// src/pages/SubColumnItem.js
import React from 'react';
import { useResponsiveStyles } from './component/pagebuilder/useResponsiveStyles';
import DroppableColumn from './component/DroppableColumn';
import DraggableComponent from './component/DraggableComponent';
import CustomPopover from './component/CustomPopover';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPenToSquare,
  faTrashCan,
  faClone
} from '@fortawesome/free-solid-svg-icons';
import { componentTypes } from './config/componentTypes';

function SubColumnItem({
  subColumn,
  rowId,
  sectionId,
  colIdx,
  subSectionId,
  subColIdx,
  addSubComponentToColumn,
  dropResetFlags,
  removeSubComponent,
  removeComponent,
  cloneNestedComponent,
  handleEditComponent,
  saveChanges,
  cloneComponent,
  handleRemove // Ensure this prop is passed
}) {
  const subColumnStyles = useResponsiveStyles(subColumn.styles);

  return (
    <DroppableColumn
      key={`${subSectionId}_${subColIdx}`}
      id={`${subSectionId}_${subColIdx}`}
      className={`${subColumn.class} p-3-custom border`}
      onDropComponent={(columnIdDropped, item) =>
        addSubComponentToColumn(
          rowId,
          sectionId,
          colIdx,
          item,
          `${subSectionId}_${subColIdx}`,
          subSectionId,
          subColIdx
        )
      }
      resetDrop={dropResetFlags[`${subSectionId}_${subColIdx}`]}
    >
      <div style={subColumnStyles}>
        {/* Components inside subColumn */}
        {Array.isArray(subColumn.components) && subColumn.components.length > 0 ? (
          subColumn.components.map((comp, compIdx) =>
            comp ? (
              <DraggableComponent
                key={compIdx}
                type={comp.type}
                sectionId={subSectionId}
                rowID={rowId}
                columnIdx={subColIdx}
                componentIdx={compIdx}
                props={comp.props}
                onCloneSection={() =>
                  cloneNestedComponent(
                    rowId,
                    sectionId,
                    colIdx,
                    subSectionId,
                    subColIdx,
                    compIdx
                  )
                }
              >
                {React.createElement(componentTypes[comp.type], {
                  ...comp.props,
                  onSave: (newContent) =>
                    saveChanges({
                      ...comp.props,
                      content: newContent
                    })
                })}
                <div className="editable-wrap">
                  <button
                    className="btn"
                    onClick={() =>
                      handleEditComponent(
                        subSectionId,
                        subColIdx,
                        compIdx,
                        comp
                      )
                    }
                  >
                    <FontAwesomeIcon icon={faPenToSquare} />
                  </button>
                  <button
                    className="btn"
                    onClick={() =>
                      handleRemove(removeSubComponent, [
                        rowId,
                        sectionId,
                        subSectionId,
                        subColIdx,
                        compIdx
                      ])
                    }
                  >
                    <FontAwesomeIcon icon={faTrashCan} />
                  </button>
                  <button
                    className="btn"
                    onClick={() =>
                      cloneNestedComponent(
                        rowId,
                        sectionId,
                        colIdx,
                        subSectionId,
                        subColIdx,
                        compIdx
                      )
                    }
                  >
                    <FontAwesomeIcon icon={faClone} />
                  </button>
                </div>
              </DraggableComponent>
            ) : null
          )
        ) : (
          <p className="empty-component">
            Drag and drop a component from the left sidebar to get started!
          </p>
        )}
      </div>
    </DroppableColumn>
  );
}

export default SubColumnItem;
