import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';



const MinimalBreadcrumb = ({ items, separator = '•', styles = {} }) => (
    <nav className="breadcrumb-container minimal-breadcrumb" style={styles.container}>
        {items.map((item, index) => (
            <span key={index} className="breadcrumb-item" style={styles.item}>
                <a href={item.link} style={styles.link}>
                    {item.text}
                </a>
                {index < items.length - 1 && (
                    <span className="breadcrumb-separator" style={styles.separator}>
                        
                        <FontAwesomeIcon icon={faAngleRight} />
                    </span>
                )}
            </span>
        ))}
    </nav>
);


export default MinimalBreadcrumb;