import { useContext } from 'react';
import { BreakpointContext } from './BreakpointContext';

export function useResponsiveStyles(styles) {
  const currentBreakpoint = useContext(BreakpointContext); 

  // Ensure styles is a valid object
  const validStyles = styles && typeof styles === 'object' ? styles : {};

  // Retrieve styles based on the current breakpoint
  const breakpointStyles = validStyles[currentBreakpoint] || validStyles.desktop || {};

  return breakpointStyles;
}