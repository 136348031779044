import React from 'react';
import { useResponsiveStyles } from './useResponsiveStyles';


function SpacerComponent({ height = '20px', styles = {} }) {

    const appliedStyles = useResponsiveStyles(styles);

    const spacerStyles = {
        height,
        ...appliedStyles.spacerStyle, // Apply additional styles dynamically
    };

    return <div className="spacer-wrap" style={spacerStyles}></div>;
}

export default SpacerComponent;
