import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Loading from './component/Loading'; // LoadingOverlay component
import { useAppData } from './component/AppDataContext';
import ResponsiveIframe from './utils/ResponsiveIframe';

function PreviewPages() {
    const { slug, pageId } = useParams(); // Extract both slug and pageId from URL
    const [isLoading, setIsLoading] = useState(false);
    const { subdomain } = useAppData(); // Get the subdomain from context
    const navigate = useNavigate(); // Use navigate for redirection

    // Construct the URL for the iframe
    const url = `/${slug}`;

    const handleExitPreview = () => {
        // Redirect to /page-builder/{pageId}
        navigate(`/page-builder/${pageId}`);
    };

    return (
        <div>
            {isLoading && <Loading />}
            {/* ResponsiveIframe */}
            <ResponsiveIframe url={url} handleExitPreview={handleExitPreview}/>
        </div>
    );
}

export default PreviewPages;
