// src/utils/MetaDataModal.js

import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark, faCheck } from '@fortawesome/free-solid-svg-icons';
import { Modal, Button, Form, Spinner, Image } from 'react-bootstrap';
import PropTypes from 'prop-types';
import MediaLibrary from '../component/MediaLibrary';
import { API_URL } from '../../config'; 
import { useAppData } from '../component/AppDataContext'; // Adjust the import path as needed
import ColorPicker from '../component/ColorPicker'; // Adjust the path based on your project structure
import ColorPickerModal from '../component/ColorPickerModal'; // Adjust the path based on your project structure

// Import CodeMirror and its extensions for CodeMirror 6
import CodeMirror from '@uiw/react-codemirror';
import { css } from '@codemirror/lang-css'; // CSS language support
import { oneDark } from '@codemirror/theme-one-dark'; // Theme extension

// Optional: Import CodeMirror 6's basic styles if needed
// Note: @uiw/react-codemirror handles most styling via themes and extensions

const MetaDataModal = ({ show, handleClose, context, selectedPageId }) => {
    const [metaData, setMetaData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [saving, setSaving] = useState(false);
    const [formValues, setFormValues] = useState({});
    
    const { subdomain: accountKey } = useAppData(); 

    // State for ColorPickerModal
    const [showColorPickerModal, setShowColorPickerModal] = useState(false);
    const [currentColorField, setCurrentColorField] = useState(null);

    // Fetch metadata and related data when modal opens
    useEffect(() => {
        if (show) {
            fetchMetaData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [show, context, selectedPageId]);

    const fetchMetaData = async () => {
        setLoading(true);
        try {
            const params = new URLSearchParams({
                category: context, 
                account_key: accountKey,
            });

            const response = await fetch(`${API_URL}/meta-data/category?${params.toString()}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    // Include authentication headers if necessary
                },
            });

            if (!response.ok) {
                if (response.status === 404) {
                    throw new Error('No metadata found for the specified category.');
                } else {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
            }

            const data = await response.json();
            setMetaData(data.metaDataList);

            // Initialize form values
            const initialValues = {};
            data.metaDataList.forEach((item) => {
                initialValues[`meta_value_${item.id}`] =
                    Array.isArray(item.meta_value) ? item.meta_value : item.meta_value;
            });
            setFormValues(initialValues);
        } catch (error) {
            console.error('Error fetching metadata:', error);
            alert(error.message || 'Failed to fetch metadata.');
        } finally {
            setLoading(false);
        }
    };


    const handleInputChange = (e, meta) => {
        const { name, value, type, checked, options } = e.target;

        if (type === 'select-multiple') {
            const selectedOptions = Array.from(options)
                .filter(option => option.selected)
                .map(option => option.value);
            setFormValues({
                ...formValues,
                [name]: selectedOptions,
            });
        } else if (type === 'checkbox') {
            setFormValues({
                ...formValues,
                [name]: checked,
            });
        } else {
            setFormValues({
                ...formValues,
                [name]: value,
            });
        }
    };

    const handleImageSelect = (url, metaId) => {
        setFormValues({
            ...formValues,
            [`meta_value_${metaId}`]: url,
        });
    };

    const handleImagesSelect = (urls, metaId) => {
        setFormValues({
            ...formValues,
            [`meta_value_${metaId}`]: urls,
        });
    };

    const handleColorChange = (e, metaId) => {
        const { value } = e.target;
        setFormValues({
            ...formValues,
            [`meta_value_${metaId}`]: value,
        });
    };

    const openColorPickerModal = (fieldName) => {
        setCurrentColorField(fieldName);
        setShowColorPickerModal(true);
    };

    const closeColorPickerModal = () => {
        setShowColorPickerModal(false);
        setCurrentColorField(null);
    };

    const handleColorSelection = (newColor) => {
        if (currentColorField) {
            setFormValues({
                ...formValues,
                [currentColorField]: newColor,
            });
        }
        // closeColorPickerModal(); // Uncomment if you want to close after selection
    };

    const handleSubmit = async () => {
        setSaving(true);
        try {
            const params = new URLSearchParams({
                account_key: accountKey,
            });

            const payload = {
                page_id: selectedPageId,
                context: context,
                metaData: formValues,
            };

            const response = await fetch(`${API_URL}/meta-data/update?${params.toString()}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    // Include authentication headers if necessary
                },
                body: JSON.stringify(payload),
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const result = await response.json();

            if (result.success) {
                alert('Metadata updated successfully.');
                handleClose();
            } else {
                throw new Error(result.message || 'Failed to update metadata.');
            }
        } catch (error) {
            console.error('Error updating metadata:', error);
            alert(error.message || 'Failed to update metadata.');
        } finally {
            setSaving(false);
        }
    };


    const renderFormField = (meta) => {
        const fieldName = `meta_value_${meta.id}`;
        const value = formValues[fieldName] || '';

        switch (meta.meta_type) {
            case 'text':
                return (
                    <Form.Control
                        type="text"
                        name={fieldName}
                        value={value}
                        onChange={(e) => handleInputChange(e, meta)}
                        placeholder={meta.placeholder || ''}
                    />
                );
            case 'textarea':
                return (
                    <Form.Control
                        as="textarea"
                        rows={3} // Adjust the number of rows as needed
                        name={fieldName}
                        value={value}
                        onChange={(e) => handleInputChange(e, meta)}
                        placeholder={meta.placeholder || ''}
                    />
                );
            case 'color':
                return (
                    <>
                        <div className="d-flex align-items-center">
                            <ColorPicker
                                color={value || '#ffffff'}
                                onOpen={() => openColorPickerModal(fieldName)}
                            />
                            <Form.Control
                                type="text"
                                name={fieldName}
                                value={value}
                                onChange={(e) => handleInputChange(e, meta)}
                                placeholder="#ffffff"
                                className="ms-2"
                            />
                        </div>
                    </>
                );
            case 'image':
                return (
                    <>
                        <div className="row">
                            <div className="col-md-3">
                                <div className="media-img-wrap">
                                    {value && (
                                        <div className="mt-2">
                                            <Image 
                                                src={value} 
                                                alt="Selected" 
                                                thumbnail
                                                style={{ 
                                                    height: '100px',
                                                    width: '100px',
                                                    objectFit: 'cover',
                                                }} 
                                            />
                                        </div>
                                    )}
                                    <MediaLibrary
                                        onSelectImage={(url) => handleImageSelect(url, meta.id)}
                                        multiple={false}
                                    />
                                </div>
                            </div>
                        </div>    
                    </>
                );
            case 'stylesheet':
                return (
                    <CodeMirror
                        value={value}
                        extensions={[css()]} // Add CSS language support
                        theme={oneDark} // Apply the One Dark theme
                        basicSetup={{
                            lineNumbers: true,
                            foldGutter: true,
                            highlightActiveLine: true,
                        }}
                        onChange={(value, viewUpdate) => {
                            setFormValues({
                                ...formValues,
                                [fieldName]: value,
                            });
                        }}
                        height="300px" // Adjust height as needed
                    />
                );
            default:
                return (
                    <Form.Control
                        type="text"
                        name={fieldName}
                        value={value}
                        onChange={(e) => handleInputChange(e, meta)}
                    />
                );
        }
    };

    const getModalTitle = () => {
        switch (context) {
            case 'header':
                return 'Customize Header';
            case 'footer':
                return 'Customize Footer';
            case 'customStyles':
                return 'Custom Style Sheet';
            default:
                return 'Customize';
        }
    };

    if (!show) return null;

    return (
        <>
            <Modal className="model-styling" show={show} onHide={handleClose} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        {getModalTitle()}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="tab-content">
                        {loading ? (
                            <div className="d-flex justify-content-center">
                                <Spinner animation="border" />
                            </div>
                        ) : (
                            <Form>
                                {metaData.map((meta) => (
                                    <div className="icon-card-form carousel-wrap pb-2" key={meta.id}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>
                                                {meta.meta_key}
                                                {meta.description && (
                                                    <i
                                                        data-bs-toggle="tooltip"
                                                        data-bs-placement="top"
                                                        title={meta.description}
                                                        className="fas fa-info-circle ms-2"
                                                    ></i>
                                                )}
                                            </Form.Label>
                                            {renderFormField(meta)}
                                        </Form.Group>
                                    </div>
                                ))}
                            </Form>
                        )}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={handleClose}>
                        <FontAwesomeIcon icon={faXmark} /> Close
                    </Button>
                    <Button variant="success" onClick={handleSubmit} disabled={saving || loading}>
                        {saving ? (
                            <>
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                /> Saving...
                            </>
                        ) : (
                            <>
                                <FontAwesomeIcon icon={faCheck} /> Save Changes
                            </>
                        )}
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* ColorPickerModal */}
            {currentColorField && (
                <ColorPickerModal
                    show={showColorPickerModal}
                    handleClose={closeColorPickerModal}
                    color={formValues[currentColorField] || '#ffffff'}
                    handleColorChange={handleColorSelection}
                />
            )}
        </>
    );

    };

    MetaDataModal.propTypes = {
        show: PropTypes.bool.isRequired,
        handleClose: PropTypes.func.isRequired,
        context: PropTypes.oneOf(['header', 'footer', 'customStyles']).isRequired,
        selectedPageId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    };

    export default MetaDataModal;