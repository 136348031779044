import React from 'react';
import { Carousel } from 'react-bootstrap';

const ImageOnTopTemplate = ({ headline, testimonials, styles = {} }) => (
    <div className="testimonial-image-on-top-wrap"  style={styles.container || {}}>
        {/*<h2 style={styles.headline || {}}>{headline}</h2>*/}
        <Carousel indicators={true} interval={5000}>
            {testimonials.map((testimonial, idx) => (
                <Carousel.Item key={idx} className="testimonial-item-image-on-top">
                    <div className="text-center">
                        <img
                            src={testimonial.image}
                            alt={testimonial.name}
                            className="mx-auto d-block"
                            style={{
                                ...styles.image,
                            }}
                        />
                        <p style={styles.message || {}}>"{testimonial.message}"</p>
                        <h5 style={styles.name || {}}>{testimonial.name}</h5>
                        <h6 style={styles.position || {}}>{testimonial.position}</h6>
                    </div>
                </Carousel.Item>
            ))}
        </Carousel>
    </div>
);

export default ImageOnTopTemplate;
