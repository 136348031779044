import React, { useState, useEffect, lazy, Suspense } from 'react';
import { useParams } from 'react-router-dom';
import Loading from './component/Loading';
import { API_URL } from '../config';
import { useAppData } from './component/AppDataContext';
import Masonry from 'react-masonry-css';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

const CustomPages = lazy(() => import('./CustomPages'));

const Blog = () => {
    const { blogId } = useParams();
    const { subdomain } = useAppData();

    const [isLoading, setIsLoading] = useState(true);
    const [blog, setBlog] = useState(null); // New state for blog details
    const [categories, setCategories] = useState([]);
    const [posts, setPosts] = useState([]);
    const [originalPosts, setOriginalPosts] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(null);

    useEffect(() => {
        const fetchBlogData = async () => {
            try {
                setIsLoading(true);
                const response = await fetch(
                    `${API_URL}/blog/${blogId}?account_key=${subdomain}`
                );

                if (!response.ok) {
                    throw new Error('Failed to fetch blog details');
                }

                const data = await response.json();
                setBlog(data.blog || {}); // Store blog details
                setCategories(data.categories || []);
                setPosts(data.posts || []);
                setOriginalPosts(data.posts || []);
            } catch (error) {
                console.error('Error fetching blog data:', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchBlogData();
    }, [blogId, subdomain]);

    const handleCategoryFilter = (categoryId) => {
        setSelectedCategory(categoryId);
        if (!categoryId) {
            setPosts(originalPosts);
        } else {
            const filtered = originalPosts.filter((post) =>
                post.categories?.some((c) => c.id === categoryId)
            );
            setPosts(filtered);
        }
    };

    const breakpointColumnsObj = {
        default: 1,
        992: 1,
        576: 1,
    };

    if (isLoading) {
        return <Loading />;
    }

    return (
        <>
            {/* Blog Header with Background Image */}
            {blog && (
                <div
                    className="blog-list-head"
                    style={{
                        backgroundImage: blog.media_url
                            ? `url(${blog.media_url})`
                            : 'url(/path/to/default-image.jpg)', // Fallback image
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                        padding: '100px 0', // Adjust padding as needed
                        color: '#fff', // Ensure text is readable over the image
                        textAlign: 'center',
                        position: 'relative',
                    }}
                >
                    {/* Optional Overlay for Better Text Readability */}
                    {blog.media_url && (
                        <div
                            style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                                backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent overlay
                                zIndex: 1,
                            }}
                        ></div>
                    )}
                    <div
                        style={{
                            position: 'relative',
                            zIndex: 2,
                            maxWidth: '800px',
                            margin: '0 auto',
                            padding: '0 20px',
                        }}
                    >
                        <h1>{blog.title || `Blog (ID: ${blogId})`}</h1>
                        <p>{blog.description || 'Explore posts below'}</p>
                    </div>
                </div>
            )}

            {/* Main Content */}
            <div className="container py-5">
                {/* Categories */}
                {categories.length > 0 && (
                    <div className="blog-category-wrap mb-4">
                        <div className="d-flex flex-row overflow-auto">
                            <button
                                onClick={() => handleCategoryFilter(null)}
                                className={
                                    selectedCategory === null
                                        ? 'btn btn-primary me-2 flex-shrink-0'
                                        : 'btn btn-outline-secondary me-2 flex-shrink-0'
                                }
                            >
                                All
                            </button>
                            {categories.map((cat) => (
                                <button
                                    key={cat.id}
                                    className={
                                        selectedCategory === cat.id
                                            ? 'btn btn-primary me-2 flex-shrink-0'
                                            : 'btn btn-outline-primary me-2 flex-shrink-0'
                                    }
                                    onClick={() => handleCategoryFilter(cat.id)}
                                >
                                    {cat.name}
                                </button>
                            ))}
                        </div>
                    </div>
                )}

                {/* Posts with Masonry & Animations */}
                <div className="blogs-list">
                    <Masonry
                        breakpointCols={breakpointColumnsObj}
                        className="my-blog-grid"
                        columnClassName="my-blog-grid_column"
                    >
                        <TransitionGroup component={null}>
                            {posts.length > 0 ? (
                                posts.map((post) => (
                                    <CSSTransition key={post.id} timeout={300} classNames="fade">
                                        <div className="card mb-4">
                                            {post.featured_image_url && (
                                                <img
                                                    src={post.featured_image_url}
                                                    className="card-img-top"
                                                    alt={post.title}
                                                    style={{ objectFit: 'cover', height: '200px' }}
                                                />
                                            )}
                                            <div className="card-body d-flex flex-column">
                                                <h5 className="card-title">{post.title}</h5>
                                                <p className="card-text text-muted mb-3">
                                                    {post.content?.length > 120
                                                        ? `${post.content.substring(0, 120)}...`
                                                        : post.content}
                                                </p>
                                                <div className="mt-auto">
                                                    {/* Updated link to match /blog/post/:postId */}
                                                    <a
                                                        href={`/blog/post/${post.slug}`}
                                                        className="btn btn-primary"
                                                    >
                                                        Read More
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </CSSTransition>
                                ))
                            ) : (
                                <p className="text-muted">No posts found for this category.</p>
                            )}
                        </TransitionGroup>
                    </Masonry>
                </div>
            </div>
        </>
    );
};

export default Blog;
