import React from 'react';
import { useResponsiveStyles } from '../../useResponsiveStyles';


const MinimalHeaderFooterCard = ({ header, footer, title, bodyText, styles = {} }) => {

    const appliedStyles = useResponsiveStyles(styles);

    return (
        <div className="card minimal-hf-card">
            <div className="card-header minimal-header" style={appliedStyles.header || {}}>
                {header}
            </div>
            <div className="card-body">
                <h5 className="card-title" style={appliedStyles.title || {}}>
                    {title}
                </h5>
                <p className="card-text" style={appliedStyles.bodyText || {}}>
                    {bodyText}
                </p>
                <a href={appliedStyles.button?.link || "#"} className="btn minimal-btn" style={appliedStyles.button || {}}>
                    {appliedStyles.button?.text || "Details"}
                </a>
            </div>
            <div className="card-footer minimal-footer" style={appliedStyles.footer || {}}>
                {footer}
            </div>
        </div>
    );
};

export default MinimalHeaderFooterCard;
