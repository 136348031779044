import React from 'react';
import GridTemplate from './templates/gallery/GridTemplate';
import MasonryTemplate from './templates/gallery/MasonryTemplate';
import CarouselTemplate from './templates/gallery/CarouselTemplate';
import OverlayTemplate from './templates/gallery/OverlayTemplate';
import PolaroidTemplate from './templates/gallery/PolaroidTemplate';
import LightboxTemplate from './templates/gallery/LightboxTemplate';
import SliderTemplate from './templates/gallery/SliderTemplate';
import { useResponsiveStyles } from './useResponsiveStyles';


const GalleryComponent = ({ images, template = "Grid", styles = {} }) => {

    const appliedStyles = useResponsiveStyles(styles);


    switch (template) {
        case "Carousel":
            return <CarouselTemplate images={images} styles={appliedStyles} />;
        case "Overlay":
            return <OverlayTemplate images={images} styles={appliedStyles} />;
        case "Lightbox":
            return <LightboxTemplate images={images} />;
        case "Slider": 
            return <SliderTemplate images={images} styles={appliedStyles} />;
        default: // Grid
            return <GridTemplate images={images} styles={appliedStyles} />;
    }
};

export default GalleryComponent;
