import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { Form, Button, InputGroup, Spinner, Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { API_URL } from '../../../config'; // Adjust the path as needed
import { useAppData } from '../AppDataContext';

const MenusForm = ({ formValues, setFormValues }) => {
    const [availableMenus, setAvailableMenus] = useState([]);
    const [loadingMenus, setLoadingMenus] = useState(true);
    const [error, setError] = useState(null);
    const { subdomain } = useAppData();

    // Fetch available menus from the API on component mount
    useEffect(() => {
        const fetchAvailableMenus = async () => {
            try {
                const response = await fetch(`${API_URL}/menus?account_key=${subdomain}`);
                const data = await response.json();

                if (response.ok) {
                    setAvailableMenus(data);
                } else {
                    throw new Error(data.message || 'Failed to fetch menus.');
                }
            } catch (err) {
                console.error('Error fetching menus:', err);
                setError(err.message);
            } finally {
                setLoadingMenus(false);
            }
        };

        fetchAvailableMenus();
    }, [subdomain]);

    // Map menu items to react-select options
    const menuOptions = availableMenus.map(menu => ({
        value: menu.id,
        label: menu.title,
    }));

    // Handle changes in the menu selection
    const handleSelectChange = (selectedOptions) => {
        if (selectedOptions.length > 12) {
            selectedOptions = selectedOptions.slice(0, 12); // Adjusted to slice to 12 instead of 2
        }
        setFormValues({
            ...formValues,
            selectedMenus: selectedOptions.map(option => ({
                value: option.value,
                label: option.label,
            })),
        });
    };

    // Handle removing a menu
    const handleRemoveMenu = (index) => {
        const updatedMenus = [...formValues.selectedMenus];
        updatedMenus.splice(index, 1);
        setFormValues({ ...formValues, selectedMenus: updatedMenus });
    };

    return (
        <div className="menu-listing-form">
            {error && (
                <Alert variant="danger" onClose={() => setError(null)} dismissible>
                    {error}
                </Alert>
            )}

            {/* Menu Selection */}
            <Form.Group controlId="selectedMenus">
                <Form.Label>Select Menus (Maximum 12)</Form.Label>
                {loadingMenus ? (
                    <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                ) : (
                    <Select
                        isMulti
                        options={menuOptions}
                        value={formValues.selectedMenus || []}
                        onChange={handleSelectChange}
                        placeholder="Select up to 12 menus..."
                        closeMenuOnSelect={false}
                        maxMenuHeight={200}
                        isOptionDisabled={() =>
                            (formValues.selectedMenus || []).length >= 12
                        }
                    />
                )}
            </Form.Group>

            {/* Display Selected Menus */}
            {formValues.selectedMenus?.length > 0 && (
                <div className="selected-menus mt-3">
                    <h5>Selected Menus:</h5>
                    {formValues.selectedMenus.map((menu, index) => (
                        <InputGroup key={menu.value} className="mb-2">
                            <Form.Control type="text" readOnly value={menu.label} />
                            <Button
                                variant="danger"
                                onClick={() => handleRemoveMenu(index)}
                            >
                                <FontAwesomeIcon icon={faTrash} />
                            </Button>
                        </InputGroup>
                    ))}
                </div>
            )}
        </div>
    );
};

export default MenusForm;
