import React, { createContext, useState, useEffect, useContext } from 'react';
import { API_URL } from '../../config';

const AppDataContext = createContext();

export const useAppData = () => useContext(AppDataContext);

export const AppDataProvider = ({ children }) => {
  const [menus, setMenus] = useState([]);
  const [logoUrl, setLogoUrl] = useState('');
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [headerBackgroundColor, setHeaderBackgroundColor] = useState('');
  const [headerMenuFontColor, setHeaderMenuFontColor] = useState('');
  const [customStyles, setCustomStyles] = useState('');

    // Only storing IDs for website header and footer
    const [websiteHeaderCustomUrl, setWebsiteHeaderCustomUrl] = useState('');
    const [websiteFooterCustomUrl, setWebsiteFooterCustomUrl] = useState('');
    
    const subdomain = window.location.hostname.split('.')[0];  // Simplified for demonstration

  // Example subdomain handling:
  //const subdomain = 'test'; // simplified for demo

  useEffect(() => {
    const fetchData = async () => {
      setIsDataLoading(true);
      try {
        const [menuResponse, metaResponse] = await Promise.all([
          fetch(`${API_URL}/menus?account_key=${subdomain}`),
          fetch(`${API_URL}/meta-data?account_key=${subdomain}`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
              identifiers: [
                'website_logo',
                'header_background_color',
                'header_menu_font_color',
                'custom_styles',
                'website_header_custom_url',
                'website_footer_custom_url',
              ],
            }),
          }),
        ]);

        const menuData = await menuResponse.json();
        const metaData = await metaResponse.json();

        // Menus
        if (menuData) {
          setMenus(Object.values(menuData));
        }

        // Meta Data
        metaData.forEach((item) => {
          switch (item.identifier) {
            case 'website_logo':
              setLogoUrl(item.data);
              break;
            case 'header_background_color':
              setHeaderBackgroundColor(item.data);
              break;
            case 'header_menu_font_color':
              setHeaderMenuFontColor(item.data);
              break;
            case 'custom_styles':
              setCustomStyles(item.data);
              break;
            case 'website_header_custom_url':
              // This is where we get the ID for website header
              setWebsiteHeaderCustomUrl(item.data);
              break;
            case 'website_footer_custom_url':
              // This is where we get the ID for website footer
              setWebsiteFooterCustomUrl(item.data);
              break;
            default:
              break;
          }
        });

        setIsDataLoading(false);
      } catch (error) {
        console.error('Failed to fetch app data:', error);
        setIsDataLoading(false);
      }
    };

    fetchData();
  }, [subdomain]);

  return (
    <AppDataContext.Provider
      value={{
        menus,
        logoUrl,
        isDataLoading,
        subdomain,
        headerBackgroundColor,
        headerMenuFontColor,
        customStyles,
        websiteHeaderCustomUrl,
        websiteFooterCustomUrl,
      }}
    >
      {children}
    </AppDataContext.Provider>
  );
};
