import React from 'react';
import { Form } from 'react-bootstrap';

const ButtonForm = ({ formValues, setFormValues }) => (
    <div className="carousel-wrap pb-3">
        <Form.Group controlId="formButtonText">
            <Form.Label>Button Text</Form.Label>
            <Form.Control
                type="text"
                name="text"
                value={formValues.text || ''}
                onChange={(e) => setFormValues({ ...formValues, text: e.target.value })}
            />
        </Form.Group>
    </div>
);

export default ButtonForm;
