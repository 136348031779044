export const columnLayouts = {
    /*fullWidth: ['col-md-12'],
    leftSmallRightBig: ['col-md-3 col-sm-12', 'col-md-9 col-sm-12'],
    leftBigRightSmall: ['col-md-9 col-sm-12', 'col-md-3 col-sm-12'],
    twoEqualColumns: ['col-md-6 col-sm-12', 'col-md-6 col-sm-12'],
    threeEqualColumns: ['col-md-4 col-sm-12', 'col-md-4 col-sm-12', 'col-md-4 col-sm-12'],
    fourEqualColumns: ['col-md-3 col-sm-12', 'col-md-3 col-sm-12', 'col-md-3 col-sm-12', 'col-md-3 col-sm-12'],
    sixEqualColumns: ['col-md-2 col-sm-12', 'col-md-2 col-sm-12', 'col-md-2 col-sm-12', 'col-md-2 col-sm-12', 'col-md-2 col-sm-12', 'col-md-2 col-sm-12']*/

    fullWidth: ['col-lg-12'],
    twoEqualColumns: ['col-md-6 col-lg-6', 'col-md-6 col-lg-6'],
    threeEqualColumns: ['col-md-6 col-lg-4', 'col-md-6 col-lg-4', 'col-md-6 col-lg-4'],
    fourEqualColumns: ['col-md-6 col-lg-3', 'col-md-6 col-lg-3', 'col-md-6 col-lg-3', 'col-md-6 col-lg-3'],
    sixEqualColumns: ['col-md-6 col-lg-2', 'col-md-6 col-lg-2', 'col-md-6 col-lg-2', 'col-md-6 col-lg-2', 'col-md-6 col-lg-2', 'col-md-6 col-lg-2'],
    leftSmallRightBig: ['col-md-6 col-lg-3', 'col-md-6 col-lg-9'],
    leftBigRightSmall: ['col-md-6 col-lg-9', 'col-md-6 col-lg-3'],

    twoEqualRightBigColumns: ['col-md-6 col-lg-3', 'col-md-6 col-lg-3', 'col-md-6 col-lg-6'],
    LeftBigTwoEqualColumns: ['col-md-6 col-lg-6', 'col-md-6 col-lg-3', 'col-md-6 col-lg-3'],
    LeftFiveRightSeven: ['col-md-12 col-lg-5', 'col-md-12 col-lg-7'],
    LeftSevenRightFive: ['col-md-12 col-lg-7', 'col-md-12 col-lg-5'],
    LeftFourRightEight: ['col-md-6 col-lg-4', 'col-md-6 col-lg-8'],
    LeftEightRightFour: ['col-md-6 col-lg-8', 'col-md-6 col-lg-4'],
    CenterBigSideTwoEqualColumns: ['col-md-6 col-lg-3', 'col-md-6 col-lg-6', 'col-md-6 col-lg-3'],
    CenterBigSideSmallEqualColumns: ['col-md-6 col-lg-2', 'col-md-6 col-lg-8', 'col-md-6 col-lg-2']


};