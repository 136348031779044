import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faTwitter, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { useResponsiveStyles } from './useResponsiveStyles';

const SocialMediaIconsComponent = ({ links, styles = {}, template = "Default" }) => {
    const appliedStyles = useResponsiveStyles(styles);

    const getIcon = (name) => {
        switch (name) {
            case 'facebook':
                return faFacebook;
            case 'twitter':
                return faTwitter;
            case 'instagram':
                return faInstagram;
            case 'linkedin':
                return faLinkedin;
            default:
                return null;
        }
    };

    const renderIcons = (additionalClass) => (
        <div className={`${additionalClass}`} style={appliedStyles.container}>
            {links.map((link, index) => {
                const icon = getIcon(link.name);
                if (!icon) return null;

                return (
                    <a
                        key={index}
                        href={link.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="icon-link"
                        style={appliedStyles.icon}
                    >
                        <FontAwesomeIcon icon={icon} />
                    </a>
                );
            })}
        </div>
    );

    switch (template) {
        case "Modern":
            return renderIcons("modern-social");
        case "Minimal":
            return renderIcons("minimal-social");
        case "Custom":
            return renderIcons("custom-social");
        default:
            return renderIcons("default-social");
    }
};

export default SocialMediaIconsComponent;
