// BreakpointContext.js
import React, { createContext } from 'react';
import { useBreakpoint } from './useBreakpoint';

export const BreakpointContext = createContext('desktop');

export function BreakpointProvider({ children }) {
  const breakpoint = useBreakpoint();
  return (
    <BreakpointContext.Provider value={breakpoint}>
      {children}
    </BreakpointContext.Provider>
  );
}
