import React from 'react';
import { useResponsiveStyles } from './useResponsiveStyles';
import { nl2br } from '../../utils/nl2br'; // Adjust the import path as needed


function TextComponent({ content, styles = {} }) {

    const appliedStyles = useResponsiveStyles(styles);

    return (
        <div style={appliedStyles.container}>
            <p style={appliedStyles.content}>{nl2br(content) || ""}</p>
        </div>
    );
}

export default TextComponent;
