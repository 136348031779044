import React, { useState } from 'react';
import PropTypes from 'prop-types';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faTimes, faMobileAlt, faTabletAlt, faDesktop} from '@fortawesome/free-solid-svg-icons';

const ResponsiveIframe = ({ url, handleExitPreview }) => {
    // Define view types and their corresponding dimensions
    const views = {
        mobile: { width: '375px', height: '667px' },  // iPhone X
        tablet: { width: '768px', height: '1024px' }, // iPad
        web: { width: '100%', height: '1200px' },      // Full width
    };

    // State to keep track of the current view
    const [currentView, setCurrentView] = useState('web');

    // Handler to change the view
    const handleViewChange = (view) => {
        setCurrentView(view);
    };

    // Get the dimensions based on the current view
    const { width, height } = views[currentView];

    // Styles
    const containerStyle = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '20px',
    };

    const buttonContainerStyle = {
        marginBottom: '20px',
    };

    const buttonStyle = (active) => ({
        padding: '10px 20px',
        margin: '0 10px',
        border: 'none',
        borderRadius: '5px',
        backgroundColor: active ? '#007BFF' : '#CCCCCC',
        color: '#fff',
        cursor: 'pointer',
    });

    const iframeStyle = {
        width: width,
        height: height,
        border: '1px solid #ccc',
        borderRadius: '8px',
    };

    return (
        <div className="device-view-wrap">
            {/* Header Section with Exit Preview Button */}
            <div className="exit-preview">
                <button className="btn" onClick={handleExitPreview}>
                    <FontAwesomeIcon icon={faTimes} />
                    Exit Preview
                </button>
            </div>
            <div className="select-device">
                <button
                    onClick={() => handleViewChange('mobile')}
                >
                    <FontAwesomeIcon icon={faMobileAlt} />
                </button>
                <button
                    onClick={() => handleViewChange('tablet')}
                >
                    <FontAwesomeIcon icon={faTabletAlt} />
                </button>
                <button
                    onClick={() => handleViewChange('web')}
                >
                    <FontAwesomeIcon icon={faDesktop} />
                </button>
                
            </div>
            <div className="device-check">
                <iframe
                    src={url}
                    title="Responsive Iframe"
                    style={iframeStyle}
                    allowFullScreen
                ></iframe>
            </div>
        </div>
    );
};

// PropTypes for validation
ResponsiveIframe.propTypes = {
    url: PropTypes.string.isRequired,
};

// Default Props (optional)
ResponsiveIframe.defaultProps = {
    url: 'https://www.example.com',
};

export default ResponsiveIframe;
