// src/pages/ColumnItem.js
import React from 'react';
import { useResponsiveStyles } from './component/pagebuilder/useResponsiveStyles';
import DroppableColumn from './component/DroppableColumn'; // Corrected import path
import DroppableSubSection from './component/droppable/DroppableSubSection';
import SubColumnItem from './SubColumnItem'; // Import the new SubColumnItem component
import DraggableComponent from './component/DraggableComponent';
import CustomPopover from './component/CustomPopover';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowsUpDownLeftRight,
  faEyeSlash,
  faEye,
  faClone,
  faTrashCan,
  faCog,
  faAngleDown,
  faAngleUp,
  faPlus,
  faPenToSquare
} from '@fortawesome/free-solid-svg-icons';
import { componentTypes } from './config/componentTypes';

function ColumnItem({
  column,
  rowId,
  sectionId,
  colIdx,
  subSectionId,
  subColIdx,
  addComponentToColumn,
  addSubComponentToColumn,
  dropResetFlags,
  removeSubSection,
  cloneNestedSection,
  toggleSubSectionState,
  changeSubLayout,
  handleRemove,
  removeSubComponent,
  removeComponent,
  cloneNestedComponent,
  handleEditComponent,
  saveChanges,
  cloneComponent,
  handleButtonClick,
  activeRowId,
  addButtonRef,
  columnLayouts,
  moveSubSection // Ensure this prop is passed from RowItem
}) {
  // Apply responsive styles to the column
  const appliedStyles = useResponsiveStyles(column.styles);

  // Determine the column ID for drop resets
  const columnId = subSectionId
    ? `${subSectionId}_${subColIdx}`
    : `${sectionId}_${colIdx}`;

  return (
    <DroppableColumn
      key={columnId}
      id={columnId}
      className={`${column.class}  col-pos-rel p-3-custom border `}
      onDropComponent={(columnIdDropped, item) =>
        subSectionId
          ? addSubComponentToColumn(
              rowId,
              sectionId,
              colIdx,
              item,
              columnId,
              subSectionId,
              subColIdx
            )
          : addComponentToColumn(rowId, sectionId, colIdx, item, columnId)
      }
      resetDrop={dropResetFlags[columnId]}
    >
      <div style={appliedStyles}>
        {/* Render nested subSections if any */}
        {column.sections && column.sections.length > 0 && (
          <div className="row">
            {column.sections.map((subSection, subIdx) => (
              <DroppableSubSection
                key={subSection.id}
                id={subSection.id}
                rowId={rowId}
                columnIdx={colIdx}
                index={subIdx}
                moveSubSection={moveSubSection} // Pass the actual function
                onRemoveSection={() =>
                  removeSubSection(rowId, sectionId, subSection.id)
                }
                onCloneSection={() =>
                  cloneNestedSection(rowId, sectionId, subSection.id, colIdx)
                }
              >
                <div className="applied-grid">
                  <div className="row-edit-set-wrap d-flex justify-content-between align-items-center">
                    <div className="actions-wrap">
                      <button className="drag-handle">
                        <FontAwesomeIcon icon={faArrowsUpDownLeftRight} />
                      </button>
                      <button
                        onClick={() =>
                          toggleSubSectionState(
                            rowId,
                            sectionId,
                            subSection.id,
                            colIdx
                          )
                        }
                      >
                        <FontAwesomeIcon
                          icon={
                            subSection.disabled ? faEyeSlash : faEye
                          }
                        />
                      </button>
                      <button
                        onClick={() =>
                          cloneNestedSection(
                            rowId,
                            sectionId,
                            subSection.id,
                            colIdx
                          )
                        }
                      >
                        <FontAwesomeIcon icon={faClone} />
                      </button>
                      <button
                        onClick={() =>
                          handleRemove(removeSubSection, [
                            rowId,
                            sectionId,
                            subSection.id
                          ])
                        }
                      >
                        <FontAwesomeIcon icon={faTrashCan} />
                      </button>
                      <CustomPopover
                        title="Select Grid Layout"
                        layouts={columnLayouts}
                        onLayoutSelect={(layoutKey) =>
                          changeSubLayout(
                            rowId,
                            sectionId,
                            layoutKey,
                            subSection.id
                          )
                        }
                        triggerElement={
                          <div
                            style={{
                              cursor: 'pointer',
                              display: 'inline-block'
                            }}
                          >
                            <svg
                              viewBox="0 0 28 28"
                              preserveAspectRatio="xMidYMid meet"
                              shapeRendering="geometricPrecision"
                              style={{
                                fill: 'rgb(255, 255, 255)',
                                width: '35px',
                                minWidth: '35px',
                                height: '36px',
                                margin: '-6px',
                                opacity: 1,
                                transform: 'scale(1)'
                              }}
                            >
                              <g>
                                <path
                                  d="M20 8H8a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V9a1 1 0 0 0-1-.999V8zm-7 2h2v8h-2v-8zm-2 8H9v-8h2v8zm6-8h2v8h-2v-8z"
                                  fillRule="evenodd"
                                />
                              </g>
                            </svg>
                          </div>
                        }
                      />
                    </div>
                    <button
                      className="btn collapse-toggle-btn"
                      onClick={() =>
                        /* Implement toggle for subSection collapse if needed */
                        null
                      }
                    >
                      {/* Placeholder for collapse icon */}
                      <FontAwesomeIcon icon={faAngleDown} />
                    </button>
                  </div>

                  {/* Render subSection content if not collapsed */}
                  <div
                    className={`row ${
                      subSection.disabled ? 'disabled-subsection ' : ''
                    }`}
                  >
                    {subSection.columns.map((subColumn, subColIdx) => (
                      <SubColumnItem
                        key={subColIdx}
                        subColumn={subColumn}
                        rowId={rowId}
                        sectionId={sectionId}
                        colIdx={colIdx}
                        subSectionId={subSection.id}
                        subColIdx={subColIdx}
                        addSubComponentToColumn={addSubComponentToColumn}
                        dropResetFlags={dropResetFlags}
                        removeSubComponent={removeSubComponent}
                        removeComponent={removeComponent}
                        cloneNestedComponent={cloneNestedComponent}
                        handleEditComponent={handleEditComponent}
                        saveChanges={saveChanges}
                        cloneComponent={cloneComponent}
                        handleRemove={handleRemove} // Pass handleRemove prop
                      />
                    ))}
                  </div>
                </div>
              </DroppableSubSection>
            ))}
          </div>
        )}

        <div className="add-subsection-link-container">
          <CustomPopover
            trigger="click"
            placement="bottom"
            title="Select Grid Layout"
            layouts={columnLayouts}
            onLayoutSelect={(layoutKey) =>
              handleButtonClick(rowId, sectionId, colIdx, layoutKey)
            }
            triggerElement={
              <div
                className="add-subsection-link"
                style={{ cursor: 'pointer', display: 'inline-block' }}
              >
                <FontAwesomeIcon icon={faPlus} />
              </div>
            }
          />
        </div>

        {/* Components directly under this column */}
        {(column.components || []).map((comp, compIdx) =>
          comp ? (
            <DraggableComponent
              key={compIdx}
              type={comp.type}
              sectionId={sectionId}
              columnIdx={colIdx}
              rowID={rowId}
              componentIdx={compIdx}
              props={comp.props}
              onCloneSection={() =>
                cloneComponent(rowId, sectionId, colIdx, compIdx)
              }
            >
              {React.createElement(componentTypes[comp.type], {
                ...comp.props,
                onSave: (newContent) =>
                  saveChanges({
                    ...comp.props,
                    content: newContent
                  })
              })}
              <div className="editable-wrap">
                <button
                  className="btn"
                  onClick={() =>
                    handleEditComponent(sectionId, colIdx, compIdx, comp)
                  }
                >
                  <FontAwesomeIcon icon={faPenToSquare} />
                </button>
                <button
                  className="btn"
                  onClick={() =>
                    handleRemove(removeComponent, [
                      rowId,
                      sectionId,
                      colIdx,
                      compIdx
                    ])
                  }
                >
                  <FontAwesomeIcon icon={faTrashCan} />
                </button>
                <button
                  className="btn"
                  onClick={() =>
                    cloneComponent(rowId, sectionId, colIdx, compIdx)
                  }
                >
                  <FontAwesomeIcon icon={faClone} />
                </button>
              </div>
            </DraggableComponent>
          ) : null
        )}
      </div>
    </DroppableColumn>
  );
}

export default ColumnItem;
