    import React, { useReducer, useRef, useCallback, useState, useEffect } from 'react';
    import 'bootstrap/dist/css/bootstrap.min.css';
    import { DndProvider } from 'react-dnd';
    import { HTML5Backend } from 'react-dnd-html5-backend';
    import Dropdown from 'react-bootstrap/Dropdown';
    import DropdownButton from 'react-bootstrap/DropdownButton';
    import { useParams } from 'react-router-dom';
    import Swal from 'sweetalert2';
    import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
    import DraggableComponent from './component/DraggableComponent';
    import DroppableColumn from './component/DroppableColumn';
    import ComponentEditorModal from './component/ComponentEditorModal';
    import { columnLayouts } from './config/columnLayouts';
    import DroppableRow from './component/droppable/DroppableRow';
    import DroppableSection from './component/droppable/DroppableSection';
    import DroppableSubSection from './component/droppable/DroppableSubSection';
    import pageBuilderReducer from './reducers/pageBuilderReducer';
    import StyleEditorModal from './component/StyleEditorModal';
    import Sidebar from './component/Sidebar';
    import Loading from './component/Loading';
    import { OverlayTrigger, Popover } from 'react-bootstrap';
    import CustomPopover from './component/CustomPopover'; // Adjust the path as necessary
    import RowItem from './RowItem';

    import {
        faPlus,
        faTimes,
        faTrashCan,
        faArrowsUpDownLeftRight,
        faEyeSlash,
        faEye,
        faClone,
        faPenToSquare,
        faChevronRight,
        faChevronLeft,
        faCog,
        faAngleUp,
        faAngleDown,
    } from '@fortawesome/free-solid-svg-icons';

    import { API_URL } from '../config';
    import { componentTypes } from './config/componentTypes';
    import { fetchPageData, savePageData } from './utils/apis';
    import BuilderHeader from './utils/BuilderHeader';
    import { useAppData } from './component/AppDataContext';

    import { useNavigate } from 'react-router-dom'; // Import useNavigate
    const initialState = { rows: [] };

    function PageBuilder() {
        const [loading, setLoading] = useState(false);
        const navigate = useNavigate(); // Initialize useNavigate
        const [{ rows }, dispatch] = useReducer(pageBuilderReducer, initialState);
        const [modalShow, setModalShow] = useState(false);
        const [currentComponent, setCurrentComponent] = useState(null);
        const [currentSectionId, setCurrentSectionId] = useState(null);
        const [currentColumnIdx, setCurrentColumnIdx] = useState(null);
        const [currentComponentIdx, setCurrentComponentIdx] = useState(null);
        const [currentSubSectionId, setCurrentSubSectionId] = useState(null);
        const { pageId } = useParams();
        const [dropResetFlags, setDropResetFlags] = useState({});
        const [collapsedRows, setCollapsedRows] = useState({});
        const [collapsedSections, setCollapsedSections] = useState({});

        const [isSidebarVisible, setIsSidebarVisible] = useState(true);
        const [styleModalShow, setStyleModalShow] = useState(false);
        const [currentTargetForStyle, setCurrentTargetForStyle] = useState(null);
        const { subdomain } = useAppData();


        const [pages, setPages] = useState([]);
        const [currentPage, setCurrentPage] = useState(null);
        const [currentPageURL, setCurrentPageURL] = useState('');
        useEffect(() => {
            if (subdomain) {
                fetch(`${API_URL}/pages/list/${subdomain}`, {
                    headers: {
                        'Content-Type': 'application/json',
                        // Include authorization headers if required
                    },
                })
                    .then((response) => {
                        if (!response.ok) {
                            throw new Error('Network response was not ok');
                        }
                        return response.json();
                    })
                    .then((data) => {
                        if (data.success) {
                            setPages(data.pages);
                            // If pageId is present, set currentPageURL
                            if (pageId) {
                                const selectedPage = data.pages.find((page) => page.id === Number(pageId));
                                if (selectedPage) {
                                    setCurrentPageURL(`https://${window.location.hostname}/${selectedPage.slug}`);
                                    setCurrentPage(selectedPage);
                                } else if (data.pages.length > 0) {
                                    // If pageId is invalid, redirect to first page
                                    navigate(`/page-builder/${data.pages[0].id}`);
                                }
                            } else if (data.pages.length > 0) {
                                // If no pageId, navigate to first page
                                navigate(`/page-builder/${data.pages[0].id}`);
                            }
                        } else {
                            console.error('Failed to fetch pages:', data.message);
                        }
                    })
                    .catch((error) => {
                        console.error('Error fetching pages:', error);
                    });
            }
        }, [subdomain, navigate, pageId]);

        // Fetch page data when pageId changes
        useEffect(() => {
            if (pageId) {
                setLoading(true);
                fetchPageData(pageId)
                    .then((pageData) => {
                        dispatch({ type: 'LOAD_PAGE_DATA', payload: pageData });
                        setLoading(false);
                    })
                    .catch((error) => {
                        console.error('Error fetching page data:', error);
                        setLoading(false);
                    });
            }
        }, [pageId, subdomain]);

        // Handle page selection change
        const handlePageChange = (newPageId) => {
            navigate(`/page-builder/${newPageId}`);
        };

        // Add a new row to the builder
        const addRow = useCallback(() => {
            dispatch({ type: 'ADD_ROW' });
        }, []);

        // Fetch page data on mount or when pageId changes
        useEffect(() => {
            if (pageId) {
                fetchPageData(pageId).then((pageData) => {
                    dispatch({ type: 'LOAD_PAGE_DATA', payload: pageData });
                });
            }
        }, [pageId]);

        // Save the page data
        const savePage = useCallback(() => {
            setLoading(true);
            savePageData(pageId, rows)
                .then((data) => {
                    console.log('Page saved successfully:', data);
                })
                .catch((error) => {
                    console.error('Error saving page:', error);
                })
                .finally(() => {
                    setLoading(false);
                });
        }, [pageId, rows]);

        // Toggle row collapse
        const toggleCollapse = (rowId) => {
            setCollapsedRows((prevState) => ({
                ...prevState,
                [rowId]: !prevState[rowId]
            }));
        };

        // Toggle section collapse
        const toggleSectionCollapse = (sectionId) => {
            setCollapsedSections((prevState) => ({
                ...prevState,
                [sectionId]: !prevState[sectionId]
            }));
        };


        // Add columns into an existing column's section
        const addColumnInColumn = (rowId, sectionId, columnIdx, layoutKey) => {
            const newColumns = columnLayouts[layoutKey];
            dispatch({
                type: 'ADD_COLUMN_IN_COLUMN',
                payload: { rowId, sectionId, columnIdx, newColumns }
            });
        };

        const updateColumnsInRow = useCallback((rowId, sectionId, layoutKey) => {
            const columns = columnLayouts[layoutKey];
            if (!columns) return;
            dispatch({ type: 'UPDATE_COLUMNS_IN_SECTION', payload: { rowId, sectionId, columns } });
        }, []);

        const changeLayout = useCallback((rowId, sectionId, layoutKey, subSection) => {
            const columns = columnLayouts[layoutKey];
            if (!columns) return;
            dispatch({
                type: 'CHANGE_LAYOUT',
                payload: { rowId, sectionId, columns, subSection }
            });
        }, []);

        const changeSubLayout = useCallback((rowId, sectionId, layoutKey, subSection) => {
            const columns = columnLayouts[layoutKey];
            if (!columns) return;
            dispatch({
                type: 'CHANGE_SUB_SECTION_LAYOUT',
                payload: { rowId, sectionId, columns, subSection }
            });
        }, []);

        const removeColumnFromSection = useCallback((rowId, sectionId, columnIndex) => {
            dispatch({ type: 'REMOVE_COLUMN_FROM_SECTION', payload: { rowId, sectionId, columnIndex } });
        }, []);


        const addComponentToColumn = useCallback((rowId, targetSectionId, targetColumnIdx, item) => {
            const { type, sectionId, rowID, columnIdx, componentIdx, props } = item;
            const SubSectionId = item.sectionId;
            const fromRowId = item.rowID;
            const isMainToSub = sectionId !== undefined && columnIdx !== undefined && targetSectionId === undefined;
            const isMainToMain = rowId !== rowID;

            // Remove from previous location
            if (sectionId !== undefined && columnIdx !== undefined) {
                if (isMainToMain || (targetSectionId !== sectionId)) {
                    removeSubComponent(rowId, targetSectionId, SubSectionId, columnIdx, componentIdx, isMainToSub);
                }

                if (fromRowId !== undefined || !isMainToMain) {
                    removeComponent(rowId, sectionId, columnIdx, componentIdx, isMainToSub);
                }
            }

            const component = { type, props };
            dispatch({
                type: 'ADD_COMPONENT_TO_COLUMN',
                payload: { rowId, sectionId: targetSectionId, columnIdx: targetColumnIdx, component }
            });
        }, []);

      const addSubComponentToColumn = useCallback((rowId, sectionId, columnIdx, item, sectionColId,subSectionId, subColIdx) => {
        const { type, componentIdx, rowID,props } = item;
        const fromsectionId = item.sectionId;
        const columnIndex = item.columnIdx;
        // const deleteId = `${fromsectionId}_${columnIndex}`;

        const isMainToSub = subSectionId !== undefined && subSectionId !== sectionId && subSectionId !== fromsectionId && fromsectionId == sectionId;

        const isMainToMain = rowId !== rowID;
        const isSecton = fromsectionId !== sectionId

        let tempFromSectionId = fromsectionId;

        // Call `removeSubComponent` if conditions are met
        if ((subColIdx !== undefined && componentIdx !== undefined)  && tempFromSectionId !== null || (!isMainToMain )) {
            removeSubComponent(rowId, sectionId, subSectionId, columnIndex, componentIdx, isMainToSub, fromsectionId,);
        }

        if (sectionId !== undefined && columnIdx !== undefined ) {
            if (!isSecton ) {
                tempFromSectionId = null;
            }

            if( tempFromSectionId != null || !isMainToMain ){
                removeComponent(rowId, sectionId, columnIdx, componentIdx, isMainToSub, isMainToMain, tempFromSectionId,isSecton,columnIndex);
            }
        }
    
        // Create the new sub-component object
        const component = { type, props };
    
        // Dispatch action to add the sub-component to the specified sub-column
        dispatch({
            type: 'ADD_COMPONENT_TO_SUB_COLUMN',
            payload: {
                rowId,
                sectionId,
                columnIdx,
                component,
                subSectionId,
                subColIdx,
                sectionColId
            }
        });

        if (subSectionId) {
            setTimeout(() => {
                handleResetDrop(subSectionId);
            }, 1000);
        }
    }, [dispatch]);

        const removeRow = useCallback((rowId) => {
            dispatch({ type: 'REMOVE_ROW', payload: rowId });
        }, []);

        const removeSection = useCallback((rowId, sectionId) => {
            dispatch({ type: 'REMOVE_SECTION', payload: { rowId, sectionId } });
        }, []);

        const removeSubSection = useCallback((rowId, sectionId, subSectionId) => {
            dispatch({ type: 'REMOVE_SUB_SECTION', payload: { rowId, sectionId, subSectionId } });
        }, []);

        const handleEditComponent = (sectionId, columnIdx, componentIdx, component) => {
            setCurrentComponent(component);
            setCurrentSectionId(sectionId);
            setCurrentColumnIdx(columnIdx);
            setCurrentComponentIdx(componentIdx);
            setModalShow(true);
        };

        const saveChanges = (newProps) => {
            const updatedComponent = { ...currentComponent, props: newProps };
            dispatch({
                type: 'UPDATE_COMPONENT_IN_COLUMN',
                payload: {
                    sectionId: currentSectionId,
                    columnIdx: currentColumnIdx,
                    componentIdx: currentComponentIdx,
                    updatedComponent
                }
            });
            setModalShow(false);
        };

        const moveRow = (dragIndex, hoverIndex) => {
            dispatch({
                type: 'MOVE_ROW',
                payload: { fromRowIndex: dragIndex, toRowIndex: hoverIndex }
            });
        };

        const moveSection = (rowId, fromSectionIndex, toSectionIndex) => {
            dispatch({
                type: 'MOVE_SECTION',
                payload: { rowId, fromSectionIndex, toSectionIndex }
            });
        };

        const moveSubSection = (rowId, id, columnIdx, fromSubSectionIndex, toSubSectionIndex) => {
            dispatch({
                type: 'MOVE_SUB_SECTION',
                payload: { rowId, id, columnIdx, fromSubSectionIndex, toSubSectionIndex }
            });
        };

        const moveComponent = (fromSectionId, toSectionId, fromColumnIdx, toColumnIdx, fromComponentIdx, toComponentIdx) => {
            dispatch({
                type: 'MOVE_COMPONENT',
                payload: {
                    fromSectionId,
                    toSectionId,
                    fromColumnIdx,
                    toColumnIdx,
                    fromComponentIdx,
                    toComponentIdx
                }
            });
        };

        const cloneRow = (rowId) => {
            dispatch({ type: 'CLONE_ROW', payload: { rowId } });
        };

        const toggleRowState = (rowId) => {
            dispatch({ type: 'DISABLE_ROW', payload: { rowId } });
        };

        const toggleSectionState = (rowId, sectionId) => {
            dispatch({ type: 'DISABLE_SECTION', payload: { rowId, sectionId } });
        };

        const toggleSubSectionState = (rowId, sectionId, subSectionId, colIdx) => {
            dispatch({ type: 'DISABLE_SUB_SECTION', payload: { rowId, sectionId, subSectionId, colIdx } });
        };

        const cloneSection = (rowId, sectionId) => {
            dispatch({ type: 'CLONE_SECTION', payload: { rowId, sectionId } });
        };

        const cloneComponent = (rowId, sectionId, columnId, componentId) => {
            dispatch({
                type: 'CLONE_COMPONENT',
                payload: { rowId, sectionId, columnId, componentId }
            });
        };

        const cloneNestedComponent = (rowId, sectionId, columnIdx, subSectionId, subColumnId, componentId) => {
            dispatch({
                type: 'CLONE_NESTED_COMPONENT',
                payload: { rowId, sectionId, columnIdx, subSectionId, subColumnId, componentId }
            });
        };

        const cloneNestedSection = (rowId, sectionId, subSectionId, colIdx) => {
            dispatch({
                type: 'CLONE_NESTED_SECTION',
                payload: { rowId, sectionId, subSectionId, colIdx }
            });
        };

        const removeComponent = (rowId, sectionId, columnIdx, componentIdx, isMainToSub = false ,isMainToMain=false, tempFromSectionId ='',isSecton = '' , columnIndex ='') => {
            dispatch({
                type: 'REMOVE_COMPONENT_FROM_COLUMN',
                payload: {
                    rowId,
                    sectionId,
                    columnIdx,
                    componentIdx,
                    isMainToSub ,
                    isMainToMain,
                    tempFromSectionId,
                    isSecton,columnIndex// Pass the flag to the reducer
                }
            });
        };
    
        const removeSubComponent = (rowId, sectionId, subSectionId, columnIdx, componentIdx, isMainToSub = false,fromsectionId= '') => {
            
            dispatch({
                type: 'REMOVE_SUB_COMPONENT_FROM_COLUMN',
                payload: {
                    rowId,
                    sectionId,
                    subSectionId,
                    columnIdx,
                    componentIdx,
                    isMainToSub,
                    fromsectionId 
                }
            });
        };

        const handleRemove = (removeFunction, params) => {
            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!',
                customClass: {
                    popup: 'swal2-popup-custom'
                }
            }).then((result) => {
                if (result.isConfirmed) {
                    removeFunction(...params);
                    Swal.fire({
                        title: 'Deleted!',
                        text: 'The item has been removed.',
                        icon: 'success',
                        timer: 2000,
                        showConfirmButton: false,
                        customClass: {
                            popup: 'swal2-popup-custom'
                        }
                    });
                }
            });
        };

        // Handle adding nested columns and hide actions when clicked
        const handleButtonClick = (rowId, sectionId, columnIdx, layoutKey) => {
            addColumnInColumn(rowId, sectionId, columnIdx, layoutKey);
        };

        const handleResetDrop = (id) => {
            setDropResetFlags((prev) => ({ ...prev, [id]: true }));
        };


        const openRowStyleEditor = (row) => {
            setCurrentTargetForStyle({
              // if you do not set entityType to 'section', it treats it like a row
              id: row.id,
              styles: row.styles, 
              // anything else you need
            });
            setStyleModalShow(true);
        };

        // Function to open the modal for section styling
        const openSectionStyleEditor = (rowId, section) => {
            setCurrentTargetForStyle({
              entityType: 'section',
              rowId,        // so the reducer knows which row to update
              id: section.id,
              data: section // the entire section object with columns, etc.
            });
            setStyleModalShow(true);
        };

        const toggleSidebar = () => {
            setIsSidebarVisible((prevState) => !prevState);
        };


        const addButtonRef = useRef(null);


        const handleClose = () => {
            setActiveRowId(null);
        };

        // State to track which row's grid selection popover is active
        const [activeRowId, setActiveRowId] = useState(null);

        // UpdateSectionInRow with closing the popover
        const updateSectionInRow = useCallback((rowId, layoutKey) => {
            const columns = columnLayouts[layoutKey];
            dispatch({ type: 'UPDATE_SECTION_IN_ROW', payload: { rowId, columns } });
            handleClose(); // Close the popover after selection
        }, []);

        return (
            <DndProvider backend={HTML5Backend}>
                <div className="container-fluid page-build-container">
                    <BuilderHeader
                        pages={pages}
                        selectedPageId={currentPage ? currentPage.id : null}
                        onPageChange={handlePageChange}
                        onSavePage={savePage}
                        loading={loading}
                        currentPageURL={currentPageURL}
                    />

                    {loading ? <Loading text="" /> : ''}
                    <div className="row builder-wrap">
                        {isSidebarVisible && (
                            <div className="col-md-3 left-col">
                                <Sidebar />
                            </div>
                        )}
                        <div className={isSidebarVisible ? "col-md-9 page-builder-right" : "col-md-12 page-builder-right"}>
                            <button
                                className={`btn toggle-sidebar-btn ${isSidebarVisible ? 'sidebar-visible' : 'sidebar-hidden'}`}
                                onClick={toggleSidebar}
                            >
                                {isSidebarVisible ? <FontAwesomeIcon icon={faChevronLeft} /> : <FontAwesomeIcon icon={faChevronRight} />}
                            </button>

                          {rows.map((row, index) => (
                            <RowItem
                              key={row.id}
                              row={row}
                              index={index}

                              moveRow={moveRow}
                              removeRow={removeRow}
                              cloneRow={cloneRow}
                              toggleRowState={toggleRowState}
                              collapsedRows={collapsedRows}
                              toggleCollapse={toggleCollapse}
                              openRowStyleEditor={openRowStyleEditor}
                              moveSection={moveSection}
                              removeSection={removeSection}
                              cloneSection={cloneSection}
                              openSectionStyleEditor={openSectionStyleEditor}
                              changeLayout={changeLayout}
                              columnLayouts={columnLayouts}
                              toggleSectionCollapse={toggleSectionCollapse}
                              collapsedSections={collapsedSections}
                              toggleSectionState={toggleSectionState}
                              updateSectionInRow={updateSectionInRow}
                              
                              addComponentToColumn={addComponentToColumn}
                              dropResetFlags={dropResetFlags}
                              removeSubSection={removeSubSection}
                              cloneNestedSection={cloneNestedSection}
                              toggleSubSectionState={toggleSubSectionState}
                              changeSubLayout={changeSubLayout}
                              addSubComponentToColumn={addSubComponentToColumn}
                              moveSubSection={moveSubSection}
                              
                              handleRemove={handleRemove}
                              removeSubComponent={removeSubComponent}
                              removeComponent={removeComponent}
                              cloneComponent={cloneComponent}
                              cloneNestedComponent={cloneNestedComponent}
                              handleEditComponent={handleEditComponent}
                              saveChanges={saveChanges}
                              
                              handleButtonClick={handleButtonClick}
                              activeRowId={activeRowId}
                              addButtonRef={addButtonRef}
                            />
                          ))}

                            {rows.length === 0 ? (
                                <div className="add-section-first">
                                    <button className="add-row-btn" onClick={addRow}><FontAwesomeIcon icon={faPlus} /> Add New Section</button>
                                    <p>
                                        Welcome to the Page Builder! Click the button above to add your first section.
                                        Organize your content by adding rows and columns as needed.
                                    </p>
                                </div>
                            ) : (

                                <button className="add-row-btn" onClick={addRow}>
                                    <FontAwesomeIcon icon={faPlus} /> Add New Section
                                </button>
                            )}

                        </div>
                    </div>
                </div>

                <ComponentEditorModal
                    show={modalShow}
                    handleClose={() => setModalShow(false)}
                    component={currentComponent}
                    saveChanges={saveChanges}
                />

                <StyleEditorModal
                    show={styleModalShow}
                    handleClose={() => setStyleModalShow(false)}
                    currentTarget={currentTargetForStyle}
                    // We won't use `existingStyles` prop because the new code references `currentTargetForStyle?.styles`
                    onSave={(updatedStyles) => {
                      // This is how we dispatch to the reducer
                      dispatch({
                        type: 'UPDATE_STYLES',
                        payload: {
                          target: currentTargetForStyle,
                          styles: updatedStyles
                        }
                      });
                      setStyleModalShow(false);
                    }}
                  />
            
            </DndProvider>
        );
    }

    export default PageBuilder;
