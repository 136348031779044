// src/utils/BuilderHeader.js

import React, { useState, useRef, useEffect } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faFloppyDisk,
    faEye,
    faClone,
    faSpinner,
    faEllipsisVertical,
    faChevronRight,
    faCode,
    faBars
} from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import MetaDataModal from './MetaDataModal'; // Import the MetaDataModal component

function BuilderHeader({
    pages = [],
    selectedPageId,
    onPageChange,
    onSavePage,
    loading,
    currentPageURL,
}) {
    const selectedPage = pages.find((page) => page.id === selectedPageId);
    const navigate = useNavigate();

    // State for Page Selector Dropdown
    const [pageDropdownOpen, setPageDropdownOpen] = useState(false);
    const pageDropdownRef = useRef(null);

    // State for MetaData Modal
    const [showMetaDataModal, setShowMetaDataModal] = useState(false);
    const [metaDataContext, setMetaDataContext] = useState(''); // 'header' or 'footer'

    // Toggle function for Page Selector Dropdown
    const togglePageDropdown = () => {
        setPageDropdownOpen((prev) => !prev);
    };

    // Handle clicks outside of the Page Selector Dropdown
    const handleClickOutside = (event) => {
        if (
            pageDropdownRef.current &&
            !pageDropdownRef.current.contains(event.target)
        ) {
            setPageDropdownOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    // Handlers to open MetaData Modal
    const handleCustomizeHeader = () => {
        setMetaDataContext('header');
        setShowMetaDataModal(true);
    };

    // Handlers to open MetaData Modal
    const handleCustomStyle = () => {
        setMetaDataContext('customStyles');
        setShowMetaDataModal(true);
    };


    const handleCustomizeFooter = () => {
        setMetaDataContext('footer');
        setShowMetaDataModal(true);
    };

    return (
        <nav className="navbar navbar-light">
            <div className="build-nav">
                <div className="row align-items-center justify-content-between">
                    <div className="col-md-3">
                        {/* Left Section: Title and Page Selector */}
                        <div className="d-flex align-items-center justify-content-between page-info">
                            <div className="page-link-name">
                                {selectedPage ? selectedPage.title : 'Select Page'}
                                <span>{currentPageURL}</span>
                            </div>
                            <div className="select-page" ref={pageDropdownRef}>
                                <FontAwesomeIcon
                                    icon={faEllipsisVertical}
                                    onClick={togglePageDropdown}
                                    style={{ cursor: 'pointer' }}
                                />
                                {pageDropdownOpen && (
                                    <div className="page-name-display">
                                        <ul>
                                            {pages.length > 0 ? (
                                                pages.map((page) => (
                                                    <li
                                                        key={page.id}
                                                        onClick={() => {
                                                            onPageChange(page.id);
                                                            setPageDropdownOpen(false);
                                                        }}
                                                        className={
                                                            page.id === selectedPageId
                                                                ? 'active'
                                                                : ''
                                                        }
                                                        style={{ cursor: 'pointer' }}
                                                    >
                                                        <FontAwesomeIcon icon={faChevronRight} />{' '}
                                                        {page.title}
                                                    </li>
                                                ))
                                            ) : (
                                                <li>No pages available</li>
                                            )}
                                        </ul>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>

                    <div className="col-md-9 d-flex align-items-center justify-content-between">
                        {/* Center Section: Primary Actions */}
                        <div className="d-flex align-items-center build-nav-right">
                            <button className="btn me-2">
                                <FontAwesomeIcon icon={faClone} /> Clone Page
                            </button>
                            <button
                                className="btn me-2"
                                onClick={() => {
                                    if (selectedPageId) {
                                        navigate(`/preview/${selectedPage.slug}/${selectedPage.id}`);
                                    } else {
                                        alert('No page selected to preview.');
                                    }
                                }}
                            >
                                <FontAwesomeIcon icon={faEye} /> Preview Site
                            </button>
                        </div>

                        <div className="d-flex align-items-center">
                            {/* Right Section: Customization Options */}
                            <div className="save-head">
                                <button
                                    className="btn btn-light"
                                    onClick={onSavePage}
                                    disabled={loading}
                                >
                                    {loading ? (
                                        <>
                                            <FontAwesomeIcon icon={faSpinner} /> Saving...
                                        </>
                                    ) : (
                                        <>
                                            <FontAwesomeIcon icon={faFloppyDisk} /> Save
                                        </>
                                    )}
                                </button>
                            </div>

                            <div className="pub-head">
                                <button className="btn btn-primary">
                                    Publish
                                </button>
                            </div>

                            {/* Settings Menu (Unchanged) */}
                            <Dropdown align="end" className="">
                                <Dropdown.Toggle
                                    as="div"
                                    className="cust-head"
                                    style={{ cursor: 'pointer' }}
                                    id="dropdown-customize"
                                >
                                    Settings
                                </Dropdown.Toggle>


                                <Dropdown.Menu className="page-set-dropdown">
                                    <Dropdown.Item onClick={handleCustomizeHeader}>
                                        <FontAwesomeIcon icon={faBars} className="me-2" />
                                        Customize Header
                                    </Dropdown.Item>
                                    <Dropdown.Item onClick={handleCustomizeFooter}>
                                        <FontAwesomeIcon icon={faCode} className="me-2" />
                                        Customize Footer
                                    </Dropdown.Item>
                                     <Dropdown.Item onClick={handleCustomStyle}>
                                        <FontAwesomeIcon icon={faBars} className="me-2" />
                                        Custom Styles
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div>
                </div>
            </div>

            {/* MetaData Modal */}
            <MetaDataModal
                show={showMetaDataModal}
                handleClose={() => setShowMetaDataModal(false)}
                context={metaDataContext}
                selectedPageId={selectedPageId}
            />
        </nav>
    );
}

BuilderHeader.propTypes = {
    pages: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
            title: PropTypes.string.isRequired,
            slug: PropTypes.string, // Assuming slug is part of the page object
        })
    ),
    selectedPageId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onPageChange: PropTypes.func.isRequired,
    onSavePage: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    currentPageURL: PropTypes.string,
};

export default BuilderHeader;
