import React from 'react';
import { Form } from 'react-bootstrap';

const BadgeForm = ({ formValues, setFormValues }) => {
    const badgeVariants = [
        'text-bg-primary',
        'text-bg-secondary',
        'text-bg-success',
        'text-bg-danger',
        'text-bg-warning',
        'text-bg-info',
        'text-bg-light',
        'text-bg-dark',
    ];

    return (
        <>
        <div className="carousel-wrap pb-2">
            <Form.Group controlId="formBadgeText" className="mb-3">
                <Form.Label>Badge Text</Form.Label>
                <Form.Control
                    type="text"
                    name="text"
                    value={formValues.text || ''}
                    onChange={(e) => setFormValues({ ...formValues, text: e.target.value })}
                    placeholder="Enter badge text"
                />
            </Form.Group>
            <Form.Group controlId="formBadgeVariant" className="mb-3">
                <Form.Label>Badge Variant</Form.Label>
                <Form.Control
                    as="select"
                    name="variant"
                    value={formValues.variant || ''}
                    onChange={(e) => setFormValues({ ...formValues, variant: e.target.value })}
                >
                    <option value="">Select Variant</option>
                    {badgeVariants.map((variant) => (
                        <option key={variant} value={variant}>
                            {variant.replace('text-bg-', '').toUpperCase()}
                        </option>
                    ))}
                </Form.Control>
            </Form.Group>
        </div>
        </>
    );
};

export default BadgeForm;
