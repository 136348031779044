import React from 'react';
import DefaultBreadcrumb from './templates/breadcrumb/DefaultBreadcrumb';
import ModernBreadcrumb from './templates/breadcrumb/ModernBreadcrumb';
import CustomBreadcrumb from './templates/breadcrumb/CustomBreadcrumb';
import MinimalBreadcrumb from './templates/breadcrumb/MinimalBreadcrumb';
import { useResponsiveStyles } from './useResponsiveStyles';


const Breadcrumb = ({ items, separator = '/', template = 'default', styles = {} }) => {

  const appliedStyles = useResponsiveStyles(styles);

  switch (template) {
    case 'Modern':
      return <ModernBreadcrumb items={items} separator={separator} styles={appliedStyles} />;
    case 'Custom':
      return <CustomBreadcrumb items={items} separator={separator} styles={appliedStyles} />;
    case 'Minimal':
      return <MinimalBreadcrumb items={items} separator={separator} styles={appliedStyles} />;
    default:
      return <DefaultBreadcrumb items={items} separator={separator} styles={appliedStyles} />;
  }
};

export default Breadcrumb;
