import React from 'react';
import SingleColumnContactForm from './templates/contactForm/SingleColumnContactForm';
import TwoColumnContactForm from './templates/contactForm/TwoColumnContactForm';
import { useResponsiveStyles } from './useResponsiveStyles'; // Import the responsive styles hook

const ContactFormComponent = ({ formFields, template = 'SingleColumn', styles = {}, onSubmit }) => {
    const appliedStyles = useResponsiveStyles(styles); // Get responsive styles

    switch (template) {
        case 'TwoColumn':
            return <TwoColumnContactForm formFields={formFields} styles={appliedStyles} onSubmit={onSubmit} />;
        case 'SingleColumn':
        default:
            return <SingleColumnContactForm formFields={formFields} styles={appliedStyles} onSubmit={onSubmit} />;
    }
};

export default ContactFormComponent;
