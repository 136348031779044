import React from 'react';
import { useResponsiveStyles } from './useResponsiveStyles';


function TextComponent({ content, styles = {} }) {

    const appliedStyles = useResponsiveStyles(styles);

    return (
        <div style={appliedStyles.container}>
            <p style={appliedStyles.content}>{content || ""}</p>
        </div>
    );
}

export default TextComponent;
