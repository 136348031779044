// src/pages/component/AppDataContext.js

import React, { createContext, useState, useEffect, useContext } from 'react';
import { API_URL } from '../../config';
    
const AppDataContext = createContext();

export const useAppData = () => useContext(AppDataContext);

export const AppDataProvider = ({ children }) => {
    const [menus, setMenus] = useState([]);
    const [logoUrl, setLogoUrl] = useState('');
    const [isDataLoading, setIsDataLoading] = useState(true);
    const [headerBackgroundColor, setHeaderBackgroundColor] = useState('');
    const [headerMenuFontColor, setHeaderMenuFontColor] = useState('');
    const [customStyles, setCustomStyles] = useState(''); // New state for custom styles

    const subdomain = window.location.hostname.split('.')[0];  // Simplified for demonstration


// const subdomain = 'Charles';

    useEffect(() => {
        const fetchData = async () => {
            setIsDataLoading(true);
            try {
                const [menuResponse, logoResponse] = await Promise.all([
                    fetch(`${API_URL}/menus?account_key=${subdomain}`),
                    fetch(`${API_URL}/meta-data?account_key=${subdomain}`, {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify({
                            identifiers: [
                                'website_logo',
                                'header_background_color',
                                'header_menu_font_color',
                                'custom_styles', // Added 'custom_styles'
                            ],
                        }),
                    }),
                ]);

                const menuData = await menuResponse.json();
                const metaData = await logoResponse.json();

                if (menuData) {
                    setMenus(Object.values(menuData)); // Assuming data structure needs this conversion
                }

                metaData.forEach(item => {
                    switch (item.identifier) {
                        case 'website_logo':
                            setLogoUrl(item.data);
                            break;
                        case 'header_background_color':
                            setHeaderBackgroundColor(item.data);
                            break;
                        case 'header_menu_font_color':
                            setHeaderMenuFontColor(item.data);
                            break;
                        case 'custom_styles':
                            setCustomStyles(item.data); // Set custom styles
                            break;
                        default:
                            break;
                    }
                });

                setIsDataLoading(false);
            } catch (error) {
                console.error('Failed to fetch app data:', error);
                setIsDataLoading(false);
            }
        };

        fetchData();
    }, [subdomain]);

    return (
        <AppDataContext.Provider
            value={{
                menus,
                logoUrl,
                isDataLoading,
                subdomain,
                headerBackgroundColor,
                headerMenuFontColor,
                customStyles, // Provide customStyles in context
            }}
        >
            {children}
        </AppDataContext.Provider>
    );
};
