import React from 'react';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faTwitter, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons';

const SplitTeamTemplate = ({ headline, description, teamMembers, styles = {} }) => (
    <div className="team-section-split"  style={styles.container}>
        <div className="row g-4">
            {teamMembers.map((member, idx) => (
                <div key={idx} className="col-md-4 d-flex align-items-center split-content-wrap">
                    <div
                        className="team-image me-4"
                        style={{
                            backgroundImage: `url(${member.image})`,
                            ...styles.image,
                            width: '150px',
                            height: '150px',
                            borderRadius: '10px',
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                        }}
                    ></div>
                    <div className="team-info">
                        <h5 style={styles.name}>{member.name}</h5>
                        <p style={styles.position}>{member.position}</p>
                        <div className="social-media-icons">
                            <a href={member.facebook} className="icon-link" style={styles.icon}>
                                <FontAwesomeIcon icon={faFacebook} />
                            </a>
                            <a href={member.twitter} className="icon-link" style={styles.icon}>
                                <FontAwesomeIcon icon={faTwitter} />
                            </a>
                            <a href={member.instagram} className="icon-link" style={styles.icon}>
                                <FontAwesomeIcon icon={faInstagram} />
                            </a>
                            <a href={member.linkedin} className="icon-link" style={styles.icon}>
                                <FontAwesomeIcon icon={faLinkedin} />
                            </a>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    </div>
);

export default SplitTeamTemplate;
