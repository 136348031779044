import React, { useState, useEffect } from 'react';
import { 
    Modal, 
    Button, 
    Tabs, 
    Tab, 
    ButtonGroup, 
    ToggleButton, 
    Tooltip 
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
    faXmark, 
    faCheck, 
    faDesktop, 
    faTabletAlt, 
    faMobileAlt, 
    faCopy 
} from '@fortawesome/free-solid-svg-icons';

import StyleEditor from './StyleEditor';

const defaultBreakpointStyles = { desktop: {}, tablet: {}, mobile: {} };

const StyleEditorModal = ({ show, handleClose, currentTarget, onSave }) => {
    // Identify if it's a "section"
    const isSection = currentTarget?.entityType === 'section';

    // For rows: store row-level styles for each device.
    // For sections: we ignore container-level styling, only columns.
    const [styles, setStyles] = useState(defaultBreakpointStyles);

    // For sections: store each column’s device-specific styles
    const [columns, setColumns] = useState([]);

    // Track which device/breakpoint is active
    const [activeBreakpoint, setActiveBreakpoint] = useState('desktop');

    useEffect(() => {
        if (!currentTarget) return;

        if (isSection) {
            // We only load columns, each with { desktop, tablet, mobile } styles
            const colData = (currentTarget.data?.columns || []).map((col) => ({
                ...col,
                styles: {
                    desktop: col.styles?.desktop || {},
                    tablet: col.styles?.tablet || {},
                    mobile: col.styles?.mobile || {},
                },
            }));
            setColumns(colData);
            // Clear out row-level styles
            setStyles(defaultBreakpointStyles);
        } else {
            // It's a row (or something else)
            const rowStyles = currentTarget?.styles || {};
            setStyles({
                desktop: rowStyles.desktop || {},
                tablet: rowStyles.tablet || {},
                mobile: rowStyles.mobile || {},
            });
            setColumns([]);
        }
    }, [currentTarget, isSection]);

    // Merge new style changes into the current breakpoint (for rows)
    const handleRowStyleChange = (newStyles) => {
        setStyles((prev) => ({
            ...prev,
            [activeBreakpoint]: newStyles,
        }));
    };

    // Merge new style changes for columns (for sections)
    const handleColumnStyleChange = (colIndex, newStyles) => {
        setColumns((prevCols) => {
            const updated = [...prevCols];
            updated[colIndex] = {
                ...updated[colIndex],
                styles: {
                    ...updated[colIndex].styles,
                    [activeBreakpoint]: newStyles,
                },
            };
            return updated;
        });
    };

    // “Copy from Desktop” logic
    const handleCopyStyles = () => {
        if (activeBreakpoint === 'desktop') {
            alert('Desktop is the source. Select a different breakpoint to copy TO.');
            return;
        }

        if (!isSection) {
            // For row
            setStyles((prev) => ({
                ...prev,
                [activeBreakpoint]: { ...prev.desktop },
            }));
        } else {
            // For section, copy each column’s desktop => activeBreakpoint
            setColumns((prevCols) =>
                prevCols.map((col) => ({
                    ...col,
                    styles: {
                        ...col.styles,
                        [activeBreakpoint]: { ...col.styles.desktop },
                    },
                }))
            );
        }

        alert(`Copied Desktop styles to ${capitalizeFirstLetter(activeBreakpoint)}.`);
    };

    const capitalizeFirstLetter = (str) =>
        str.charAt(0).toUpperCase() + str.slice(1);

    // Final save
    const handleSave = () => {
        if (isSection) {
            // Return updated columns (no container-level style)
            const updatedSection = {
                ...currentTarget.data,
                columns: columns.map((col) => ({
                    ...col,
                    styles: { ...col.styles },
                })),
            };
            onSave(updatedSection);
        } else {
            onSave(styles);
        }
        handleClose();
    };

    // Helper function: renders the device toggle row + tooltips + copy button
    const renderDeviceToggles = () => (
        <>
            <div className="d-flex align-items-center device-view-pop">
                <ButtonGroup>
                    <ToggleButton
                        key="desktop"
                        id="desktop"
                        type="radio"
                        variant={activeBreakpoint === 'desktop' ? 'primary' : 'outline-primary'}
                        name="breakpoint"
                        value="desktop"
                        checked={activeBreakpoint === 'desktop'}
                        onChange={(e) => setActiveBreakpoint(e.currentTarget.value)}
                        data-tooltip-id="tooltip-desktop"
                        data-tooltip-content="Desktop"
                    >
                        <FontAwesomeIcon icon={faDesktop} size="lg" />
                    </ToggleButton>
                    <ToggleButton
                        key="tablet"
                        id="tablet"
                        type="radio"
                        variant={activeBreakpoint === 'tablet' ? 'primary' : 'outline-primary'}
                        name="breakpoint"
                        value="tablet"
                        checked={activeBreakpoint === 'tablet'}
                        onChange={(e) => setActiveBreakpoint(e.currentTarget.value)}
                        data-tooltip-id="tooltip-tablet"
                        data-tooltip-content="Tablet"
                    >
                        <FontAwesomeIcon icon={faTabletAlt} size="lg" />
                    </ToggleButton>
                    <ToggleButton
                        key="mobile"
                        id="mobile"
                        type="radio"
                        variant={activeBreakpoint === 'mobile' ? 'primary' : 'outline-primary'}
                        name="breakpoint"
                        value="mobile"
                        checked={activeBreakpoint === 'mobile'}
                        onChange={(e) => setActiveBreakpoint(e.currentTarget.value)}
                        data-tooltip-id="tooltip-mobile"
                        data-tooltip-content="Mobile"
                    >
                        <FontAwesomeIcon icon={faMobileAlt} size="lg" />
                    </ToggleButton>
                </ButtonGroup>

                {/* Copy from Desktop if not on Desktop */}
                {activeBreakpoint !== 'desktop' && (
                    <div className="tooltip-wrapper" style={{ marginLeft: '1rem' }}>
                        <Button
                            variant="outline-secondary"
                            className="copy-button"
                            onClick={handleCopyStyles}
                            aria-label="Copy Styles from Desktop"
                        >
                            <FontAwesomeIcon icon={faCopy} />
                        </Button>
                        <span className="tooltip-text" onClick={handleCopyStyles}>
                            Copy desktop style
                        </span>
                    </div>
                )}
            </div>

            {/* Add the <Tooltip> components here (if you're using react-tooltip) */}
            <Tooltip id="tooltip-desktop" place="top" effect="solid" />
            <Tooltip id="tooltip-tablet" place="top" effect="solid" />
            <Tooltip id="tooltip-mobile" place="top" effect="solid" />
        </>
    );

    return (
        <Modal className="model-styling" show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>
                    {isSection ? 'Edit Section Columns' : 'Edit Row Style'}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {/* If it's a row: show a single Tab for "Row Styles", plus device toggles inside */}
                {!isSection && (
                    <Tabs defaultActiveKey="styles" id="row-style-editor-tabs" className="">
                        <Tab eventKey="styles" title="Row Styles">
                            {renderDeviceToggles()}
                            <StyleEditor
                                styles={styles[activeBreakpoint] || {}}
                                onChange={handleRowStyleChange}
                                editableFields={[
                                    'backgroundColor',
                                    'border',
                                    'margin',
                                    'padding',
                                    'backgroundImage', 
                                    'borderRadius',
                                    'display'
                                ]}
                            />
                        </Tab>
                    </Tabs>
                )}

                {/* If it's a section: we only have columns; each column is a Tab. Device toggles inside each tab. */}
                {isSection && columns.length > 0 && (
                    <Tabs defaultActiveKey="0" id="columns-tabs" className="">
                        {columns.map((col, idx) => (
                            <Tab 
                                eventKey={idx.toString()} 
                                title={`Column ${idx + 1}`} 
                                key={idx}
                            >
                                {renderDeviceToggles()}
                                <StyleEditor
                                    styles={col.styles[activeBreakpoint] || {}}
                                    onChange={(newStyles) => handleColumnStyleChange(idx, newStyles)}
                                    editableFields={[
                                        'backgroundColor',
                                        'border',
                                        'margin',
                                        'padding',
                                        'backgroundImage'
                                    ]}
                                />
                            </Tab>
                        ))}
                    </Tabs>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="danger" onClick={handleClose}>
                    <FontAwesomeIcon icon={faXmark} /> Close
                </Button>
                <Button variant="success" onClick={handleSave}>
                    <FontAwesomeIcon icon={faCheck} /> Save
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default StyleEditorModal;
