import React from 'react';
import { Card } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faTwitter, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons';

const ClassicTeamTemplate = ({ headline, description, teamMembers, styles = {} }) => (
    <div className="team-section-classic"  style={styles.container}>
        
        <div className="row g-4">
            {teamMembers.map((member, idx) => (
                <div key={idx} className="col-md-4">
                    <Card style={styles.card} className="team-member-card-classic">
                        <div
                            className="image-wrap"
                            style={{
                                ...styles.image,
                                backgroundImage: `url(${member.image})`,
                                height: '250px',
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                            }}
                        ></div>
                        <Card.Body>
                            <Card.Title style={styles.name}>{member.name}</Card.Title>
                            <Card.Text style={styles.position}>{member.position}</Card.Text>
                            <div className="social-media-icons d-flex justify-content-center">
                                <a href={member.facebook} className="icon-link" style={styles.icon}>
                                    <FontAwesomeIcon icon={faFacebook} />
                                </a>
                                <a href={member.twitter} className="icon-link" style={styles.icon}>
                                    <FontAwesomeIcon icon={faTwitter} />
                                </a>
                                <a href={member.instagram} className="icon-link" style={styles.icon}>
                                    <FontAwesomeIcon icon={faInstagram} />
                                </a>
                                <a href={member.linkedin} className="icon-link" style={styles.icon}>
                                    <FontAwesomeIcon icon={faLinkedin} />
                                </a>
                            </div>
                        </Card.Body>
                    </Card>
                </div>
            ))}
        </div>
    </div>
);

export default ClassicTeamTemplate;
