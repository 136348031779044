import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useResponsiveStyles } from './useResponsiveStyles';


const IconComponent = ({ icon, title, description, buttonText, template = "Classic", styles = {} }) => {

    const appliedStyles = useResponsiveStyles(styles);
            return (
                <div className="card icon-card-modern" style={appliedStyles.container || {}}>
                    <div className="card-body text-center">
                        {icon && (
                            <div
                                className="icon-wrap"
                                style={appliedStyles.iconWrap || {}}
                            >
                                <FontAwesomeIcon
                                    icon={icon}
                                    size="2x"
                                    style={appliedStyles.icon || {}}
                                />
                            </div>
                        )}
                      
                    </div>
                </div>
            );
};

export default IconComponent;
