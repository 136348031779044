// Menus.jsx

import React, { useEffect, useState } from 'react';
import { Spinner, Alert, Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { useAppData } from '../AppDataContext';
import { API_URL } from '../../../config';
import { useResponsiveStyles } from './useResponsiveStyles'; // Import the hook

const Menus = ({
  selectedMenus = [],
  styles = {},
  template = "Horizontal" // Default to Horizontal
}) => {
  const [menus, setMenus] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { subdomain } = useAppData();

  // Apply responsive styles
  const appliedStyles = useResponsiveStyles(styles);

  // Destructure styles for different parts
  const navbarStyles = appliedStyles?.navbar || {};
  const toggleStyles = appliedStyles?.toggle || {};
  const navStyles = appliedStyles?.nav || {};
  const navLinkStyles = appliedStyles?.navLink || {};
  const navDropdownStyles = appliedStyles?.navDropdown || {};
  const dropdownItemStyles = appliedStyles?.dropdownItem || {};

  // Styles for Vertical Template
  const verticalMenuStyles = appliedStyles?.verticalMenu || {};
  const verticalMenuItemStyles = appliedStyles?.verticalMenuItem || {};
  const verticalSubMenuStyles = appliedStyles?.verticalSubMenu || {};
  const verticalDropdownItemStyles = appliedStyles?.verticalDropdownItem || {};

  const spinnerStyles = appliedStyles?.spinner || {};
  const alertStyles = appliedStyles?.alert || {};
  const noMenusMessageStyles = appliedStyles?.noMenusMessage || {};

  // Fetch menus based on selectedMenus and subdomain
  useEffect(() => {
    const fetchMenus = async () => {
      try {
        setLoading(true);

        // Extract IDs if selectedMenus is an array of objects
        const menuIds = selectedMenus.map((menu) => menu.value);

        const response = await fetch(
          `${API_URL}/menus?account_key=${subdomain}&menus_id=${menuIds.join(',')}`
        );
        const data = await response.json();

        if (response.ok && Array.isArray(data)) {
          setMenus(data);
        } else {
          throw new Error(data.message || 'Failed to fetch menus.');
        }
      } catch (err) {
        console.error('Error fetching menus:', err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    if (selectedMenus.length > 0) {
      fetchMenus();
    } else {
      setMenus([]);
      setLoading(false);
    }
  }, [selectedMenus, subdomain]);

  // Function to generate URL based on link_type
  const getMenuLink = (menu) => {
    switch (menu.link_type) {
      case 'category':
        return `/products?cat=${menu.id}`;
      case 'product':
      case 'productListingPage':
        return '/products';
      case 'blog':
        return `/blog/${menu.blog_id}`;
      default:
        return menu.link || '#';
    }
  };

  // Recursive function to render menus and submenus for Horizontal Template
  const renderHorizontalMenuItems = (menusList) => {
    return menusList.map((menu) => {
      const hasSubMenus = menu.subMenus && menu.subMenus.length > 0;
      if (hasSubMenus) {
        return (
          <NavDropdown
            title={menu.title}
            id={`nav-dropdown-${menu.id}`}
            key={menu.id}
            className="menu-dropdown"
            style={navDropdownStyles}
          >
            {renderHorizontalSubMenuItems(menu.subMenus)}
          </NavDropdown>
        );
      } else {
        const href = getMenuLink(menu);
        return (
          <Nav.Link
            href={href}
            key={menu.id}
            className="nav-link"
            style={navLinkStyles}
          >
            {menu.title}
          </Nav.Link>
        );
      }
    });
  };

  // Recursive function to render submenus for Horizontal Template
  const renderHorizontalSubMenuItems = (subMenus) => {
    return subMenus.map((subMenu) => {
      const hasSubMenus = subMenu.subMenus && subMenu.subMenus.length > 0;
      if (hasSubMenus) {
        return (
          <NavDropdown
            title={subMenu.title}
            id={`nav-subdropdown-${subMenu.id}`}
            key={subMenu.id}
            className="submenu-dropdown"
            drop="end"
            style={navDropdownStyles}
          >
            {renderHorizontalSubMenuItems(subMenu.subMenus)}
          </NavDropdown>
        );
      } else {
        const href = getMenuLink(subMenu);
        return (
          <NavDropdown.Item
            href={href}
            key={subMenu.id}
            className="dropdown-item"
            style={dropdownItemStyles}
          >
            {subMenu.title}
          </NavDropdown.Item>
        );
      }
    });
  };

  // Recursive function to render menus and submenus for Vertical Template
  const renderVerticalMenuItems = (menusList) => {
    return (
      <ul style={verticalMenuStyles} className="vertical-menu">
        {menusList.map((menu) => {
          const hasSubMenus = menu.subMenus && menu.subMenus.length > 0;
          return (
            <li key={menu.id} style={verticalMenuItemStyles} className="vertical-menu-item">
              {hasSubMenus ? (
                <>
                  <span style={{ cursor: 'pointer', ...verticalMenuItemStyles }}>{menu.title}</span>
                  {renderVerticalSubMenuItems(menu.subMenus)}
                </>
              ) : (
                <a href={getMenuLink(menu)} style={verticalMenuItemStyles}>
                  {menu.title}
                </a>
              )}
            </li>
          );
        })}
      </ul>
    );
  };

  // Recursive function to render submenus for Vertical Template
  const renderVerticalSubMenuItems = (subMenus) => {
    return (
      <ul style={verticalSubMenuStyles} className="vertical-submenu">
        {subMenus.map((subMenu) => {
          const hasSubMenus = subMenu.subMenus && subMenu.subMenus.length > 0;
          return (
            <li key={subMenu.id} style={verticalMenuItemStyles} className="vertical-submenu-item">
              {hasSubMenus ? (
                <>
                  <span style={{ cursor: 'pointer', ...verticalMenuItemStyles }}>{subMenu.title}</span>
                  {renderVerticalSubMenuItems(subMenu.subMenus)}
                </>
              ) : (
                <a href={getMenuLink(subMenu)} style={verticalDropdownItemStyles}>
                  {subMenu.title}
                </a>
              )}
            </li>
          );
        })}
      </ul>
    );
  };

  // Render loading state
  if (loading) {
    return (
      <div className="text-center my-4" style={spinnerStyles}>
        <Spinner animation="border" role="status" style={spinnerStyles}>
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  }

  // Render error state
  if (error) {
    return (
      <div className="text-center my-4" style={alertStyles}>
        <Alert variant="danger" style={alertStyles}>
          {error}
        </Alert>
      </div>
    );
  }

  // Render no menus found
  if (menus.length === 0) {
    return (
      <div className="text-center my-4" style={noMenusMessageStyles}>
        <p style={noMenusMessageStyles}>No menus found.</p>
      </div>
    );
  }

  // Render Horizontal or Vertical Menu based on template prop
  return template === "Vertical" ? (
    <nav className="vertical-menu-container" style={verticalMenuStyles}>
      {renderVerticalMenuItems(menus)}
    </nav>
  ) : (
    // Default to Horizontal Template
    <Navbar
      expand="lg"
      style={navbarStyles}
      variant="light"
      className="navbar"
    >
      <Navbar.Toggle aria-controls="navbar-nav" style={toggleStyles} />
      <Navbar.Collapse id="navbar-nav">
        <Nav className="me-auto" style={navStyles}>
          {renderHorizontalMenuItems(menus)}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default Menus;
