import React from 'react';
import { useResponsiveStyles } from './useResponsiveStyles';


function DividerComponent({ styles = {} }) {

    const appliedStyles = useResponsiveStyles(styles);

    const dividerStyles = appliedStyles.divider || {};

    return <hr style={dividerStyles} />;
}

export default DividerComponent;
