import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

import { useResponsiveStyles } from './useResponsiveStyles';


const PricingTableComponent = ({ plans = [], template = "Classic", styles = {} }) => {
    const appliedStyles = useResponsiveStyles(styles);

    const renderFeatures = (features) => (
        features.map((feature, featureIdx) => (
            <li key={featureIdx} style={appliedStyles.featureItem}>
                <FontAwesomeIcon icon={faCheck} style={appliedStyles.icon} /> {feature}
            </li>
        ))
    );

    const commonCardStyles = {
        header: {
            fontFamily: appliedStyles.planName?.fontFamily,
            fontSize: appliedStyles.planName?.fontSize,
            color: appliedStyles.planName?.color,
            fontWeight: appliedStyles.planName?.fontWeight,
            textAlign: appliedStyles.planName?.textAlign,
            margin: appliedStyles.planName?.margin,
            padding: appliedStyles.planName?.padding,
        },
        price: {
            fontFamily: appliedStyles.price?.fontFamily,
            fontSize: appliedStyles.price?.fontSize,
            color: appliedStyles.price?.color,
            fontWeight: appliedStyles.price?.fontWeight,
            textAlign: appliedStyles.price?.textAlign,
            margin: appliedStyles.price?.margin,
            padding: appliedStyles.price?.padding,
        },
        features: {
            fontFamily: appliedStyles.features?.fontFamily,
            fontSize: appliedStyles.features?.fontSize,
            color: appliedStyles.features?.color,
            fontWeight: appliedStyles.features?.fontWeight,
            lineHeight: appliedStyles.features?.lineHeight,
            textAlign: appliedStyles.features?.textAlign,
        },
        button: {
            backgroundColor: appliedStyles.button?.backgroundColor,
            color: appliedStyles.button?.color,
            fontFamily: appliedStyles.button?.fontFamily,
            fontSize: appliedStyles.button?.fontSize,
            fontWeight: appliedStyles.button?.fontWeight,
            borderRadius: appliedStyles.button?.borderRadius,
            margin: appliedStyles.button?.margin,
            padding: appliedStyles.button?.padding,
        },
    };

    switch (template) {
        case "Classic":
            return (
                <div className="row pricing-classic-wrap">
                    {plans.map((plan, idx) => (
                        <div key={idx} className="col-md-4">
                            <div className="card classic-card mb-4">
                                <div className="pricing-card-price" style={commonCardStyles.price}>
                                    ${plan.price} <small>/month</small>
                                </div>
                                    
                                <div className="card-header" style={commonCardStyles.header}>
                                    <h4 className="my-0 font-weight-normal">{plan.name}</h4>
                                </div>
                                <div className="card-body">
                                    <ul className="list-unstyled" style={commonCardStyles.features}>
                                        {renderFeatures(plan.features)}
                                    </ul>
                                </div>
                                <div className="card-footer classic-card-footer">
                                    <button
                                        type="button"
                                        className="btn btn-primary"
                                        style={commonCardStyles.button}
                                    >
                                        Sign up
                                    </button>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            );

        case "Modern":
            return (
                <div className="row pricing-modern-wrap">
                    {plans.map((plan, idx) => (
                        <div key={idx} className="col-md-4 p-0">
                            <div className="card modern-card mb-4">
                                <div className="card-header" style={commonCardStyles.header}>
                                    <h4 className="card-title" style={commonCardStyles.header}>{plan.name}</h4>
                                    <div className="pricing-card-price" style={commonCardStyles.price}>
                                        ${plan.price} <small>/month</small>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <ul className="card-features list-unstyled" style={commonCardStyles.features}>
                                        {renderFeatures(plan.features)}
                                    </ul>
                                </div>
                                <div className="card-footer modern-card-footer">
                                    <button
                                        type="button"
                                        className="btn btn-outline-primary"
                                        style={commonCardStyles.button}
                                    >
                                        Get Started
                                    </button>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            );

        default:
            return (
                <div className="row pricing-default-wrap">
                    {plans.map((plan, idx) => (
                        <div key={idx} className="col-md-4">
                            <div className="card default-card mb-4">
                                <div className="card-header" style={commonCardStyles.header}>
                                    {plan.name}
                                </div>
                                <div className="card-body">
                                    <h3 style={commonCardStyles.price}><span>${plan.price} <small>/month</small></span></h3>
                                    <ul className="list-unstyled" style={commonCardStyles.features}>
                                        {renderFeatures(plan.features)}
                                    </ul>
                                </div>
                                <div className="card-footer default-card-footer">
                                    <button
                                        type="button"
                                        className="btn btn-primary"
                                        style={commonCardStyles.button}
                                    >
                                        Select
                                    </button>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            );
    }
};

export default PricingTableComponent;
