import React from 'react';
import { useResponsiveStyles } from './useResponsiveStyles';


const HorizontalCard = ({ title, imageUrl, bodyText, buttonText, styles = {}, template = "Default" }) => {

    const appliedStyles = useResponsiveStyles(styles);

    switch (template) {
        case "HorizontalCardLeft":
            return (
                <div className="card horizontal-card-left mb-3" style={appliedStyles.container}>
                    <div className="row g-0">
                        <div className="col-md-8">
                            <div className="card-body" style={appliedStyles.body}>
                                <h5 className="card-title" style={appliedStyles.title}>{title}</h5>
                                <p className="card-text" style={appliedStyles.bodyText}>{bodyText}</p>

                                 {buttonText && (
                                    <a href="#" className="btn btn-primary" style={appliedStyles.button}>
                                        {buttonText}
                                    </a>
                                )}

                               
                            </div>
                        </div>
                        <div className="col-md-4">
                            <img
                                src={imageUrl}
                                className="img-fluid"
                                alt="Split with Border"
                                style={appliedStyles.image}
                            />
                        </div>
                    </div>
                </div>
            );

        case "HorizontalCardTop":
            return (
                <div className="card horizontal-card-top mb-3" style={appliedStyles.container}>
                    <div className="row g-0">
                        <div className="col-md-12 card-img-h-t">
                            <img
                                src={imageUrl}
                                className="img-fluid"
                                alt="Modern Outline"
                                style={appliedStyles.image}
                            />
                        </div>
                        <div className="col-md-12">
                            <div className="card-body" style={appliedStyles.body}>
                                <h5 className="card-title" style={appliedStyles.title}>{title}</h5>
                                <p className="card-text" style={appliedStyles.bodyText}>{bodyText}</p>

                                 {buttonText && (
                                    <a href="#" className="btn btn-primary" style={appliedStyles.button}>
                                        {buttonText}
                                    </a>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            );

        case "HorizontalCardBottom":
            return (
                <div className="card horizontal-card-bottom mb-3" style={appliedStyles.container}>
                    <div className="row g-0">
                        <div className="col-md-12">
                            <div className="card-body" style={appliedStyles.body}>
                                <h5 className="card-title" style={appliedStyles.title}>{title}</h5>
                                <p className="card-text" style={appliedStyles.bodyText}>{bodyText}</p>
                                <a href="#" className="btn btn-primary" style={appliedStyles.button}>
                                    {buttonText}
                                </a>
                            </div>
                        </div>
                        <div className="col-md-12 card-img-h-t">
                            <img
                                src={imageUrl}
                                className="img-fluid"
                                alt="Modern Outline"
                                style={appliedStyles.image}
                            />
                        </div>
                    </div>
                </div>
            );

        case "HorizontalCardRoundedLeft":
            return (
                <div className="card horizontal-card-rounded-Left mb-3" style={appliedStyles.container}>
                    <div className="row g-0">
                        <div className="col-md-4 card-img-r-l">
                            <img
                                src={imageUrl}
                                className="img-fluid "
                                alt="Framed with Background"
                                style={appliedStyles.image}
                            />
                        </div>
                        <div className="col-md-8">
                            <div className="card-body" style={appliedStyles.body}>
                                <h5 className="card-title" style={appliedStyles.title}>{title}</h5>
                                <p className="card-text" style={appliedStyles.bodyText}>{bodyText}</p>

                                 {buttonText && (
                                    <a href="#" className="btn btn-primary" style={appliedStyles.button}>
                                        {buttonText}
                                    </a>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            );

        case "HorizontalCardRoundedTop":
            return (
                <div className="card horizontal-card-rounded-top mb-3" style={appliedStyles.container}>
                    <div className="row g-0">
                        <div className="col-md-12 card-img-r-l">
                            <img
                                src={imageUrl}
                                className="img-fluid shadow-sm"
                                alt="Image with Shadow"
                                style={appliedStyles.image}
                            />
                        </div>
                        <div className="col-md-12">
                            <div className="card-body" style={appliedStyles.body}>
                                <h5 className="card-title" style={appliedStyles.title}>{title}</h5>
                                <p className="card-text" style={appliedStyles.bodyText}>{bodyText}</p>

                                 {buttonText && (
                                    <a href="#" className="btn btn-primary" style={appliedStyles.button}>
                                        {buttonText}
                                    </a>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            );

        default: // Default layout
            return (
                <div className="card horiz-default-card mb-3" style={appliedStyles.container}>
                    <div className="row g-0">
                        <div className="col-md-4">
                            <img
                                src={imageUrl}
                                className="img-fluid rounded-start"
                                alt="Default"
                                style={appliedStyles.image}
                            />
                        </div>
                        <div className="col-md-8">
                            <div className="card-body" style={appliedStyles.body}>
                                <h5 className="card-title" style={appliedStyles.title}>{title}</h5>
                                <p className="card-text" style={appliedStyles.bodyText}>{bodyText}</p>

                                 {buttonText && (
                                    <a href="#" className="btn btn-primary" style={appliedStyles.button}>
                                        {buttonText}
                                    </a>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            );
    }
};

export default HorizontalCard;
