// RichTextEditor.js
import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // For the snow theme

const RichTextEditor = ({ value, onChange }) => {
    const modules = {
        toolbar: [
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }, { 'font': [] }], // Add H1 to H6 options
            [{size: []}],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{'list': 'ordered'}, {'list': 'bullet'}, 
             {'indent': '-1'}, {'indent': '+1'}],
            [{ 'color': [] }, { 'background': [] }], // Add color and background color options
            ['link', 'image'],
            [{ 'align': [] }],
            ['clean']
        ],
    };

    const formats = [
        'header', 'font', 'size',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent',
        'link', 'image', 'align',
        'color', 'background' // Include color and background formats
    ];

    return (
        <ReactQuill 
            value={value} 
            onChange={onChange} 
            modules={modules}
            formats={formats}
        />
    );
};

export default RichTextEditor;
