import React from 'react';
import { useResponsiveStyles } from './useResponsiveStyles';

function BlockquoteComponent({ template = "default", quote, author, styles = {} }) {
    const appliedStyles = useResponsiveStyles(styles);

    const containerClassName = `${template.toLowerCase()}`;

    switch (template) {
        case "Modern":
            return (
                <blockquote className={containerClassName} style={appliedStyles.container}>
                    <p style={appliedStyles.blockquote}>{quote || "Default quote text goes here."}</p>
                    <footer className="blockquote-footer" style={appliedStyles.footer}>
                        {author || "Unknown Author"}
                    </footer>
                </blockquote>
            );

        case "Minimal":
            return (
                <blockquote className={containerClassName} style={appliedStyles.container}>
                    <p style={appliedStyles.blockquote}>{quote || "Default quote text goes here."}</p>
                    <footer className="blockquote-footer" style={appliedStyles.footer}>
                        {author || "Unknown Author"}
                    </footer>
                </blockquote>
            );

        case "Roundedquote":
            return (
                <blockquote className={containerClassName} style={appliedStyles.container}>
                    <div className="quote-content" style={appliedStyles.blockquote}>
                        <p>{quote || "Default quote text goes here."}</p>
                        <footer className="blockquote-footer" style={appliedStyles.footer}>
                            {author || "Unknown Author"}
                        </footer>
                    </div>
                </blockquote>
            );

        default: // Grid
            return (
                <blockquote className={containerClassName} style={appliedStyles.container}>
                    <p style={appliedStyles.blockquote}>{quote || "Default quote text goes here."}</p>
                    <footer className="blockquote-footer" style={appliedStyles.footer}>
                        {author || "Unknown Author"}
                    </footer>
                </blockquote>
            );
    }
}

export default BlockquoteComponent;
