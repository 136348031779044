import React from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const CustomPopover = ({
    trigger = 'click',
    placement = 'bottom',
    title = 'Insert Row',
    layouts = {},
    onLayoutSelect = () => {},
    triggerElement,
    showCloseButton = true,
}) => {
    return (
        <OverlayTrigger
            trigger={trigger}
            placement={placement}
            rootClose
            overlay={
                <Popover className="model-popover grid-model-popover">
                    {showCloseButton && (
                        <Popover.Header className="d-flex justify-content-between align-items-center">
                            <span>{title}</span>
                            <button
                                type="button"
                                className="close-button"
                                style={{
                                    border: 'none',
                                    cursor: 'pointer',
                                    background: 'transparent'
                                }}
                                onClick={() => {
                                    // Simulate an outside click to close the popover
                                    const clickEvent = new MouseEvent('click', { bubbles: true });
                                    document.body.dispatchEvent(clickEvent);
                                }}
                            >
                                <FontAwesomeIcon icon={faTimes} />
                            </button>
                        </Popover.Header>
                    )}
                    <Popover.Body>
                        <div className="grid-selection row">
                            {Object.keys(layouts).map((layoutKey) => (
                                <button
                                    key={layoutKey}
                                    className="btn grid-icon col-md-3"
                                    onClick={() => {
                                        onLayoutSelect(layoutKey);
                                        // Also simulate outside click after selection, so it closes automatically
                                        const clickEvent = new MouseEvent('click', { bubbles: true });
                                        document.body.dispatchEvent(clickEvent);
                                    }}
                                >
                                    <div className="grid-inner-wrap">
                                        {layouts[layoutKey].map((colClass, idx) => (
                                            <div key={idx} className={`${colClass}`}>
                                                <div className="grid-inner"></div>
                                            </div>
                                        ))}
                                    </div>
                                </button>
                            ))}
                        </div>
                    </Popover.Body>
                </Popover>
            }
        >
            {triggerElement}
        </OverlayTrigger>
    );
};

export default CustomPopover;
