import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useResponsiveStyles } from './useResponsiveStyles';
import { nl2br } from '../../utils/nl2br'; // Adjust the import path as needed

const IconCard = ({ icon, title, description, buttonText, template = "Classic", styles = {} }) => {

    const appliedStyles = useResponsiveStyles(styles);

    // Helper function to safely extract the button link
    const getButtonLink = () => {
        if (appliedStyles.button && appliedStyles.button.link) {
            return appliedStyles.button.link;
        }
        return "#";
    };

    switch (template) {
        case "Modern":
            return (
                <div className="card icon-card-modern" style={appliedStyles.container || {}}>
                    <div className="card-body text-center">
                        {icon && (
                            <div
                                className="icon-wrap"
                                style={appliedStyles.iconWrap || {}}
                            >
                                <FontAwesomeIcon
                                    icon={icon}
                                    size="2x"
                                    style={appliedStyles.icon || {}}
                                />
                            </div>
                        )}
                        {title && (
                            <h5 className="card-title" style={appliedStyles.title || {}}>
                                {nl2br(title)}
                            </h5>
                        )}
                        {description && (
                            <p className="card-text" style={appliedStyles.description || {}}>
                                {nl2br(description)}
                            </p>
                        )}
                        {buttonText && (
                            <a
                                href={getButtonLink()}
                                className="btn btn-primary"
                                style={appliedStyles.button || {}}
                            >
                                {buttonText}
                            </a>
                        )}
                    </div>
                </div>
            );

        case "Minimal":
            return (
                <div className="card icon-card-minimal text-left" style={appliedStyles.container || {}}>
                    <div className="d-flex align-items-center">
                        <div className="ic-classic-icon" style={appliedStyles.iconWrap || {}}>
                            {icon && (
                                <FontAwesomeIcon
                                    icon={icon}
                                    size="2x"
                                    style={appliedStyles.icon || { marginRight: '10px' }}
                                />
                            )}
                        </div>
                        {title && (
                            <h5 className="card-title" style={appliedStyles.title || {}}>
                                {nl2br(title)}
                            </h5>
                        )}
                    </div>
                    {description && (
                        <p className="card-text" style={appliedStyles.description || {}}>
                            {nl2br(description)}
                        </p>
                    )}
                    {buttonText && (
                        <a
                            href={getButtonLink()}
                            className="btn btn-primary"
                            style={appliedStyles.button || {}}
                        >
                            {buttonText}
                        </a>
                    )}
                </div>
            );

        default: // Classic
            return (
                <div className="card icon-card-classic text-center" style={appliedStyles.container || {}}>
                    <div className="card-body">
                        <div className="ic-classic-icon" style={appliedStyles.iconWrap || {}}>
                            {icon && (
                                <FontAwesomeIcon
                                    icon={icon}
                                    size="1x"
                                    style={appliedStyles.icon || {}}
                                />
                            )}
                        </div>
                        {title && (
                            <h5 className="card-title" style={appliedStyles.title || {}}>
                                {nl2br(title)}
                            </h5>
                        )}
                        {description && (
                            <p className="card-text" style={appliedStyles.description || {}}>
                                {nl2br(description)}
                            </p>
                        )}
                        {buttonText && (
                            <a
                                href={getButtonLink()}
                                className="btn btn-primary"
                                style={appliedStyles.button || {}}
                            >
                                {buttonText}
                            </a>
                        )}
                    </div>
                </div>
            );
    }
};

export default IconCard;
