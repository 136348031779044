import React from 'react';
import { Form } from 'react-bootstrap';

const TextForm = ({ formValues, setFormValues }) => (
    <div className="carousel-wrap pb-2">
        {/* Text Content Field */}
        <Form.Group controlId="formTextContent" className="mb-3">
            <Form.Label>Text Content</Form.Label>
            <Form.Control
                as="textarea"
                placeholder="Enter text content"
                value={formValues.content || ''}
                onChange={(e) => setFormValues({ ...formValues, content: e.target.value })}
            />
        </Form.Group>
    </div>
);

export default TextForm;
