import React, { useState, useEffect } from 'react';
import { Modal, Button, Tabs, Tab, Form, InputGroup } from 'react-bootstrap';
import { useDropzone } from 'react-dropzone';
import { API_URL } from '../../config';
import { useAppData } from './AppDataContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark, faPlus, faSearch } from '@fortawesome/free-solid-svg-icons';

const MediaLibrary = ({ onSelectImage }) => {
    const [mediaItems, setMediaItems] = useState([]);
    const [premiumMediaItems, setPremiumMediaItems] = useState([]);
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);
    const [premiumLoading, setPremiumLoading] = useState(false);
    const [activeTab, setActiveTab] = useState('media'); // Default tab is Media Library
    const [searchQuery, setSearchQuery] = useState('');

    // Get `account_key` (subdomain) from AppData context
    const { subdomain } = useAppData();

    // Fetch media items for Media Library (account-specific)
    const fetchMediaItems = async () => {
        try {
            const response = await fetch(`${API_URL}/media?account_key=${subdomain}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            // Check if response is OK
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();

            // Ensure data.media is an array; default to empty array if undefined or null
            setMediaItems(Array.isArray(data.media) ? data.media : []);
        } catch (error) {
            console.error('Failed to fetch media:', error);
            // Optionally, set mediaItems to empty array on error
            setMediaItems([]);
        }
    };

    // Fetch premium media items with optional search
    const fetchPremiumMediaItems = async (query = '') => {
        setPremiumLoading(true);
        try {
            const url = query
                ? `${API_URL}/media/premium?account_key=${subdomain}&search=${encodeURIComponent(query)}`
                : `${API_URL}/media/premium?account_key=${subdomain}`;
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            // Check if response is OK
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();

            // Ensure data.media is an array; default to empty array if undefined or null
            setPremiumMediaItems(Array.isArray(data.media) ? data.media : []);
        } catch (error) {
            console.error('Failed to fetch premium media:', error);
            // Optionally, set premiumMediaItems to empty array on error
            setPremiumMediaItems([]);
        } finally {
            setPremiumLoading(false);
        }
    };

    // Upload media file using Dropzone with account_key
    const handleFileUpload = async (acceptedFiles) => {
        if (acceptedFiles.length === 0) return;

        const formData = new FormData();
        formData.append('file', acceptedFiles[0]);
        formData.append('account_key', subdomain); // Ensure account_key is sent

        setLoading(true);

        try {
            const response = await fetch(`${API_URL}/media/upload`, {
                method: 'POST',
                body: formData,
            });

            // Check if response is OK
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();

            if (data.success && data.media) {
                setMediaItems((prevItems) => [data.media, ...prevItems]); // Add new media to the media list
                setActiveTab('media'); // Switch to the media library tab on successful upload
            } else {
                console.error('Upload failed:', data.error || 'Unknown error');
            }
        } catch (error) {
            console.error('Failed to upload file:', error);
        } finally {
            setLoading(false);
        }
    };

    // Dropzone setup for image uploading
    const { getRootProps, getInputProps } = useDropzone({
        onDrop: handleFileUpload,
        accept: 'image/*',
        multiple: false
    });

    // Fetch media items on component mount and when subdomain changes
    useEffect(() => {
        fetchMediaItems();
    }, [subdomain]);

    // Fetch premium media items when Premium Media tab is activated
    useEffect(() => {
        if (activeTab === 'premium') {
            // Do not trigger fetch on searchQuery change
            // fetchPremiumMediaItems(searchQuery);
            // Instead, only fetch when the tab is activated
            // Users can initiate search via the search button
            // Optionally, fetch all premium media when tab is activated without any search query
            fetchPremiumMediaItems('');
        }
    }, [activeTab, subdomain]);

    // Open Media Library when clicking 'Change Media'
    const openMediaLibrary = () => {
        setShow(true);
        setActiveTab('media'); // Set default tab to Media Library
    };

    // Handle search input change
    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    // Handle search form submission
    const handleSearchSubmit = (e) => {
        e.preventDefault();
        fetchPremiumMediaItems(searchQuery);
    };

    return (
        <>
            <div onClick={openMediaLibrary} className="chg-media-img" style={{ cursor: 'pointer' }}>
                Change Media
            </div>

            <Modal className="model-styling" show={show} onHide={() => setShow(false)} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Media Library</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Tabs activeKey={activeTab} onSelect={(k) => setActiveTab(k)}>
                        <Tab eventKey="media" title="Media Library">
                            <div className="media-library mt-3 d-flex flex-wrap">
                                {Array.isArray(mediaItems) && mediaItems.length > 0 ? (
                                    mediaItems.map((media) => (
                                        <img
                                            key={media.id}
                                            src={media.url}
                                            alt={media.title}
                                            onClick={() => {
                                                onSelectImage(media.url);
                                                setShow(false); // Close the modal upon selecting an image
                                            }}
                                            style={{
                                                cursor: 'pointer',
                                                margin: '10px',
                                                maxWidth: '100px',
                                                maxHeight: '100px',
                                                border: '1px solid #ddd',
                                                borderRadius: '5px'
                                            }}
                                        />
                                    ))
                                ) : (
                                    <p>No media available.</p>
                                )}
                            </div>
                        </Tab>
                        <Tab eventKey="upload" title="Upload Media">
                            <div className="mt-3 upload-img-pop" {...getRootProps()}>
                                <FontAwesomeIcon icon={faPlus} />{' '}
                                {loading ? 'Uploading...' : 'Drag & drop an image here, or click to select one'}
                                <input {...getInputProps()} />
                            </div>
                        </Tab>
                        <Tab eventKey="premium" title="Premium Media">
                            <div className="mt-3">
                                <Form onSubmit={handleSearchSubmit}>
                                    <InputGroup className="mb-3">
                                        <Form.Control
                                            type="text"
                                            placeholder="Search premium media by keyword..."
                                            value={searchQuery}
                                            onChange={handleSearchChange}
                                        />
                                        <Button variant="outline-secondary" type="submit">
                                            <FontAwesomeIcon icon={faSearch} />
                                        </Button>
                                    </InputGroup>
                                </Form>
                                {premiumLoading ? (
                                    <p>Loading...</p>
                                ) : (
                                    <div className="premium-media-library d-flex flex-wrap">
                                        {Array.isArray(premiumMediaItems) && premiumMediaItems.length > 0 ? (
                                            premiumMediaItems.map((media) => (
                                                <img
                                                    key={media.id}
                                                    src={media.url}
                                                    alt={media.title}
                                                    onClick={() => {
                                                        onSelectImage(media.url);
                                                        setShow(false); // Close the modal upon selecting an image
                                                    }}
                                                    style={{
                                                        cursor: 'pointer',
                                                        margin: '10px',
                                                        maxWidth: '100px',
                                                        maxHeight: '100px',
                                                        border: '1px solid #ddd',
                                                        borderRadius: '5px'
                                                    }}
                                                />
                                            ))
                                        ) : (
                                            <p>No premium media found.</p>
                                        )}
                                    </div>
                                )}
                            </div>
                        </Tab>
                    </Tabs>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={() => setShow(false)}>
                        <FontAwesomeIcon icon={faXmark} /> Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default MediaLibrary;
