import React from 'react';

const TimelineComponent = ({ events, template = 'Classic', styles = {} }) => {
    const renderTimelineEvents = (additionalClasses = '') =>
        events.map((event, idx) => (
            <li key={idx} className={`timeline-event ${additionalClasses}`}>
                <div className="timeline-panel"  style={styles.panel || {}}>
                    <div className="date-wrap" style={styles.date || {}}>
                        {event.date} <span>{event.year}</span>
                    </div>
                    <div className="timeline-heading">
                        <h4 className="timeline-title"  style={styles.heading || {}}>{event.title}</h4>
                    </div>
                    <div className="timeline-body">
                        <p  style={styles.body || {}}>{event.description}</p>
                    </div>
                </div>
            </li>
        ));

    switch (template) {
        case 'Modern':
            return (
                <div className="timeline-modern">
                    <ul className="timeline">{renderTimelineEvents('modern')}</ul>
                </div>
            );

        case 'Minimal':
            return (
                <div className="timeline-minimal">
                    <ul className="timeline">{renderTimelineEvents('minimal')}</ul>
                </div>
            );

        case 'Vertical':
            return (
                <div className="timeline-vertical">
                    <ul className="timeline">{renderTimelineEvents('vertical')}</ul>
                </div>
            );

        case 'Gradient':
            return (
                <div className="timeline-gradient">
                    <ul className="timeline">{renderTimelineEvents('gradient')}</ul>
                </div>
            );

        default: // Classic
            return (
                <div className="timeline-classic">
                    <ul className="timeline">{renderTimelineEvents('classic')}</ul>
                </div>
            );
    }
};

export default TimelineComponent;
