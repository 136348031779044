import React from 'react';
import ClassicCarousel from './templates/carousel/ClassicCarousel';
import ModernCarousel from './templates/carousel/ModernCarousel';
import MinimalCarousel from './templates/carousel/MinimalCarousel';
import { useResponsiveStyles } from './useResponsiveStyles';


function CarouselComponent({ items, template, styles = {} }) {
    const appliedStyles = useResponsiveStyles(styles);

    switch (template) {
        case 'Modern':
            return <ModernCarousel items={items} styles={appliedStyles} />;
        case 'Minimal':
            return <MinimalCarousel items={items} styles={appliedStyles} />;
        case 'Classic':
        default:
            return <ClassicCarousel items={items} styles={appliedStyles} />;
    }
}

export default CarouselComponent;
