import React from 'react';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faTwitter, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons';

const MinimalGridTeamTemplate = ({ headline, description, teamMembers, styles = {} }) => (
    <div className="minimal-grid-wrap">
        <div className="row g-4 justify-content-center">
            {teamMembers.map((member, idx) => (
                <div key={idx} className="col-md-3">
                    <div className="minimal-grid-item">
                        <div
                            className="minimal-grid-image"
                            style={{
                                backgroundImage: `url(${member.image})`,
                                ...styles.image,
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                            }}
                        ></div>
                        <h5 style={styles.name}>{member.name}</h5>
                        <p style={styles.position}>{member.position}</p>

                        <div className="social-media-icons">
                            <a href={member.facebook} className="icon-link" style={styles.icon}>
                                <FontAwesomeIcon icon={faFacebook} />
                            </a>
                            <a href={member.twitter} className="icon-link" style={styles.icon}>
                                <FontAwesomeIcon icon={faTwitter} />
                            </a>
                            <a href={member.instagram} className="icon-link" style={styles.icon}>
                                <FontAwesomeIcon icon={faInstagram} />
                            </a>
                            <a href={member.linkedin} className="icon-link" style={styles.icon}>
                                <FontAwesomeIcon icon={faLinkedin} />
                            </a>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    </div>
);

export default MinimalGridTeamTemplate;
