    import React, { useReducer, useRef, useCallback, useState, useEffect } from 'react';
    import 'bootstrap/dist/css/bootstrap.min.css';
    import { DndProvider } from 'react-dnd';
    import { HTML5Backend } from 'react-dnd-html5-backend';
    import Dropdown from 'react-bootstrap/Dropdown';
    import DropdownButton from 'react-bootstrap/DropdownButton';
    import { useParams } from 'react-router-dom';
    import Swal from 'sweetalert2';
    import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
    import DraggableComponent from './component/DraggableComponent';
    import DroppableColumn from './component/DroppableColumn';
    import ComponentEditorModal from './component/ComponentEditorModal';
    import { columnLayouts } from './config/columnLayouts';
    import DroppableRow from './component/droppable/DroppableRow';
    import DroppableSection from './component/droppable/DroppableSection';
    import DroppableSubSection from './component/droppable/DroppableSubSection';
    import pageBuilderReducer from './reducers/pageBuilderReducer';
    import StyleEditorModal from './component/StyleEditorModal';
    import Sidebar from './component/Sidebar';
    import Loading from './component/Loading';
    import { OverlayTrigger, Popover } from 'react-bootstrap';
    import CustomPopover from './component/CustomPopover'; // Adjust the path as necessary

    import {
        faPlus,
        faTimes,
        faTrashCan,
        faArrowsUpDownLeftRight,
        faEyeSlash,
        faEye,
        faClone,
        faPenToSquare,
        faChevronRight,
        faChevronLeft,
        faCog,
        faAngleUp,
        faAngleDown,
    } from '@fortawesome/free-solid-svg-icons';

    import { API_URL } from '../config';
    import { componentTypes } from './config/componentTypes';
    import { fetchPostData, savePostData } from './utils/apis';
    import BuilderPostHeader from './utils/BuilderPostHeader';
    import { useAppData } from './component/AppDataContext';

    import { useNavigate } from 'react-router-dom'; // Import useNavigate
    const initialState = { rows: [] };

    function PostBuilder() {
        const [loading, setLoading] = useState(false);
        const navigate = useNavigate(); // Initialize useNavigate
        const [{ rows }, dispatch] = useReducer(pageBuilderReducer, initialState);
        const [modalShow, setModalShow] = useState(false);
        const [currentComponent, setCurrentComponent] = useState(null);
        const [currentSectionId, setCurrentSectionId] = useState(null);
        const [currentColumnIdx, setCurrentColumnIdx] = useState(null);
        const [currentComponentIdx, setCurrentComponentIdx] = useState(null);
        const [currentSubSectionId, setCurrentSubSectionId] = useState(null);
        const { postId } = useParams();
        const [dropResetFlags, setDropResetFlags] = useState({});
        const [collapsedRows, setCollapsedRows] = useState({});
        const [collapsedSections, setCollapsedSections] = useState({});

        const [isSidebarVisible, setIsSidebarVisible] = useState(true);
        const [styleModalShow, setStyleModalShow] = useState(false);
        const [currentTargetForStyle, setCurrentTargetForStyle] = useState(null);
        const { subdomain } = useAppData();


        const [pages, setPages] = useState([]);
        const [currentPage, setCurrentPage] = useState(null);
        const [currentPageURL, setCurrentPageURL] = useState('');
    

        // Fetch page data when pageId changes
        useEffect(() => {
            if (postId) {
                setLoading(true);
                fetchPostData(postId)
                    .then((pageData) => {
                        dispatch({ type: 'LOAD_PAGE_DATA', payload: pageData });
                        setLoading(false);
                    })
                    .catch((error) => {
                        console.error('Error fetching page data:', error);
                        setLoading(false);
                    });
            }
        }, [postId, subdomain]);


        // Add a new row to the builder
        const addRow = useCallback(() => {
            dispatch({ type: 'ADD_ROW' });
        }, []);

        // Fetch page data on mount or when pageId changes
        useEffect(() => {
            if (postId) {
                fetchPostData(postId, subdomain).then((postData) => {
                    dispatch({ type: 'LOAD_PAGE_DATA', payload: postData });
                });
            }
        }, [postId, subdomain]);

        // Save the page data
        const savePost = useCallback(() => {
            setLoading(true);
            savePostData(postId, rows)
                .then((data) => {
                    console.log('Page saved successfully:', data);
                })
                .catch((error) => {
                    console.error('Error saving page:', error);
                })
                .finally(() => {
                    setLoading(false);
                });
        }, [postId, rows]);

        // Toggle row collapse
        const toggleCollapse = (rowId) => {
            setCollapsedRows((prevState) => ({
                ...prevState,
                [rowId]: !prevState[rowId]
            }));
        };

        // Toggle section collapse
        const toggleSectionCollapse = (sectionId) => {
            setCollapsedSections((prevState) => ({
                ...prevState,
                [sectionId]: !prevState[sectionId]
            }));
        };


        // Add columns into an existing column's section
        const addColumnInColumn = (rowId, sectionId, columnIdx, layoutKey) => {
            const newColumns = columnLayouts[layoutKey];
            dispatch({
                type: 'ADD_COLUMN_IN_COLUMN',
                payload: { rowId, sectionId, columnIdx, newColumns }
            });
        };

        const updateColumnsInRow = useCallback((rowId, sectionId, layoutKey) => {
            const columns = columnLayouts[layoutKey];
            if (!columns) return;
            dispatch({ type: 'UPDATE_COLUMNS_IN_SECTION', payload: { rowId, sectionId, columns } });
        }, []);

        const changeLayout = useCallback((rowId, sectionId, layoutKey, subSection) => {
            const columns = columnLayouts[layoutKey];
            if (!columns) return;
            dispatch({
                type: 'CHANGE_LAYOUT',
                payload: { rowId, sectionId, columns, subSection }
            });
        }, []);

        const changeSubLayout = useCallback((rowId, sectionId, layoutKey, subSection) => {
            const columns = columnLayouts[layoutKey];
            if (!columns) return;
            dispatch({
                type: 'CHANGE_SUB_SECTION_LAYOUT',
                payload: { rowId, sectionId, columns, subSection }
            });
        }, []);

        const removeColumnFromSection = useCallback((rowId, sectionId, columnIndex) => {
            dispatch({ type: 'REMOVE_COLUMN_FROM_SECTION', payload: { rowId, sectionId, columnIndex } });
        }, []);


        const addComponentToColumn = useCallback((rowId, targetSectionId, targetColumnIdx, item) => {
            const { type, sectionId, rowID, columnIdx, componentIdx, props } = item;
            const SubSectionId = item.sectionId;
            const fromRowId = item.rowID;
            const isMainToSub = sectionId !== undefined && columnIdx !== undefined && targetSectionId === undefined;
            const isMainToMain = rowId !== rowID;

            // Remove from previous location
            if (sectionId !== undefined && columnIdx !== undefined) {
                if (isMainToMain || (targetSectionId !== sectionId)) {
                    removeSubComponent(rowId, targetSectionId, SubSectionId, columnIdx, componentIdx, isMainToSub);
                }

                if (fromRowId !== undefined || !isMainToMain) {
                    removeComponent(rowId, sectionId, columnIdx, componentIdx, isMainToSub);
                }
            }

            const component = { type, props };
            dispatch({
                type: 'ADD_COMPONENT_TO_COLUMN',
                payload: { rowId, sectionId: targetSectionId, columnIdx: targetColumnIdx, component }
            });
        }, []);

        const addSubComponentToColumn = useCallback((rowId, sectionId, columnIdx, item, sectionColId, subSectionId, subColIdx) => {
            const { type, componentIdx, rowID, props } = item;
            const fromsectionId = item.sectionId;
            const originalColumnIdx = item.columnIdx;
            const isMainToSub = subSectionId !== undefined && subSectionId !== sectionId && subSectionId !== fromsectionId && fromsectionId === sectionId;
            const isMainToMain = rowId !== rowID;
            const isSectionChanged = fromsectionId !== sectionId;

            // Remove previous sub-component if needed
            if ((subColIdx !== undefined && componentIdx !== undefined && fromsectionId != null) || (!isMainToMain)) {
                removeSubComponent(rowId, sectionId, subSectionId, originalColumnIdx, componentIdx, isMainToSub, fromsectionId);
            }

            // Remove previous component if needed
            let tempFromSectionId = fromsectionId;
            if (sectionId !== undefined && columnIdx !== undefined) {
                if (!isSectionChanged) {
                    tempFromSectionId = null;
                }

                if (tempFromSectionId != null || !isMainToMain) {
                    removeComponent(rowId, sectionId, columnIdx, componentIdx, isMainToSub, isMainToMain, tempFromSectionId, isSectionChanged, originalColumnIdx);
                }
            }

            const component = { type, props };
            dispatch({
                type: 'ADD_COMPONENT_TO_SUB_COLUMN',
                payload: {
                    rowId,
                    sectionId,
                    columnIdx,
                    component,
                    subSectionId,
                    subColIdx,
                    sectionColId
                }
            });

            if (subSectionId) {
                // Reset drop after adding a sub-component
                setTimeout(() => {
                    handleResetDrop(subSectionId);
                }, 1000);
            }
        }, []);

        const removeRow = useCallback((rowId) => {
            dispatch({ type: 'REMOVE_ROW', payload: rowId });
        }, []);

        const removeSection = useCallback((rowId, sectionId) => {
            dispatch({ type: 'REMOVE_SECTION', payload: { rowId, sectionId } });
        }, []);

        const removeSubSection = useCallback((rowId, sectionId, subSectionId) => {
            dispatch({ type: 'REMOVE_SUB_SECTION', payload: { rowId, sectionId, subSectionId } });
        }, []);

        const handleEditComponent = (sectionId, columnIdx, componentIdx, component) => {
            setCurrentComponent(component);
            setCurrentSectionId(sectionId);
            setCurrentColumnIdx(columnIdx);
            setCurrentComponentIdx(componentIdx);
            setModalShow(true);
        };

        const saveChanges = (newProps) => {
            const updatedComponent = { ...currentComponent, props: newProps };
            dispatch({
                type: 'UPDATE_COMPONENT_IN_COLUMN',
                payload: {
                    sectionId: currentSectionId,
                    columnIdx: currentColumnIdx,
                    componentIdx: currentComponentIdx,
                    updatedComponent
                }
            });
            setModalShow(false);
        };

        const moveRow = (dragIndex, hoverIndex) => {
            dispatch({
                type: 'MOVE_ROW',
                payload: { fromRowIndex: dragIndex, toRowIndex: hoverIndex }
            });
        };

        const moveSection = (rowId, fromSectionIndex, toSectionIndex) => {
            dispatch({
                type: 'MOVE_SECTION',
                payload: { rowId, fromSectionIndex, toSectionIndex }
            });
        };

        const moveSubSection = (rowId, id, columnIdx, fromSubSectionIndex, toSubSectionIndex) => {
            dispatch({
                type: 'MOVE_SUB_SECTION',
                payload: { rowId, id, columnIdx, fromSubSectionIndex, toSubSectionIndex }
            });
        };

        const moveComponent = (fromSectionId, toSectionId, fromColumnIdx, toColumnIdx, fromComponentIdx, toComponentIdx) => {
            dispatch({
                type: 'MOVE_COMPONENT',
                payload: {
                    fromSectionId,
                    toSectionId,
                    fromColumnIdx,
                    toColumnIdx,
                    fromComponentIdx,
                    toComponentIdx
                }
            });
        };

        const cloneRow = (rowId) => {
            dispatch({ type: 'CLONE_ROW', payload: { rowId } });
        };

        const toggleRowState = (rowId) => {
            dispatch({ type: 'DISABLE_ROW', payload: { rowId } });
        };

        const toggleSectionState = (rowId, sectionId) => {
            dispatch({ type: 'DISABLE_SECTION', payload: { rowId, sectionId } });
        };

        const toggleSubSectionState = (rowId, sectionId, subSectionId, colIdx) => {
            dispatch({ type: 'DISABLE_SUB_SECTION', payload: { rowId, sectionId, subSectionId, colIdx } });
        };

        const cloneSection = (rowId, sectionId) => {
            dispatch({ type: 'CLONE_SECTION', payload: { rowId, sectionId } });
        };

        const cloneComponent = (rowId, sectionId, columnId, componentId) => {
            dispatch({
                type: 'CLONE_COMPONENT',
                payload: { rowId, sectionId, columnId, componentId }
            });
        };

        const cloneNestedComponent = (rowId, sectionId, columnIdx, subSectionId, subColumnId, componentId) => {
            dispatch({
                type: 'CLONE_NESTED_COMPONENT',
                payload: { rowId, sectionId, columnIdx, subSectionId, subColumnId, componentId }
            });
        };

        const cloneNestedSection = (rowId, sectionId, subSectionId, colIdx) => {
            dispatch({
                type: 'CLONE_NESTED_SECTION',
                payload: { rowId, sectionId, subSectionId, colIdx }
            });
        };

        const removeComponent = (rowId, sectionId, columnIdx, componentIdx, isMainToSub = false, isMainToMain = false, tempFromSectionId = '', isSection = '', originalColumnIdx = '') => {
            dispatch({
                type: 'REMOVE_COMPONENT_FROM_COLUMN',
                payload: {
                    rowId,
                    sectionId,
                    columnIdx,
                    componentIdx,
                    isMainToSub,
                    isMainToMain,
                    tempFromSectionId,
                    isSection,
                    columnIndex: originalColumnIdx
                }
            });
        };

        const removeSubComponent = (rowId, sectionId, subSectionId, columnIdx, componentIdx, isMainToSub = false, fromsectionId = '') => {
            dispatch({
                type: 'REMOVE_SUB_COMPONENT_FROM_COLUMN',
                payload: {
                    rowId,
                    sectionId,
                    subSectionId,
                    columnIdx,
                    componentIdx,
                    isMainToSub,
                    fromsectionId
                }
            });
        };

        const handleRemove = (removeFunction, params) => {
            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!',
                customClass: {
                    popup: 'swal2-popup-custom'
                }
            }).then((result) => {
                if (result.isConfirmed) {
                    removeFunction(...params);
                    Swal.fire({
                        title: 'Deleted!',
                        text: 'The item has been removed.',
                        icon: 'success',
                        timer: 2000,
                        showConfirmButton: false,
                        customClass: {
                            popup: 'swal2-popup-custom'
                        }
                    });
                }
            });
        };

        // Handle adding nested columns and hide actions when clicked
        const handleButtonClick = (rowId, sectionId, columnIdx, layoutKey) => {
            addColumnInColumn(rowId, sectionId, columnIdx, layoutKey);
        };

        const handleResetDrop = (id) => {
            setDropResetFlags((prev) => ({ ...prev, [id]: true }));
        };

        const openStyleEditor = (target) => {
            setCurrentTargetForStyle(target);
            setStyleModalShow(true);
        };

        const toggleSidebar = () => {
            setIsSidebarVisible((prevState) => !prevState);
        };


        const addButtonRef = useRef(null);


        const handleClose = () => {
            setActiveRowId(null);
        };

        // State to track which row's grid selection popover is active
        const [activeRowId, setActiveRowId] = useState(null);

        // UpdateSectionInRow with closing the popover
        const updateSectionInRow = useCallback((rowId, layoutKey) => {
            const columns = columnLayouts[layoutKey];
            dispatch({ type: 'UPDATE_SECTION_IN_ROW', payload: { rowId, columns } });
            handleClose(); // Close the popover after selection
        }, []);

        return (
            <DndProvider backend={HTML5Backend}>
                <div className="container-fluid page-build-container">
                    <BuilderPostHeader
                        onSavePost={savePost}
                        loading={loading}
                        currentPageURL={currentPageURL}
                    />

                    {loading ? <Loading text="" /> : ''}
                    <div className="row builder-wrap">
                        {isSidebarVisible && (
                            <div className="col-md-3 left-col">
                                <Sidebar />
                            </div>
                        )}
                        
                        <div className={isSidebarVisible ? "col-md-9 page-builder-right" : "col-md-12 page-builder-right"}>
                            <button
                                className={`btn toggle-sidebar-btn ${isSidebarVisible ? 'sidebar-visible' : 'sidebar-hidden'}`}
                                onClick={toggleSidebar}
                            >
                                {isSidebarVisible ? <FontAwesomeIcon icon={faChevronLeft} /> : <FontAwesomeIcon icon={faChevronRight} />}
                            </button>


                            {rows.map((row, index) => (
                                <DroppableRow
                                    key={row.id}
                                    index={index}
                                    moveRow={moveRow}
                                    onRemoveRow={() => removeRow(row.id)}
                                    onCloneRow={() => cloneRow(row.id)}
                                >
                                    <div key={row.id} className="mb-2 p-2 pb-5 added-row">
                                        <div className="section-edit-set-wrap d-flex justify-content-between align-items-center">
                                            <div className="actions-wrap">
                                                <button className="drag-handle">
                                                    <FontAwesomeIcon icon={faArrowsUpDownLeftRight} />
                                                </button>
                                                <button onClick={() => toggleRowState(row.id)}>
                                                    <FontAwesomeIcon icon={row.disabled ? faEyeSlash : faEye} />
                                                </button>
                                                <button onClick={() => cloneRow(row.id)}>
                                                    <FontAwesomeIcon icon={faClone} />
                                                </button>
                                                <button onClick={() => handleRemove(removeRow, [row.id])}>
                                                    <FontAwesomeIcon icon={faTrashCan} />
                                                </button>
                                                <button onClick={() => openStyleEditor(row)}>
                                                    <FontAwesomeIcon icon={faCog} />
                                                </button>
                                            </div>
                                            
                                            <span className="row-title">Row {row.id}</span>
                                            
                                            <button
                                                className="btn collapse-toggle-btn"
                                                onClick={() => toggleCollapse(row.id)}
                                            >
                                                {collapsedRows[row.id] ? (
                                                    <FontAwesomeIcon icon={faAngleDown} />
                                                ) : (
                                                    <FontAwesomeIcon icon={faAngleUp} />
                                                )}
                                            </button>
                                        </div>

                                        {!collapsedRows[row.id] && (
                                            <div className="row-content">


                                                <div className={`row ${row.disabled ? "disabled-row" : ""}`}>
                                                    {row.sections.map((section, sectionIndex) => (
                                                        <DroppableSection
                                                            key={section.id}
                                                            id={section.id}
                                                            rowId={row.id}
                                                            index={sectionIndex}
                                                            moveSection={moveSection}
                                                            onRemoveSection={() => removeSection(row.id, section.id)}
                                                            onCloneSection={() => cloneSection(row.id, section.id)}
                                                        >
                                                            <div className="applied-grid">
                                                                <div className="row-edit-set-wrap d-flex justify-content-between align-items-center">
                                                                    <div className="actions-wrap">
                                                                        <button className="drag-handle">
                                                                            <FontAwesomeIcon icon={faArrowsUpDownLeftRight} />
                                                                        </button>
                                                                        <button onClick={() => toggleSectionState(row.id, section.id)}>
                                                                            <FontAwesomeIcon icon={section.disabled ? faEyeSlash : faEye} />
                                                                        </button>
                                                                        <button onClick={() => cloneSection(row.id, section.id)}>
                                                                            <FontAwesomeIcon icon={faClone} />
                                                                        </button>
                                                                        <button onClick={() => handleRemove(removeSection, [row.id, section.id])}>
                                                                            <FontAwesomeIcon icon={faTrashCan} />
                                                                        </button>
                                                                      <CustomPopover
    title="Select Grid Layout"
    layouts={columnLayouts}
    onLayoutSelect={(layoutKey) => changeLayout(row.id, section.id, layoutKey)}
    triggerElement={
        <div style={{cursor: 'pointer', display: 'inline-block'}}>
            <svg viewBox="0 0 28 28" preserveAspectRatio="xMidYMid meet" shapeRendering="geometricPrecision" 
                style={{ fill: 'rgb(255, 255, 255)', width: '35px', minWidth: '35px', height: '36px', margin: '-3px 0 0 3px', opacity: 1, transform: 'scale(1)'}}> 
                <g>
                    <path d="M20 8H8a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V9a1 1 0 0 0-1-.999V8zm-7 2h2v8h-2v-8zm-2 8H9v-8h2v8zm6-8h2v8h-2v-8z" fillRule="evenodd"/>
                </g>
            </svg>
        </div>
    }
/>
                                                                    </div>
                                                                   <button
                                                                        className="btn collapse-toggle-btn"
                                                                        onClick={() => toggleSectionCollapse(section.id)}
                                                                    >
                                                                        {collapsedSections[section.id] ? (
                                                                            <FontAwesomeIcon icon={faAngleDown} />
                                                                        ) : (
                                                                            <FontAwesomeIcon icon={faAngleUp} />
                                                                        )}
                                                                    </button>
                                                                </div>
                                                                
    {!collapsedSections[section.id] && (
                                                                <div className={`row ${section.disabled ? "disabled-section" : ""}`}>
                                                                    {section.columns.map((column, colIdx) => (
                                                                        <DroppableColumn
                                                                            key={colIdx}
                                                                            id={`${section.id}_${colIdx}`}
                                                                            className={`${column.class} p-3-custom border col-pos-rel `}
                                                                            onDropComponent={(columnId, item) =>
                                                                                addComponentToColumn(row.id, section.id, colIdx, item, `${section.id}_${colIdx}`)
                                                                            }
                                                                            resetDrop={dropResetFlags[`${section.id}_${colIdx}`]}
                                                                        >


                                                                            {/* Nested sections */}
                                                                            {column.sections && column.sections.length > 0 && (
                                                                                <div className="row">
                                                                                    {column.sections.map((subSection, subIdx) => (
                                                                                        <DroppableSubSection
                                                                                            key={subIdx}
                                                                                            id={subSection.id}
                                                                                            rowId={row.id}
                                                                                            columnIdx={colIdx}
                                                                                            index={subIdx}
                                                                                            moveSubSection={moveSubSection}
                                                                                            onRemoveSection={() => removeSubSection(row.id, section.id, subSection.id)}
                                                                                            onCloneSection={() => cloneNestedSection(row.id, section.id, subSection.id, colIdx)}
                                                                                        >
                                                                                            <div className="applied-grid">
                                                                                                <div className="row-edit-set-wrap d-flex justify-content-between align-items-center">
                                                                                                    <div className="actions-wrap">
                                                                                                        <button className="drag-handle">
                                                                                                            <FontAwesomeIcon icon={faArrowsUpDownLeftRight} />
                                                                                                        </button>
                                                                                                        <button onClick={() => toggleSubSectionState(row.id, section.id, subSection.id, colIdx)}>
                                                                                                            <FontAwesomeIcon icon={subSection.disabled ? faEyeSlash : faEye} />
                                                                                                        </button>
                                                                                                        <button onClick={() => cloneNestedSection(row.id, section.id, subSection.id, colIdx)}>
                                                                                                            <FontAwesomeIcon icon={faClone} />
                                                                                                        </button>
                                                                                                        <button onClick={() => handleRemove(removeSubSection, [row.id, section.id, subSection.id])}>
                                                                                                            <FontAwesomeIcon icon={faTrashCan} />
                                                                                                        </button>

<CustomPopover
    title="Select Grid Layout"
    layouts={columnLayouts}
    onLayoutSelect={(layoutKey) => changeSubLayout(row.id, section.id, layoutKey, subSection.id)}
    triggerElement={
        <div style={{ cursor: 'pointer', display: 'inline-block' }}>
            <svg viewBox="0 0 28 28" preserveAspectRatio="xMidYMid meet" shapeRendering="geometricPrecision"
                style={{ fill: 'rgb(255, 255, 255)', width: '35px', minWidth: '35px', height: '36px', margin: '-6px', opacity: 1, transform: 'scale(1)' }}>
                <g>
                    <path d="M20 8H8a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V9a1 1 0 0 0-1-.999V8zm-7 2h2v8h-2v-8zm-2 8H9v-8h2v8zm6-8h2v8h-2v-8z" fillRule="evenodd"/>
                </g>
            </svg>
        </div>
    }
/>

                                                                                                    </div>
                                                                                                </div>
                                                                                                

                                                                                                <div className={`row ${subSection.disabled ? 'disabled-subsection ' : ''}`}>
                                                                                                    {subSection.columns.map((subColumn, subColIdx) => (
                                                                                                        <DroppableColumn
                                                                                                            key={subColIdx}
                                                                                                            id={`${subSection.id}_${subColIdx}`}
                                                                                                            className={`${subColumn.class} p-3-custom border`}
                                                                                                            onDropComponent={(columnId, item) =>
                                                                                                                addSubComponentToColumn(row.id, section.id, colIdx, item, `${subSection.id}_${subColIdx}`, subSection.id, subColIdx)
                                                                                                            }
                                                                                                        >
                                                                                                            {Array.isArray(subColumn.components) && subColumn.components.length > 0 ? (
                                                                                                                subColumn.components.map((comp, compIdx) =>
                                                                                                                    comp ? (
                                                                                                                        <DraggableComponent
                                                                                                                            key={compIdx}
                                                                                                                            type={comp.type}
                                                                                                                            sectionId={subSection.id}
                                                                                                                            rowID={row.id}
                                                                                                                            columnIdx={subColIdx}
                                                                                                                            componentIdx={compIdx}
                                                                                                                            props={comp.props}
                                                                                                                            onCloneNestedSection={() =>
                                                                                                                                cloneNestedComponent(row.id, section.id, colIdx, subSection.id, subColIdx, compIdx)
                                                                                                                            }
                                                                                                                        >
                                                                                                                            {React.createElement(componentTypes[comp.type], {
                                                                                                                                ...comp.props,
                                                                                                                                onSave: (newContent) =>
                                                                                                                                    saveChanges({
                                                                                                                                        ...comp.props,
                                                                                                                                        content: newContent
                                                                                                                                    })
                                                                                                                            })}
                                                                                                                            <div className="editable-wrap">
                                                                                                                                <button
                                                                                                                                    className="btn"
                                                                                                                                    onClick={() =>
                                                                                                                                        handleEditComponent(subSection.id, subColIdx, compIdx, comp)
                                                                                                                                    }
                                                                                                                                >
                                                                                                                                    <FontAwesomeIcon icon={faPenToSquare} />
                                                                                                                                </button>
                                                                                                                                <button
                                                                                                                                    className="btn"
                                                                                                                                    onClick={() =>
                                                                                                                                        handleRemove(removeSubComponent, [
                                                                                                                                            row.id,
                                                                                                                                            section.id,
                                                                                                                                            subSection.id,
                                                                                                                                            subColIdx,
                                                                                                                                            compIdx
                                                                                                                                        ])
                                                                                                                                    }
                                                                                                                                >
                                                                                                                                    <FontAwesomeIcon icon={faTrashCan} />
                                                                                                                                </button>
                                                                                                                                <button
                                                                                                                                    className="btn"
                                                                                                                                    onClick={() =>
                                                                                                                                        cloneNestedComponent(row.id, section.id, colIdx, subSection.id, subColIdx, compIdx)
                                                                                                                                    }
                                                                                                                                >
                                                                                                                                    <FontAwesomeIcon icon={faClone} />
                                                                                                                                </button>
                                                                                                                            </div>
                                                                                                                        </DraggableComponent>
                                                                                                                    ) : null
                                                                                                                )
                                                                                                            ) : (
                                                                                                                <p className="empty-component">Drag and drop a component from the left sidebar to get started!</p>
                                                                                                            )}
                                                                                                        </DroppableColumn>
                                                                                                    ))}
                                                                                                </div>
                                                                                            </div>
                                                                                        </DroppableSubSection>
                                                                                    ))}
                                                                                </div>
                                                                            )}


<div className="add-subsection-link-container">
    <CustomPopover
    trigger="click"
    placement="top"
    title="Select Grid Layout"
    layouts={columnLayouts}
    onLayoutSelect={(layoutKey) => handleButtonClick(row.id, section.id, colIdx, layoutKey)}
    triggerElement={
        <div
            className="add-subsection-link"
            style={{ cursor: 'pointer', display: 'inline-block' }}
        >
            <FontAwesomeIcon icon={faPlus} />
        </div>
    }
/>

</div>

                                                                            {/* Components directly under this column */}
                                                                            {column.components.map((comp, compIdx) => (
                                                                                <DraggableComponent
                                                                                    key={compIdx}
                                                                                    type={comp.type}
                                                                                    sectionId={section.id}
                                                                                    columnIdx={colIdx}
                                                                                    rowID={row.id}
                                                                                    componentIdx={compIdx}
                                                                                    props={comp.props}
                                                                                    onCloneSection={() => cloneComponent(row.id, section.id, colIdx, compIdx)}
                                                                                >
                                                                                    {React.createElement(componentTypes[comp.type], {
                                                                                        ...comp.props,
                                                                                        onSave: (newContent) => saveChanges({
                                                                                            ...comp.props,
                                                                                            content: newContent
                                                                                        })
                                                                                    })}
                                                                                    <div className="editable-wrap">
                                                                                        <button
                                                                                            className="btn"
                                                                                            onClick={() =>
                                                                                                handleEditComponent(section.id, colIdx, compIdx, comp)
                                                                                            }
                                                                                        >
                                                                                            <FontAwesomeIcon icon={faPenToSquare} />
                                                                                        </button>
                                                                                        <button
                                                                                            className="btn"
                                                                                            onClick={() =>
                                                                                                handleRemove(removeComponent, [
                                                                                                    row.id,
                                                                                                    section.id,
                                                                                                    colIdx,
                                                                                                    compIdx
                                                                                                ])
                                                                                            }
                                                                                        >
                                                                                            <FontAwesomeIcon icon={faTrashCan} />
                                                                                        </button>
                                                                                        <button
                                                                                            className="btn"
                                                                                            onClick={() => cloneComponent(row.id, section.id, colIdx, compIdx)}
                                                                                        >
                                                                                            <FontAwesomeIcon icon={faClone} />
                                                                                        </button>
                                                                                    </div>
                                                                                </DraggableComponent>
                                                                            ))}
                                                                        </DroppableColumn>
                                                                    ))}
                                                                </div>
                                                                )}
                                                               
                                                            </div>
                                                        </DroppableSection>
                                                    ))}
                                                </div>


                                                <div className="add-new-section-link-container">
                                                    <CustomPopover
                                                        trigger="click"
                                                        placement="top"
                                                        title="Select Grid Layout"
                                                        layouts={columnLayouts}
                                                        onLayoutSelect={(layoutKey) => updateSectionInRow(row.id, layoutKey)}
                                                        triggerElement={
                                                            <div
                                                                className="add-new-section-link"
                                                                ref={activeRowId === row.id ? addButtonRef : null}
                                                                style={{ cursor: 'pointer', display: 'inline-block' }}
                                                            >
                                                                <FontAwesomeIcon icon={faPlus} />
                                                            </div>
                                                        }
                                                    />
                                                </div>


                                            </div>
                                        )}
                                        
                                    </div>
                                </DroppableRow>
                            ))}

                            {rows.length === 0 ? (
                                <div className="add-section-first">
                                    <button className="add-row-btn" onClick={addRow}><FontAwesomeIcon icon={faPlus} /> Add New Section</button>
                                    <p>
                                        Welcome to the Page Builder! Click the button above to add your first section.
                                        Organize your content by adding rows and columns as needed.
                                    </p>
                                </div>
                            ) : (

                                <button className="add-row-btn" onClick={addRow}>
                                    <FontAwesomeIcon icon={faPlus} /> Add New Section
                                </button>
                            )}

                        </div>
                    </div>
                </div>

                <ComponentEditorModal
                    show={modalShow}
                    handleClose={() => setModalShow(false)}
                    component={currentComponent}
                    saveChanges={saveChanges}
                />
                <StyleEditorModal
                    show={styleModalShow}
                    handleClose={() => setStyleModalShow(false)}
                    currentTarget={currentTargetForStyle}
                    existingStyles={currentTargetForStyle?.styles || {}}
                    onSave={(updatedStyles) => {
                        dispatch({
                            type: 'UPDATE_STYLES',
                            payload: { target: currentTargetForStyle, styles: updatedStyles }
                        });
                        setStyleModalShow(false);
                    }}
                />
            </DndProvider>
        );
    }

    export default PostBuilder;
