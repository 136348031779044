import React from 'react';
import { Link } from 'react-router-dom';
import { useAppData } from './AppDataContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faHeadphones, faEnvelope, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons'

const Footer = () => {
    const { logoUrl } = useAppData();
   
   const logoUrlCustom = 'https://fiklu.com/dynamic-svg';


    return (
        

        <footer className="footer desktop-view web-footer">
            <div className="container-fluid">
                <div className="row text-center text-md-start">
                    <div className="col-md-12 col-lg-4">
                        <div className="row">
                            <div className="col-md-12 col-lg-12">
                                <div className="footer-brand">
                                    {logoUrl ? (
                                        <img src={logoUrl} alt="Logo" height="auto" height="80"/>
                                    ) : (
                                        <img src={logoUrlCustom} alt="Logo" width="auto" height="220" />
                                    )}
                                    <p>We're committed to environmental awareness and action. Join us in making a difference.</p>
                                </div>
                            </div>
                        </div>        
                    </div>
                    <div className="col-md-12 col-lg-8">
                        <div className="row">
                            <div className="col-md-6 col-lg-5 px-5">
                                <h3>Contact info</h3>
                                <div className="contact-info">
                                    <h4><FontAwesomeIcon icon={faHeadphones} /> Call us</h4>
                                    <p>+1-002-345-6789</p>
                                    <p>+1-009-876-5432</p>
                                </div>
                                <div className="contact-info">
                                    <h4><FontAwesomeIcon icon={faEnvelope} /> Email</h4>
                                    <p>info@fiklu-theme.com</p>
                                </div>
                                <div className="contact-info">
                                    <h4><FontAwesomeIcon icon={faMapMarkerAlt} /> Location</h4>
                                    <p>SoHo 94 Broadway St New York, NY 10012</p>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-4 ps-5">
                                <h3>Useful Links</h3>
                                <ul className="site-links">
                                    <li>Home</li>
                                    <li>About</li>
                                    <li>Services</li>
                                    <li>Team</li>
                                    <li>Gallery</li>
                                    <li>Contact</li>
                                </ul>
                            </div>

                        </div>
                    </div>

                    
                </div>
                <div className="row text-center text-md-start copyright-info">
                    <div className="col-md-6 col-sm-12 mt-4 mb-4">
                        <ul className="list-unstyled foot-links">
                            <li><Link to="/privacy/policy" className="text-decoration-none">Privacy Policy</Link></li>
                            <li><Link to="/terms/use" className="text-decoration-none">Terms of Use</Link></li>
                            <li><Link to="/contact/us" className="text-decoration-none">Need Help?</Link></li>
                        </ul>
                    </div>
                    <div className="col-md-6 col-sm-12 mt-4 text-md-end">
                        <div className="text-muted">Copyright &copy; 2023 FIKLU. All Rights Reserved.</div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
