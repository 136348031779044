import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { Form, Button, InputGroup, Spinner, Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { API_URL } from '../../../config'; // Adjust the path as needed
import { useAppData } from '../AppDataContext';

const BlogListingForm = ({ formValues, setFormValues }) => {
  const [availableBlogs, setAvailableBlogs] = useState([]);
  const [loadingBlogs, setLoadingBlogs] = useState(true);
  const [errorBlogs, setErrorBlogs] = useState(null);

  const [availablePosts, setAvailablePosts] = useState([]);
  const [loadingPosts, setLoadingPosts] = useState(false);
  const [errorPosts, setErrorPosts] = useState(null);

  const { subdomain } = useAppData();

  /**
   * Fetch the list of all blogs (for the given account/subdomain).
   */
  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        setLoadingBlogs(true);
        const response = await fetch(`${API_URL}/blogs?account_key=${subdomain}`);
        const data = await response.json();

        if (response.ok && data.success) {
          setAvailableBlogs(data.blogs); // or data if that's how your API returns
        } else {
          throw new Error(data.message || 'Failed to fetch blogs.');
        }
      } catch (err) {
        console.error('Error fetching blogs:', err);
        setErrorBlogs(err.message);
      } finally {
        setLoadingBlogs(false);
      }
    };

    fetchBlogs();
  }, [subdomain]);

  /**
   * Whenever the selected blog changes in formValues,
   * fetch the posts for that blog.
   */
  useEffect(() => {
    const fetchPosts = async () => {
      if (!formValues.selectedBlog) {
        setAvailablePosts([]);
        return;
      }

      try {
        setLoadingPosts(true);
        const blogId = formValues.selectedBlog.value;
        const response = await fetch(
          `${API_URL}/blog-posts?account_key=${subdomain}&blog_id=${blogId}`
        );
        const data = await response.json();

        if (response.ok && data.success) {
          setAvailablePosts(data.posts);
        } else {
          throw new Error(data.message || 'Failed to fetch blog posts.');
        }
      } catch (err) {
        console.error('Error fetching blog posts:', err);
        setErrorPosts(err.message);
      } finally {
        setLoadingPosts(false);
      }
    };

    fetchPosts();
  }, [formValues.selectedBlog, subdomain]);

  /**
   * Convert the fetched blogs to a format suitable for React-Select.
   */
  const blogOptions = availableBlogs.map((blog) => ({
    value: blog.id,          // Adjust if your blog has a different key
    label: blog.title,       // Adjust if your blog has a different display field
  }));

  /**
   * Convert the fetched posts to a format suitable for React-Select.
   */
  const postOptions = availablePosts.map((post) => ({
    value: post.id,          // Adjust if your post has a different key
    label: post.title,       // Adjust if your post has a different display field
  }));

  /**
   * Handle changes in the blog selection (single select).
   */
  const handleBlogChange = (selectedOption) => {
    // Reset the selectedPosts whenever the blog changes
    setFormValues({
      ...formValues,
      selectedBlog: selectedOption,
      selectedPosts: [],
    });
  };

  /**
   * Handle changes in the post selection (multi-select).
   */
  const handlePostChange = (selectedOptions) => {
    // Limit to 12 posts, similar to your product example
    if (selectedOptions.length > 12) {
      selectedOptions = selectedOptions.slice(0, 12);
    }
    setFormValues({
      ...formValues,
      selectedPosts: selectedOptions.map((option) => ({
        value: option.value,
        label: option.label,
      })),
    });
  };

  /**
   * Handle removing a post from the selected list.
   */
  const handleRemovePost = (index) => {
    const updatedPosts = [...formValues.selectedPosts];
    updatedPosts.splice(index, 1);
    setFormValues({ ...formValues, selectedPosts: updatedPosts });
  };

  return (
    <div className="blog-listing-form">

      {/* Error Handling for Blogs */}
      {errorBlogs && (
        <Alert variant="danger" onClose={() => setErrorBlogs(null)} dismissible>
          {errorBlogs}
        </Alert>
      )}

      {/* Error Handling for Posts */}
      {errorPosts && (
        <Alert variant="danger" onClose={() => setErrorPosts(null)} dismissible>
          {errorPosts}
        </Alert>
      )}

      {/* Blog Selection (Single Select) */}
      <Form.Group controlId="selectedBlog" className="mb-3">
        <Form.Label>Select a Blog</Form.Label>
        {loadingBlogs ? (
          <Spinner animation="border" role="status" />
        ) : (
          <Select
            options={blogOptions}
            value={formValues.selectedBlog || null}
            onChange={handleBlogChange}
            placeholder="Select a blog..."
            isClearable
          />
        )}
      </Form.Group>

      {/* Post Selection (Multi Select) */}
      <Form.Group controlId="selectedPosts" className="mb-3">
        <Form.Label>Select Blog Posts (Maximum 12)</Form.Label>
        {loadingPosts ? (
          <Spinner animation="border" role="status" />
        ) : (
          <Select
            isMulti
            options={postOptions}
            value={formValues.selectedPosts || []}
            onChange={handlePostChange}
            placeholder="Select up to 12 blog posts..."
            closeMenuOnSelect={false}
            maxMenuHeight={200}
            isOptionDisabled={() =>
              (formValues.selectedPosts || []).length >= 12
            }
          />
        )}
      </Form.Group>

      {/* Display Selected Posts */}
      {formValues.selectedPosts?.length > 0 && (
        <div className="selected-posts mt-3">
          <h5>Selected Blog Posts:</h5>
          {formValues.selectedPosts.map((post, index) => (
            <InputGroup key={post.value} className="mb-2">
              <Form.Control type="text" readOnly value={post.label} />
              <Button variant="danger" onClick={() => handleRemovePost(index)}>
                <FontAwesomeIcon icon={faTrash} />
              </Button>
            </InputGroup>
          ))}
        </div>
      )}
    </div>
  );
};

export default BlogListingForm;
