import React from 'react';
import { Carousel } from 'react-bootstrap';

const CenterAlignedTemplate = ({ headline, testimonials, styles = {} }) => (
    <div className="testimonial-center-aligned-wrap"  style={styles.container || {}}>
        <Carousel indicators={true} interval={3000}>
            {testimonials.map((testimonial, idx) => (
                <Carousel.Item key={idx} className="testimonial-item-center-aligned text-center">
                    <p style={styles.message || {}}>"{testimonial.message}"</p>
                    <h5 style={styles.name || {}}>{testimonial.name}</h5>
                    <h6 style={styles.position || {}}>{testimonial.position}</h6>
                </Carousel.Item>
            ))}
        </Carousel>
    </div>
);

export default CenterAlignedTemplate;
