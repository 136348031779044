import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { AuthProvider, useAuth } from './pages/component/AuthContext';
import NotFound from './pages/NotFound';
import Home from './pages/Home';
import ProductPage from './pages/ProductPage';
import ProductDetailPage from './pages/ProductDetailPage';

import 'bootstrap/dist/css/bootstrap.min.css';

import './pages/styles/Home.css';
import './pages/styles/ModernCarousel.css';
import './pages/styles/IconCardTemplates.css';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import './pages/styles/AccordionTemplates.css';
import './pages/styles/BlockquoteComponent.css';
import './pages/styles/BlogList.css';
import './pages/styles/ButtonTemplates.css';
import './pages/styles/CountdownTimerComponent.css';
import './pages/styles/FAQTemplates.css';
import './pages/styles/GalleryTemplates.css';
import './pages/styles/HeadingTemplates.css';
import './pages/styles/HorizontalCardTemplates.css';
import './pages/styles/ImageOverlayCardTemplates.css';
import './pages/styles/ImageTemplates.css';
import './pages/styles/ListGroupCardTemplates.css';
import './pages/styles/ModalComponent.css';
import './pages/styles/PricingTableTemplates.css';
import './pages/styles/SocialMediaIconsComponent.css';
import './pages/styles/TabsTemplates.css';
import './pages/styles/TeamSectionComponent.css';
import './pages/styles/TestimonialTemplates.css';
import './pages/styles/TimelineTemplates.css';

import './pages/styles/CustomBreadcrumb.css';
import './pages/styles/DefaultBreadcrumb.css';
import './pages/styles/MinimalBreadcrumb.css';
import './pages/styles/ModernBreadcrumb.css';
import './pages/styles/ClassicCarousel.css';
import './pages/styles/MinimalCarousel.css';
import './pages/styles/SingleColumnContactForm.css';
import './pages/styles/TwoColumnContactForm.css';
import './pages/styles/CarouselTemplate.css';
import './pages/styles/GridTemplate.css';
import './pages/styles/LightboxTemplate.css';
import './pages/styles/MasonryTemplate.css';
import './pages/styles/OverlayTemplate.css';
import './pages/styles/PolaroidTemplate.css';
import './pages/styles/ClassicHeaderFooterCard.css';
import './pages/styles/MinimalHeaderFooterCard.css';
import './pages/styles/ModernHeaderFooterCard.css';
import './pages/styles/ClassicProgress.css';
import './pages/styles/MinimalProgress.css';
import './pages/styles/ModernProgress.css';
import './pages/styles/CardOverlayTeamTemplate.css';
import './pages/styles/ClassicTeamTemplate.css';
import './pages/styles/MinimalGridTeamTemplate.css';
import './pages/styles/ModernTeamTemplate.css';
import './pages/styles/SplitTeamTemplate.css';
import './pages/styles/CenterAlignedTemplates.css';
import './pages/styles/ImageOnTopTemplates.css';
import './pages/styles/SplitScreenTemplates.css';
import './pages/styles/TextOnlyTemplates.css';
import './pages/styles/fontsImports.css';





import { CartProvider } from './pages/component/CartContext';
import { HelmetProvider, Helmet } from 'react-helmet-async'; // Import Helmet
import { BreakpointProvider } from './pages/component/pagebuilder/BreakpointContext';

import Header from './pages/component/Header';
import CartPage from './pages/CartPage';
import BottomNav from './pages/component/BottomNav';
import Footer from './pages/component/Footer';
import SplashScreen from './pages/component/SplashScreen';
import { AppDataProvider, useAppData } from './pages/component/AppDataContext';
import PageBuilder from './pages/PageBuilder';
import PostBuilder from './pages/PostBuilder';

import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import CustomPages from './pages/CustomPages';
import PreviewPages from './pages/PreviewPages';
import Login from './pages/Login';
import Register from './pages/Register';
import Blog from './pages/Blog';
import CustomPosts from './pages/CustomPosts';

// 1. Roboto
import '@fontsource/roboto'; // 400
import '@fontsource/roboto/500.css'; // 500
import '@fontsource/roboto/700.css'; // 700
import '@fontsource/roboto/900.css'; // 900

// 2. Open Sans
import '@fontsource/open-sans'; // 400
import '@fontsource/open-sans/600.css'; // 600
import '@fontsource/open-sans/700.css'; // 700
import '@fontsource/open-sans/800.css'; // 800

// 3. Lato
import '@fontsource/lato'; // 400
import '@fontsource/lato/700.css'; // 700
import '@fontsource/lato/900.css'; // 900

// 4. Montserrat
import '@fontsource/montserrat'; // 400
import '@fontsource/montserrat/600.css'; // 600
import '@fontsource/montserrat/700.css'; // 700
import '@fontsource/montserrat/900.css'; // 900

// 5. Playfair Display
import '@fontsource/playfair-display'; // 400
import '@fontsource/playfair-display/700.css'; // 700
import '@fontsource/playfair-display/900.css'; // 900

// 6. Poppins
import '@fontsource/poppins'; // 400
import '@fontsource/poppins/600.css'; // 600
import '@fontsource/poppins/700.css'; // 700
import '@fontsource/poppins/900.css'; // 900

// 7. Merriweather
import '@fontsource/merriweather'; // 400
import '@fontsource/merriweather/700.css'; // 700
import '@fontsource/merriweather/900.css'; // 900

// 8. Nunito
import '@fontsource/nunito'; // 400
import '@fontsource/nunito/600.css'; // 600
import '@fontsource/nunito/700.css'; // 700
import '@fontsource/nunito/800.css'; // 800

// 9. Raleway
import '@fontsource/raleway'; // 400
import '@fontsource/raleway/600.css'; // 600
import '@fontsource/raleway/700.css'; // 700
import '@fontsource/raleway/900.css'; // 900

// 10. Oswald
import '@fontsource/oswald'; // 400
import '@fontsource/oswald/600.css'; // 600
import '@fontsource/oswald/700.css'; // 700


// New Font Imports with Additional Weights

// 11. Source Sans Pro
import '@fontsource/source-sans-pro'; // 400
import '@fontsource/source-sans-pro/600.css'; // 600
import '@fontsource/source-sans-pro/700.css'; // 700
import '@fontsource/source-sans-pro/900.css'; // 900

// 12. Inter
import '@fontsource/inter'; // 400
import '@fontsource/inter/600.css'; // 600
import '@fontsource/inter/700.css'; // 700
import '@fontsource/inter/900.css'; // 900

// 13. Noto Sans
import '@fontsource/noto-sans'; // 400
import '@fontsource/noto-sans/600.css'; // 600
import '@fontsource/noto-sans/700.css'; // 700
import '@fontsource/noto-sans/900.css'; // 900

// 14. Ubuntu
import '@fontsource/ubuntu'; // 400
import '@fontsource/ubuntu/500.css'; // 500
import '@fontsource/ubuntu/700.css'; // 700

// 15. Fira Sans
import '@fontsource/fira-sans'; // 400
import '@fontsource/fira-sans/600.css'; // 600
import '@fontsource/fira-sans/700.css'; // 700
import '@fontsource/fira-sans/900.css'; // 900

// 16. PT Sans
import '@fontsource/pt-sans'; // 400
import '@fontsource/pt-sans/700.css'; // 700

// 17. Josefin Sans
import '@fontsource/josefin-sans'; // 400
import '@fontsource/josefin-sans/600.css'; // 600
import '@fontsource/josefin-sans/700.css'; // 700

// 18. Hind
import '@fontsource/hind'; // 400
import '@fontsource/hind/600.css'; // 600
import '@fontsource/hind/700.css'; // 700

// 19. Cabin
import '@fontsource/cabin'; // 400
import '@fontsource/cabin/600.css'; // 600
import '@fontsource/cabin/700.css'; // 700

// 20. Libre Baskerville
import '@fontsource/libre-baskerville'; // 400
import '@fontsource/libre-baskerville/700.css'; // 700

// 21. Muli (Now known as Mulish)
import '@fontsource/muli'; // 400

// 22. Rubik
import '@fontsource/rubik'; // 400
import '@fontsource/rubik/500.css'; // 500
import '@fontsource/rubik/700.css'; // 700
import '@fontsource/rubik/900.css'; // 900

// 23. Work Sans
import '@fontsource/work-sans'; // 400
import '@fontsource/work-sans/600.css'; // 600
import '@fontsource/work-sans/700.css'; // 700
import '@fontsource/work-sans/900.css'; // 900

// 24. Ubuntu Mono
import '@fontsource/ubuntu-mono'; // 400
import '@fontsource/ubuntu-mono/700.css'; // 700

// 25. Roboto Slab
import '@fontsource/roboto-slab'; // 400
import '@fontsource/roboto-slab/600.css'; // 600
import '@fontsource/roboto-slab/700.css'; // 700
import '@fontsource/roboto-slab/900.css'; // 900

// 26. Titillium Web
import '@fontsource/titillium-web'; // 400
import '@fontsource/titillium-web/600.css'; // 600
import '@fontsource/titillium-web/700.css'; // 700
import '@fontsource/titillium-web/900.css'; // 900

// 27. Nunito Sans
import '@fontsource/nunito-sans'; // 400
import '@fontsource/nunito-sans/600.css'; // 600
import '@fontsource/nunito-sans/700.css'; // 700
import '@fontsource/nunito-sans/800.css'; // 800

// 28. Exo 2
import '@fontsource/exo-2'; // 400
import '@fontsource/exo-2/600.css'; // 600
import '@fontsource/exo-2/700.css'; // 700
import '@fontsource/exo-2/900.css'; // 900

// 29. Maven Pro
import '@fontsource/maven-pro'; // 400
import '@fontsource/maven-pro/600.css'; // 600
import '@fontsource/maven-pro/700.css'; // 700
import '@fontsource/maven-pro/900.css'; // 900

// 30. Space Grotesk
import '@fontsource/space-grotesk'; // 400
import '@fontsource/space-grotesk/600.css'; // 600
import '@fontsource/space-grotesk/700.css'; // 700

import '@fontsource/sora'; // Defaults to weight 400
import '@fontsource/sora/600.css'; // Weight 600
import '@fontsource/sora/700.css'; // Weight 700

const PublicRoute = ({ element }) => {
  const { isAuthenticated } = useAuth();
  return isAuthenticated && element.type.name !== 'LoginAsCustomer' ? <Navigate to="/account" replace /> : element;
};

const PrivateRoute = ({ element }) => {
  const { isAuthenticated } = useAuth();
  return isAuthenticated ? element : <Navigate to="/login" replace />;
};

const AppContent = () => {
  const { isDataLoading, customStyles } = useAppData(); // Retrieve customStyles from context
  const [showBackdrop, setShowBackdrop] = useState(false);
  const location = useLocation();

  // Function to toggle the backdrop visibility
  const toggleBackdrop = (isVisible) => {
    setShowBackdrop(isVisible);
  };

  if (isDataLoading) {
    return <SplashScreen />;
  }

  return (
    <>
      {customStyles && (
        <Helmet>
          <style type="text/css">{customStyles}</style>
        </Helmet>
      )}
      <div>
        <div className="app-container">
          {!['/page-builder','/post-builder', '/preview'].some((path) => location.pathname.includes(path)) && (
            <div className="header">
              <Header toggleBackdrop={toggleBackdrop} />
            </div>
          )}

          <div className="content">
            <div className="app">
              <Routes>
                <Route path="/" element={<PublicRoute element={<Home />} />} />
                <Route path="/products" element={<PublicRoute element={<ProductPage />} />} />
                <Route path="/products/:productId" element={<PublicRoute element={<ProductDetailPage />} />} />
                <Route path="/cart" element={<PublicRoute element={<CartPage />} />} />
                <Route path="/page-builder/:pageId" element={<PublicRoute element={<PageBuilder />} />} />
                <Route path="/post-builder/:postId" element={<PublicRoute element={<PostBuilder />} />} />
                <Route path="/blog/:blogId" element={<PublicRoute element={<Blog />} />} />

                <Route path="/login" element={<Login />} />
                <Route path="/register" element={<Register />} />
                <Route path="/preview/:slug/:pageId" element={<PublicRoute element={<PreviewPages />} />} />
                <Route path="/:pageId" element={<PublicRoute element={<CustomPages />} />} />
                <Route path="/blog/post/:postId" element={<PublicRoute element={<CustomPosts />} />} />

                <Route path="*" element={<NotFound />} />
              </Routes>
            </div>
          </div>
          <div className="footer">
            <div className={`bghide ${showBackdrop ? 'bgtransparent' : ''}`}></div>
            <BottomNav />
          </div>
        </div>
         {!['/page-builder', '/post-builder', '/preview'].some((path) => location.pathname.includes(path)) && (
            <Footer />
          
          )}
        </div>      
    </>
  );
};

const App = () => {
  return (
    <HelmetProvider>
      <BreakpointProvider>
        <Router>
          <DndProvider backend={HTML5Backend}>
            <AppDataProvider>
              <CartProvider>
                <AuthProvider>
                  <AppContent />
                </AuthProvider>
              </CartProvider>
            </AppDataProvider>
          </DndProvider>
        </Router>
      </BreakpointProvider>
    </HelmetProvider>
  );
};

export default App;
