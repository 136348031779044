import React from 'react';
import { useResponsiveStyles } from './useResponsiveStyles';


function AlertComponent({ type = 'primary', message = 'Default Alert Message', styles = {} }) {

    const appliedStyles = useResponsiveStyles(styles);

    return (
        <div 
            className={`alert alert-${type}`} 
            role="alert" 
            style={appliedStyles.alertStyle}
        >
            {message}
        </div>
    );
}

export default AlertComponent;
