import React from 'react';
import ClassicProgress from './templates/progress/ClassicProgress';
import ModernProgress from './templates/progress/ModernProgress';
import MinimalProgress from './templates/progress/MinimalProgress';
import { useResponsiveStyles } from './useResponsiveStyles';


function ProgressComponent({ items, template,  styles = {}  }) {
    const appliedStyles = useResponsiveStyles(styles);

    switch (template) {
        case 'Modern':
            return <ModernProgress items={items} styles={appliedStyles} />;
        case 'Minimal':
            return <MinimalProgress items={items} styles={appliedStyles} />;
        case 'Classic':
        default:
            return <ClassicProgress items={items} styles={appliedStyles} />;
    }
}

export default ProgressComponent;
