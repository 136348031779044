import { API_URL } from '../../config';

export const fetchPageData = async (pageId) => {
    const response = await fetch(`${API_URL}/pages-builder/by-id/${pageId}`);
    const data = await response.json();
    return data;
};

/**
 * Build the payload for saving page data.
 * @param {string} pageId - The ID of the page.
 * @param {Array} rows - The rows of the page.
 * @returns {Object} - The constructed page data payload.
 */
const buildPageData = (pageId, rows) => ({
    title: 'My New Page',
    pageId,
    rows: rows.map(row => ({
        disabled: row.disabled,
        styles: row.styles,
        sections: row.sections.map(section => ({
            disabled: section.disabled,
            layoutKey: section.columns.map(col => col.class).join('-'),
            columns: section.columns.map(col => ({
                class: col.class,
                disabled: col.disabled,
                styles: col.styles,
                components: col.components.map(component => ({
                    type: component.type,
                    props: component.props,
                    disabled: component.disabled,
                })),
                subsections: col.sections?.map(nestedSection => ({
                    layoutKey: nestedSection.columns.map(nestedCol => nestedCol.class).join('-'),
                    disabled: nestedSection.disabled,
                    subcolumns: nestedSection.columns.map(nestedCol => ({
                        class: nestedCol.class,
                        disabled: nestedCol.disabled,
                        components: nestedCol.components.map(nestedComponent => ({
                            type: nestedComponent.type,
                            props: nestedComponent.props,
                            disabled: nestedComponent.disabled,
                        })),
                    })),
                })) || [],
            })),
        })),
    })),
});

/**
 * Save page data to the server.
 * @param {string} pageId - The page ID.
 * @param {Array} rows - The rows of the page.
 * @returns {Promise} - A promise that resolves with the server response.
 */
export const savePageData = async (pageId, rows) => {
    const pageData = buildPageData(pageId, rows);

    try {
        const response = await fetch(`${API_URL}/pages-builder`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(pageData),
        });

        if (!response.ok) {
            throw new Error('Failed to save page data');
        }

        return await response.json();
    } catch (error) {
        console.error('Error saving page data:', error);
        throw error;
    }
};




export const fetchPostData = async (postId, subdomain) => {
    const response = await fetch(`${API_URL}/post-builder/by-id/${postId}?account_key=${subdomain}`);
    const data = await response.json();
    return data;
};

/**
 * Build the payload for saving page data.
 * @param {string} pageId - The ID of the page.
 * @param {Array} rows - The rows of the page.
 * @returns {Object} - The constructed page data payload.
 */
const buildPostData = (postId, rows) => ({
    title: 'New Post',
    postId,
    rows: rows.map(row => ({
        disabled: row.disabled,
        styles: row.styles,
        sections: row.sections.map(section => ({
            disabled: section.disabled,
            layoutKey: section.columns.map(col => col.class).join('-'),
            columns: section.columns.map(col => ({
                class: col.class,
                disabled: col.disabled,
                components: col.components.map(component => ({
                    type: component.type,
                    props: component.props,
                    disabled: component.disabled,
                })),
                subsections: col.sections?.map(nestedSection => ({
                    layoutKey: nestedSection.columns.map(nestedCol => nestedCol.class).join('-'),
                    disabled: nestedSection.disabled,
                    subcolumns: nestedSection.columns.map(nestedCol => ({
                        class: nestedCol.class,
                        disabled: nestedCol.disabled,
                        components: nestedCol.components.map(nestedComponent => ({
                            type: nestedComponent.type,
                            props: nestedComponent.props,
                            disabled: nestedComponent.disabled,
                        })),
                    })),
                })) || [],
            })),
        })),
    })),
});

/**
 * Save page data to the server.
 * @param {string} pageId - The page ID.
 * @param {Array} rows - The rows of the page.
 * @returns {Promise} - A promise that resolves with the server response.
 */
export const savePostData = async (postId, rows) => {
    const postData = buildPostData(postId, rows);

    try {
        const response = await fetch(`${API_URL}/post-builder`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(postData),
        });

        if (!response.ok) {
            throw new Error('Failed to save post data');
        }

        return await response.json();
    } catch (error) {
        console.error('Error saving post data:', error);
        throw error;
    }
};