// src/components/Sidebar.js

import React from 'react';
import DraggableComponent from './DraggableComponent'; // Adjust the path
import { exampleProps } from '../config/exampleProps'; // Adjust the path
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFont,
  faImage,
  faHeading,
  faQuoteRight,
  faMagic,
  faVideo,
  faMusic,
  faTh,
  faFolder,
  faLayerGroup,
  faWindowRestore,
  faClock,
  faStream,
  faSpinner,
  faImages,
  faArrowsAltV,
  faMinus,
  faTag,
  faExclamationTriangle,
  faAddressCard,
  faArrowsUpDownLeftRight,
  faList,
  faStar,
  faDollarSign,
  faMapMarkerAlt,
  faQuestionCircle,
  faShareAlt,
  faUsers,
  faBars,
  faFeather,
  faShoePrints,
  faEnvelope, // Import the envelope icon for Contact Form
} from '@fortawesome/free-solid-svg-icons';

import Accordion from 'react-bootstrap/Accordion'; // Ensure you have react-bootstrap installed

const Sidebar = ({ openModal }) => {
  const componentCategories = [
    {
      title: 'Text Widgets',
      components: [
        { type: 'heading', props: exampleProps.heading, icon: faHeading, label: 'Heading' },
        { type: 'text', props: exampleProps.text, icon: faFont, label: 'Text' },
        { type: 'richText', props: exampleProps.richText, icon: faFont, label: 'Rich Text' },
        { type: 'blockquote', props: exampleProps.blockquote, icon: faQuoteRight, label: 'Blockquote' },
        { type: 'button', props: exampleProps.button, icon: faMagic, label: 'Button' },
      ],
    },
    {
      title: 'Media Widgets',
      components: [
        { type: 'image', props: exampleProps.image, icon: faImage, label: 'Image' },
        { type: 'video', props: exampleProps.video, icon: faVideo, label: 'Video' },
        { type: 'gallery', props: exampleProps.gallery, icon: faTh, label: 'Gallery' },
      ],
    },
    {
      title: 'Interactive Widgets',
      components: [
        { type: 'tabs', props: exampleProps.tabs, icon: faFolder, label: 'Tabs' },
        { type: 'accordion', props: exampleProps.accordion, icon: faLayerGroup, label: 'Accordion' },
        //{ type: 'countdownTimer', props: exampleProps.countdownTimer, icon: faClock, label: 'Countdown Timer' },
        //{ type: 'timeline', props: exampleProps.timeline, icon: faStream, label: 'Timeline' },
        { type: 'progress', props: exampleProps.progress, icon: faSpinner, label: 'Progress' },
        { type: 'carousel', props: exampleProps.carousel, icon: faImages, label: 'Carousel' },
      ],
    },
    {
      title: 'Layout Widgets',
      components: [
        { type: 'spacer', props: exampleProps.spacer, icon: faArrowsAltV, label: 'Spacer' },
        { type: 'divider', props: exampleProps.divider, icon: faMinus, label: 'Divider' },
        { type: 'badge', props: exampleProps.badge, icon: faTag, label: 'Badge' },
        { type: 'alert', props: exampleProps.alert, icon: faExclamationTriangle, label: 'Alert' },
        { type: 'breadcrumb', props: exampleProps.breadcrumb, icon: faStream, label: 'Breadcrumb' },
      ],
    },
    {
      title: 'Cards and Display Widgets',
      components: [
        { type: 'imageOverlayCard', props: exampleProps.imageOverlayCard, icon: faImage, label: 'Image Overlay Card' },
        { type: 'headerFooterCard', props: exampleProps.headerFooterCard, icon: faAddressCard, label: 'Header Footer Card' },
        { type: 'horizontalCard', props: exampleProps.horizontalCard, icon: faArrowsUpDownLeftRight, label: 'Horizontal Card' },
        { type: 'listGroupCard', props: exampleProps.listGroupCard, icon: faList, label: 'List Group Card' },
        { type: 'iconCard', props: exampleProps.iconCard, icon: faStar, label: 'Icon Card' },
        { type: 'iconComponent', props: exampleProps.iconCard, icon: faStar, label: 'Icon Only' },
        { type: 'testimonialComponent', props: exampleProps.testimonial, icon: faQuoteRight, label: 'Testimonial' },
      ],
    },
    {
      title: 'Business Widgets',
      components: [
        //{ type: 'categoryList', props: exampleProps.categoryList, icon: faList, label: 'Category List' },
        //{ type: 'productList', props: exampleProps.productList, icon: faTh, label: 'Product List' },
        { type: 'blogList', props: exampleProps.blogListing, icon: faTh, label: ' Blog Posts' },
        { type: 'pricingTable', props: exampleProps.pricingTable, icon: faDollarSign, label: 'Pricing Table' },
        { type: 'map', props: exampleProps.map, icon: faMapMarkerAlt, label: 'Map' },
        { type: 'faq', props: exampleProps.faq, icon: faQuestionCircle, label: 'FAQ' },
        { type: 'socialMediaIcons', props: exampleProps.socialMediaIcons, icon: faShareAlt, label: 'Social Media Icons' },
        //{ type: 'featureCards', props: exampleProps.featureCards, icon: faStar, label: 'Feature Cards' },
        { type: 'teamSection', props: exampleProps.teamSection, icon: faUsers, label: 'Team Section' },
      ],
    },
    {
      title: 'Header Footer Widgets',
      components: [
        { type: 'menus', props: exampleProps.menus, icon: faTh, label: ' Menus' },
      ],
    },
    {
      title: 'Form Widgets',
      components: [
        { type: 'contactForm', props: exampleProps.contactForm, icon: faEnvelope, label: 'Contact Form' },
        // Add more form types here in the future
      ],
    },
  ];

  return (
    <div className="components-list">
      <Accordion defaultActiveKey="0" flush>
        {componentCategories.map((category, catIdx) => (
          <Accordion.Item eventKey={String(catIdx)} key={catIdx}>
            <Accordion.Header>{category.title}</Accordion.Header>
            <Accordion.Body>
              <div className="dragable-main">
                {category.components.map((component, cIdx) => (
                  <DraggableComponent key={cIdx} type={component.type} props={component.props}>
                    <FontAwesomeIcon icon={component.icon} /> {component.label}
                  </DraggableComponent>
                ))}
              </div>
            </Accordion.Body>
          </Accordion.Item>
        ))}
      </Accordion>
    </div>
  );
};

export default Sidebar;
