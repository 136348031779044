// ColorPicker.jsx
import React from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPalette } from '@fortawesome/free-solid-svg-icons';

const ColorPicker = ({ field, color, onOpen }) => {
    return (
        <Button
            variant="outline-secondary"
            onClick={() => onOpen(field)}
            style={{
                backgroundColor: color || '#000000',
                borderColor: '#ced4da',
                width: '100%',
                height: '38px',
                color: '#fff',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            {color ? color : 'Choose Color'}
            <FontAwesomeIcon icon={faPalette} className="ms-2" />
        </Button>
    );
};

export default ColorPicker;
