import React from 'react';
import { useResponsiveStyles } from './useResponsiveStyles';


const ImageOverlayCard = ({ title, imageUrl, bodyText, styles = {}, template = "Default" }) => {

    const appliedStyles = useResponsiveStyles(styles);

    const { 
        title: titleStyles = {}, 
        bodyText: bodyTextStyles = {}, 
        image: imageStyles = {}, 
        button: buttonStyles = {} 
    } = appliedStyles;

    switch (template) {
        case "CenterAlignedOverlay":
            return (
                <div className="card bg-dark text-white center-aligned-overlay">
                    <img src={imageUrl} className="card-img" alt="Overlay" style={imageStyles} />
                    <div className="card-img-overlay d-flex flex-column justify-content-center align-items-center text-center">
                        <h5 className="card-title" style={titleStyles}>{title}</h5>
                        <p className="card-text" style={bodyTextStyles}>{bodyText}</p>
                        <a href="#" className="btn btn-primary" style={buttonStyles}>Go somewhere</a>
                    </div>
                </div>
            );

        case "BottomOverlay":
            return (
                <div className="card bg-dark text-white bottom-overlay">
                    <img src={imageUrl} className="card-img" alt="Overlay" style={imageStyles} />
                    <div className="card-img-overlay d-flex flex-column justify-content-end">
                        <h5 className="card-title" style={titleStyles}>{title}</h5>
                        <p className="card-text" style={bodyTextStyles}>{bodyText}</p>
                        <a href="#" className="btn btn-primary" style={buttonStyles}>Discover</a>
                    </div>
                </div>
            );

        case "GradientOverlay":
            return (
                <div className="card gradient-overlay">
                    <img src={imageUrl} className="card-img" alt="Overlay" style={imageStyles} />
                    <div className="card-img-overlay text-white">
                        <h5 className="card-title" style={titleStyles}>{title}</h5>
                        <p className="card-text" style={bodyTextStyles}>{bodyText}</p>
                        <a href="#" className="btn btn-primary" style={buttonStyles}>Learn More</a>
                    </div>
                </div>
            );

        case "TransparentOverlay":
            return (
                <div className="card transparent-overlay">
                    <img src={imageUrl} className="card-img" alt="Overlay" style={imageStyles} />
                    <div className="card-img-overlay text-dark" style={{ background: 'rgba(255, 255, 255, 0.7)' }}>
                        <h5 className="card-title" style={titleStyles}>{title}</h5>
                        <p className="card-text" style={bodyTextStyles}>{bodyText}</p>
                        <a href="#" className="btn btn-outline-primary" style={buttonStyles}>Explore</a>
                    </div>
                </div>
            );

        default: // Default template
            return (
                <div className="card bg-dark text-white default-overlay">
                    <img src={imageUrl} className="card-img" alt="Overlay" style={imageStyles} />
                    <div className="card-img-overlay">
                        <h5 className="card-title" style={titleStyles}>{title}</h5>
                        <p className="card-text" style={bodyTextStyles}>{bodyText}</p>
                        <a href="#" className="btn btn-primary" style={buttonStyles}>Go somewhere</a>
                    </div>
                </div>
            );
    }
};

export default ImageOverlayCard;
