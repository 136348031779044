import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faTwitter, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons';

const ModernTeamTemplate = ({ headline, description, teamMembers, styles = {} }) => (
    <div className="team-section-modern" style={styles.container}>
        {teamMembers.map((member, idx) => (
            // Add a unique key prop here
            <div
                key={member.id || idx} // Preferably use a unique ID, fallback to index
                className="team-member-modern text-center"
            >
                <div
                    className="image-circle mx-auto mb-3"
                    style={{
                        backgroundImage: `url(${member.image})`,
                        ...styles.image,
                        height: '140px',
                        width: '140px',
                        borderRadius: '50%',
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                    }}
                ></div>
                <h5 style={styles.name}>{member.name}</h5>
                <p style={styles.position}>{member.position}</p>
                <div className="social-media-icons d-flex justify-content-center">
                    {member.facebook && (
                        <a href={member.facebook} className="icon-link" style={styles.icon} target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon icon={faFacebook} />
                        </a>
                    )}
                    {member.twitter && (
                        <a href={member.twitter} className="icon-link" style={styles.icon} target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon icon={faTwitter} />
                        </a>
                    )}
                    {member.instagram && (
                        <a href={member.instagram} className="icon-link" style={styles.icon} target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon icon={faInstagram} />
                        </a>
                    )}
                    {member.linkedin && (
                        <a href={member.linkedin} className="icon-link" style={styles.icon} target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon icon={faLinkedin} />
                        </a>
                    )}
                </div>
            </div>
        ))}
    </div>
);

export default ModernTeamTemplate;
