import React, { useReducer, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Loading from './component/Loading'; // LoadingOverlay component
import { API_URL } from '../config';
import { componentTypes } from './config/componentTypes';
import { safeParseJSON } from './utils/safeParseJSON';
import { useAppData } from './component/AppDataContext';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import { Helmet } from 'react-helmet-async';
import { useResponsiveStyles } from './component/pagebuilder/useResponsiveStyles';

const initialState = { rows: [] };

function reducer(state, action) {
    switch (action.type) {
        case 'LOAD_PAGE_DATA':
            return {
                ...state,
                rows: action.payload.page_builder_rows.map(row => ({
                    id: row.id,
                    disabled: row.disabled,
                    styles: safeParseJSON(row.styles, {}), // Safely parse styles

                    sections: row.page_builder_sections.map(section => ({
                        id: section.id,
                        disabled: section.disabled,
                        styles: safeParseJSON(section.styles, {}), // Safely parse section styles
                        layout_key: section.layout_key,

                        columns: section.page_builder_columns.map(column => ({
                            class: column.class,
                            styles: safeParseJSON(column.styles, {}),
                            components: column.page_builder_components.map(component => ({
                                type: component.type,
                                props: safeParseJSON(component.props, {}), // Safely parse component props
                            })),

                            subsections: column.page_builder_subsections.map(subsection => ({
                                id: subsection.id,
                                disabled: subsection.disabled,
                                layout_key: subsection.layout_key,

                                subcolumns: subsection.page_builder_subcolumns.map(subcolumn => ({
                                    class: subcolumn.class,
                                    styles: safeParseJSON(subcolumn.styles, {}),
                                    subcomponents: subcolumn.page_builder_subcomponents.map(subcomponent => ({
                                        type: subcomponent.type,
                                        props: safeParseJSON(subcomponent.props, {}), // Safely parse subcomponent props
                                    })),
                                })),
                            })),
                        })),
                    })),
                })),
            };
        default:
            return state;
    }
}

const fetchPageData = async (pageId, subdomain) => {
    try {
        const response = await fetch(`${API_URL}/pages-builder/${pageId}?account_key=${subdomain}`);
        if (!response.ok) {
            throw new Error(`Error fetching data: ${response.statusText}`);
        }
        const data = await response.json();
        console.log(data);
        return data;
    } catch (error) {
        console.error('Fetch error:', error);
        throw error;
    }
};

// Helper function to check if a row has any non-full-screen components
const rowHasNonFullScreenComponents = (row) => {
    // Iterate through sections
    for (const section of row.sections) {
        if (section.disabled === 1) continue;

        // Iterate through columns
        for (const column of section.columns) {
            // Check top-level components
            for (const comp of column.components) {
                if (!(comp.type === 'carousel' && comp.props.isFullScreen)) {
                    return true;
                }
            }

            // Iterate through subsections
            for (const subsection of column.subsections) {
                if (subsection.disabled === 1) continue;

                for (const subcolumn of subsection.subcolumns) {
                    for (const subcomp of subcolumn.subcomponents) {
                        if (!(subcomp.type === 'carousel' && subcomp.props.isFullScreen)) {
                            return true;
                        }
                    }
                }
            }
        }
    }

    return false;
};

function PageBuilder({ pageId: propPageId }) {
    const { pageId: paramPageId } = useParams();
    const pageId = propPageId || paramPageId;
    const [state, dispatch] = useReducer(reducer, initialState);
    const [isLoading, setIsLoading] = useState(true);
    const { subdomain } = useAppData();
    const [currentPage, setCurrentPage] = useState(null);
    const navigate = useNavigate(); // Initialize useNavigate

    useEffect(() => {
        if (pageId) {
            setIsLoading(true);
            fetchPageData(pageId, subdomain)
                .then(pageData => {
                    setCurrentPage(pageData);
                    dispatch({ type: 'LOAD_PAGE_DATA', payload: pageData });
                    setIsLoading(false);
                })
                .catch(error => {
                    console.error('Error loading page data:', error);
                    setIsLoading(false);
                });
        }
    }, [pageId, subdomain]);

    // Identify full-screen carousels
    const fullScreenCarousels = state.rows.flatMap(row =>
        row.sections.flatMap(section =>
            section.columns.flatMap(column =>
                column.components.filter(
                    comp => comp.type === 'carousel' && comp.props.isFullScreen
                )
            )
        )
    );

    // Filter out rows that do not have any non-full-screen components
    const filteredRows = state.rows.filter(row => rowHasNonFullScreenComponents(row));

    // Extract SEO fields from currentPage
    const seo = currentPage ? {
        meta_title: currentPage.meta_title || currentPage.title,
        meta_description: currentPage.meta_description || '',
        meta_keywords: currentPage.meta_keywords || '',
        canonical_url: currentPage.canonical_url || window.location.href,
        og_title: currentPage.og_title || currentPage.meta_title || currentPage.title,
        og_description: currentPage.og_description || currentPage.meta_description || '',
        og_image: currentPage.og_image || '',
        twitter_title: currentPage.twitter_title || currentPage.og_title || currentPage.meta_title || currentPage.title,
        twitter_description: currentPage.twitter_description || currentPage.og_description || currentPage.meta_description || '',
        twitter_image: currentPage.twitter_image || currentPage.og_image || '',
    } : {};

    return (
        <div
            id={`custom-pages-${pageId}`}
            className={`custom-pages-${pageId}`}
        >
            {currentPage && (
                <Helmet>
                    <title>{seo.meta_title}</title>
                    <meta name="description" content={seo.meta_description} />
                    <meta name="keywords" content={seo.meta_keywords} />
                    <link rel="canonical" href={seo.canonical_url} />

                    {/* Open Graph Tags */}
                    {seo.og_title && <meta property="og:title" content={seo.og_title} />}
                    {seo.og_description && <meta property="og:description" content={seo.og_description} />}
                    {seo.og_image && <meta property="og:image" content={seo.og_image} />}

                    {/* Twitter Card Tags */}
                    {seo.twitter_title && <meta name="twitter:title" content={seo.twitter_title} />}
                    {seo.twitter_description && <meta name="twitter:description" content={seo.twitter_description} />}
                    {seo.twitter_image && <meta name="twitter:image" content={seo.twitter_image} />}
                </Helmet>
            )}
            {isLoading && <Loading />}

            {/* Render full-screen carousels separately */}
            {fullScreenCarousels.map((carouselComp, idx) => (
                <div key={`fullscreen-carousel-${idx}`} className="carousel-full-screen">
                    {React.createElement(componentTypes[carouselComp.type], {
                        ...carouselComp.props,
                    })}
                </div>
            ))}

            {/* Render filtered rows */}
            {filteredRows.map(row => (
                <Row key={row.id} row={row} />
            ))}
        </div>
    );
}

// Nested Row Component within the same file
function Row({ row }) {
    const appliedStyles = useResponsiveStyles(row.styles || {});

    return (
        <div className="row-wrap" style={appliedStyles}>
            {row.sections.map(section => (
                <Section key={section.id} section={section} />
            ))}
        </div>
    );
}

// Nested Section Component within the same file
function Section({ section }) {
    const appliedStyles = useResponsiveStyles(section.styles || {});

    return (
        <div className="section-wrap" style={appliedStyles}>
            <div className="container">
                <div className="row">
                    {section.columns.map((column, index) => (
                        <Column key={index} column={column} />
                    ))}
                </div>
            </div>
        </div>
    );
}

// Nested Column Component within the same file
function Column({ column }) {
    const appliedStyles = useResponsiveStyles(column.styles || {});

    return (
        <div className={`${column.class} p-3-custom`} style={appliedStyles}>
            {column.subsections.map(subsection => (
                <Subsection key={subsection.id} subsection={subsection} />
            ))}

            {column.components.map((comp, index) => {
                if (comp.type === 'carousel' && comp.props.isFullScreen) {
                    return null; // Skip full-screen carousels here
                }
                return (
                    <ComponentRenderer key={index} type={comp.type} props={comp.props} />
                );
            })}
        </div>
    );
}

// Nested Subsection Component within the same file
function Subsection({ subsection }) {
    if (subsection.disabled === 1) return null;

    return (
        <div className="row">
            {subsection.subcolumns.map(subcolumn => (
                <Subcolumn key={subcolumn.id} subcolumn={subcolumn} />
            ))}
        </div>
    );
}

// Nested Subcolumn Component within the same file
function Subcolumn({ subcolumn }) {
    const appliedStyles = useResponsiveStyles(subcolumn.styles || {});

    return (
        <div className={`${subcolumn.class} p-3-custom`} style={appliedStyles}>
            {subcolumn.subcomponents.map((subcomp, index) => (
                <ComponentRenderer key={index} type={subcomp.type} props={subcomp.props} />
            ))}
        </div>
    );
}

// ComponentRenderer to dynamically render components based on type
function ComponentRenderer({ type, props }) {
    const Component = componentTypes[type];
    if (!Component) {
        console.error(`Undefined component type: ${type}`);
        return null;
    }
    return <Component {...props} />;
}

export default PageBuilder;
