import React from 'react';
import { Form } from 'react-bootstrap';

const BlockquoteForm = ({ formValues, setFormValues }) => (
    <div className="carousel-wrap pb-3">

        <Form.Group controlId="formBlockquoteQuote" className="mb-3">
            <Form.Label>Quote</Form.Label>
            <Form.Control
                type="text"
                name="quote"
                value={formValues.quote || ''}
                onChange={(e) => setFormValues({ ...formValues, quote: e.target.value })}
            />
        </Form.Group>
        <Form.Group controlId="formBlockquoteAuthor">
            <Form.Label>Author</Form.Label>
            <Form.Control
                type="text"
                name="author"
                value={formValues.author || ''}
                onChange={(e) => setFormValues({ ...formValues, author: e.target.value })}
            />
        </Form.Group>
    </div>
);

export default BlockquoteForm;
