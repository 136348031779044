import React from 'react';
import ClassicTemplate from './templates/testimonial/ClassicTemplate';
import ModernTemplate from './templates/testimonial/ModernTemplate';
import ImageOnTopTemplate from './templates/testimonial/ImageOnTopTemplate';
import SplitScreenTemplate from './templates/testimonial/SplitScreenTemplate';
import TextOnlyTemplate from './templates/testimonial/TextOnlyTemplate';
import CenterAlignedTemplate from './templates/testimonial/CenterAlignedTemplate';
import { useResponsiveStyles } from './useResponsiveStyles';

const TestimonialComponent = ({ template = "Classic", styles = {}, ...otherProps }) => {
    const appliedStyles = useResponsiveStyles(styles);

    switch (template) {
        case "Modern":
            return <ModernTemplate {...otherProps} styles={appliedStyles} />;
        case "ImageOnTop":
            return <ImageOnTopTemplate {...otherProps} styles={appliedStyles} />;
        case "SplitScreen":
            return <SplitScreenTemplate {...otherProps} styles={appliedStyles} />;
        case "TextOnly":
            return <TextOnlyTemplate {...otherProps} styles={appliedStyles} />;
        case "CenterAligned":
            return <CenterAlignedTemplate {...otherProps} styles={appliedStyles} />;
        default:
            return <ClassicTemplate {...otherProps} styles={appliedStyles} />;
    }
};

export default TestimonialComponent;
