import React, { useEffect, useState } from 'react';
import { Spinner, Alert, Card, Button, Row, Col } from 'react-bootstrap';
import Slider from 'react-slick';
import { API_URL } from '../../../config';
import { useAppData } from '../AppDataContext';
import { useResponsiveStyles } from './useResponsiveStyles'; // Import the hook

const BlogList = ({ header, selectedPosts = [], template = "Slider", styles = {} }) => {
    const [posts, setPosts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { subdomain } = useAppData();

    // Apply responsive styles
    const appliedStyles = useResponsiveStyles(styles);

    // Destructure styles for different parts
    const containerStyles = appliedStyles?.container || {};
    const cardStyles = appliedStyles?.card || {};
    const titleStyles = appliedStyles?.title || {};
    const contentStyles = appliedStyles?.content || {};
    const buttonStyles = appliedStyles?.button || {};
    const imageStyles = appliedStyles?.image || {};

    useEffect(() => {
        const fetchPosts = async () => {
            try {
                setLoading(true);

                // If selectedPosts is an array of { label, value }, collect IDs:
                const postIds = selectedPosts.map((p) => p.value);

                if (postIds.length === 0) {
                    setPosts([]);
                    setLoading(false);
                    return;
                }

                const response = await fetch(
                    `${API_URL}/blog-posts?account_key=${subdomain}&post_ids=${postIds.join(',')}`
                );
                const data = await response.json();

                if (!response.ok || !data.success) {
                    throw new Error(data.message || 'Failed to fetch blog posts.');
                }

                setPosts(data.posts);
            } catch (err) {
                console.error(err);
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchPosts();
    }, [selectedPosts, subdomain]);

    if (loading) {
        return (
            <div className="text-center my-5" style={containerStyles}>
                <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            </div>
        );
    }

    if (error) {
        return (
            <Alert variant="danger" className="text-center my-5" style={containerStyles}>
                {error}
            </Alert>
        );
    }

    if (posts.length === 0) {
        return (
            <div className="text-center my-5" style={containerStyles}>
                <p>No blog posts found.</p>
            </div>
        );
    }

    // Dynamically set how many slides to show
    const sliderSettings = {
        dots: posts.length > 1,
        arrows: posts.length > 1,
        infinite: posts.length > 3,
        speed: 500,
        slidesToShow: Math.min(posts.length, 3),
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: Math.min(posts.length, 2),
                },
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    };

    return (
        <div className="blog-component" style={containerStyles}>

            

            {template === 'Slider' ? (
                <Slider {...sliderSettings}>
                    {posts.map((post) => (
                        <div key={post.id}>
                            <Card className="h-100" style={cardStyles}>
                                <div className="blog-img-top">
                                    <Card.Img
                                        src={post.featured_image_url || 'https://via.placeholder.com/600x400'}
                                        alt={post.title}
                                        style={imageStyles}
                                    />
                                </div>
                                <Card.Body>
                                    <Card.Title style={titleStyles}>{post.title}</Card.Title>
                                    <Card.Text style={contentStyles}>
                                        {post.excerpt ||
                                            (post.content && post.content.length > 100
                                                ? `${post.content.slice(0, 100)}...`
                                                : post.content)}
                                    </Card.Text>
                                    <Button
                                        variant="primary"
                                        href={`/blog/post/${post.slug}`}
                                        style={buttonStyles}
                                    >
                                        Read More
                                    </Button>
                                </Card.Body>
                            </Card>
                        </div>
                    ))}
                </Slider>
            ) : template === 'Grid' ? (
                <Row xs={1} md={3} className="g-4 grid-view-blog">
                    {posts.map((post) => (
                        <Col key={post.id}>
                            <Card className="h-100" style={cardStyles}>
                                <div className="blog-img-top">
                                    <Card.Img
                                        src={post.featured_image_url || 'https://via.placeholder.com/600x400'}
                                        alt={post.title}
                                        style={imageStyles}
                                    />
                                </div>
                                <Card.Body>
                                    <Card.Title style={titleStyles}>{post.title}</Card.Title>
                                    <Card.Text style={contentStyles}>
                                        {post.excerpt ||
                                        (post.content && post.content.length > 100
                                        ? `${post.content.slice(0, 100)}...`
                                        : post.content)}
                                    </Card.Text>
                                    <Button
                                        variant="primary"
                                        href={`/blog/post/${post.slug}`}
                                        style={buttonStyles}
                                    >
                                        Read More
                                    </Button>
                                </Card.Body>
                            </Card>
                        </Col>
                    ))}
                </Row>
            ) : (
                // "List" or Default
                <div className="list-group">
                    {posts.map((post) => (
                        <div className="list-group-item list-group-item-action">
                            <div className="d-flex w-100 align-items-center blog-img-left">
                                {post.featured_image_url && (
                                    <img
                                        src={post.featured_image_url}
                                        alt={post.title}
                                        style={{ width: '100px', height: '100px', objectFit: 'cover', ...imageStyles }}
                                        className="me-3"
                                    />
                                )}
                                <div>
                                    <h5 className="mb-1" style={titleStyles}>{post.title}</h5>
                                    <p className="mb-1" style={contentStyles}>
                                        {post.excerpt ||
                                        (post.content && post.content.length > 150
                                        ? `${post.content.slice(0, 150)}...`
                                        : post.content)}
                                    </p>
                                    <Button
                                        variant="primary"
                                        href={`/blog/post/${post.slug}`}
                                        style={buttonStyles}
                                    >
                                        Read More
                                    </Button>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default BlogList;
