import React from 'react';

const ModernBreadcrumb = ({ items, separator = '/', styles = {} }) => (
    <nav className="breadcrumb-container modern-breadcrumb" style={styles.container}>
        {items.map((item, index) => (
            <span key={index} className="breadcrumb-item" style={styles.item}>
                <a href={item.link} style={styles.link}>
                    <i className={`breadcrumb-icon ${item.icon}`} style={styles.icon}></i> {item.text}
                </a>
                {index < items.length - 1 && (
                    <span className="breadcrumb-separator" style={styles.separator}>            
                    </span>
                )}
            </span>
        ))}
    </nav>
);
export default ModernBreadcrumb;