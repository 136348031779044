// PricingTableForm.js
import React from 'react';
import { Form, Button, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';

const PricingTableForm = ({
    formValues,
    setFormValues,
    handleAddJsonArrayItem,
    handleRemoveJsonArrayItem
}) => {
    // Handler to add a feature to a specific plan
    const handleAddFeature = (planIndex) => {
        const updatedPlans = [...formValues.plans];
        updatedPlans[planIndex].features.push('');
        setFormValues({ ...formValues, plans: updatedPlans });
    };

    // Handler to remove a feature from a specific plan
    const handleRemoveFeature = (planIndex, featureIndex) => {
        const updatedPlans = [...formValues.plans];
        updatedPlans[planIndex].features = updatedPlans[planIndex].features.filter(
            (_, i) => i !== featureIndex
        );
        setFormValues({ ...formValues, plans: updatedPlans });
    };

    // Handler to update a specific feature's text
    const handleFeatureChange = (e, planIndex, featureIndex) => {
        const updatedPlans = [...formValues.plans];
        updatedPlans[planIndex].features[featureIndex] = e.target.value;
        setFormValues({ ...formValues, plans: updatedPlans });
    };

    // Render each plan's fields including features
    const renderPlanFields = (plan, planIndex) => (
        <div key={planIndex} className="mb-4 p-3 border rounded">
            <Row className="align-items-center mb-3">
                <Col>
                    <h4>Plan {planIndex + 1}</h4>
                </Col>
                <Col className="text-end">
                    {formValues.plans.length > 1 && (
                        <Button
                            variant="danger"
                            onClick={() => handleRemoveJsonArrayItem('plans', planIndex)}
                        >
                            <FontAwesomeIcon icon={faTrash} /> Remove Plan
                        </Button>
                    )}
                </Col>
            </Row>

            {/* Plan Name */}
            <Form.Group className="mb-3">
                <Form.Label>Name:</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Enter plan name"
                    value={plan.name}
                    onChange={(e) => {
                        const updatedPlans = [...formValues.plans];
                        updatedPlans[planIndex].name = e.target.value;
                        setFormValues({ ...formValues, plans: updatedPlans });
                    }}
                    required
                />
            </Form.Group>

            {/* Plan Price */}
            <Form.Group className="mb-3">
                <Form.Label>Price:</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Enter plan price"
                    value={plan.price}
                    onChange={(e) => {
                        const updatedPlans = [...formValues.plans];
                        updatedPlans[planIndex].price = e.target.value;
                        setFormValues({ ...formValues, plans: updatedPlans });
                    }}
                    required
                />
            </Form.Group>

            {/* Features */}
            <div className="mb-3">
                <h5>Features</h5>
                {plan.features.map((feature, featureIndex) => (
                    <Row key={featureIndex} className="align-items-center mb-2">
                        <Col xs={10}>
                            <Form.Control
                                type="text"
                                placeholder={`Enter feature ${featureIndex + 1}`}
                                value={feature}
                                onChange={(e) =>
                                    handleFeatureChange(e, planIndex, featureIndex)
                                }
                                required
                            />
                        </Col>
                        <Col xs={2}>
                            {plan.features.length > 1 && (
                                <Button
                                    variant="danger"
                                    onClick={() =>
                                        handleRemoveFeature(planIndex, featureIndex)
                                    }
                                >
                                    <FontAwesomeIcon icon={faTrash} />
                                </Button>
                            )}
                        </Col>
                    </Row>
                ))}
                <Button
                    variant="secondary"
                    onClick={() => handleAddFeature(planIndex)}
                    className="mt-2"
                >
                    <FontAwesomeIcon icon={faPlus} /> Add Feature
                </Button>
            </div>
        </div>
    );

    return (
        <>
            {formValues.plans?.map((plan, planIndex) => renderPlanFields(plan, planIndex))}
            <Button
                variant="primary"
                onClick={() => handleAddJsonArrayItem('plans', { name: '', price: '', features: [''] })}
                className="mb-3"
            >
                <FontAwesomeIcon icon={faPlus} /> Add Plan
            </Button>
        </>
    );
};

export default PricingTableForm;
