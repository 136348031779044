// styleConfig.js

import {
  Typography,
  Spacing,
  Borders,
  Colors,
  Layout,
  Links,
  Opacity,
  ColorOnly,
  Alignment,
  Visibility, 
  Background
} from './styleCategories';

export const styleConfig = {
  iconCard: {
    iconWrap: [...Colors, ...Spacing, ...Borders, ...Visibility],
    icon: [...Typography, ...Colors, ...Spacing, ...Layout, ...Visibility],
    title: [...Typography, ...Colors, ...Spacing, ...Visibility],
    description: [...Typography, ...Colors, ...Spacing, ...Visibility],
    button: [...Typography, ...Colors, ...Borders, ...Spacing, ...Links, ...Visibility],
    container: [...Colors, ...Borders, ...Spacing, ...Layout, ...Visibility],
  },
  iconComponent: {
    iconWrap: [...Colors, ...Spacing, ...Borders, ...Visibility],
    icon: [...Typography, ...Colors, ...Spacing, ...Layout, ...Visibility],
  },  
  heading: {
    text: [...Typography, ...Colors, ...Spacing, ...Borders, ...Visibility],
  },
  image: {
    style: [...Layout, ...Borders, ...Spacing, ...Colors, ...Links, ...Visibility],
  },
  faq: {
    title: [...Typography, ...Colors, ...Spacing, ...Visibility],
    question: [...Typography, ...Colors, ...Visibility],
    answer: [...Typography, ...Colors, ...Spacing, ...Visibility],
  },
  socialMediaIcons: {
    container: [...Layout, ...Spacing, ...Colors, ...Visibility],
    iconContainer: [...Layout, ...Spacing, ...Colors, ...Visibility],
    icon: [...Layout, ...Colors, ...ColorOnly, ...Borders, ...Spacing, ...Visibility],
  },
  text: { // Configuration for TextComponent
    container: [...Spacing, ...Colors, ...Borders, ...Visibility],
    content: [...Typography, ...Visibility, ...Spacing],
  },
  richText: { // Configuration for RichTextComponent
    container: [...Spacing, ...Colors, ...Borders, ...Visibility],
    content: [...Typography, ...Visibility],
  },
  button: {
    container: [
      ...Colors,
      ...Spacing,
      ...Borders,
      ...Alignment,
      ...Visibility,
    ],
    content: [
      ...Colors,
      ...Typography,
      ...Borders,
      ...Spacing,
      ...Visibility,
      ...Links
    ],
  },
  cardComponent: {
    title: [...Typography, ...Colors, ...Spacing, ...Visibility],
    image: [...Layout, ...Borders, ...Spacing, ...Visibility],
    bodyText: [...Typography, ...Colors, ...Spacing, ...Visibility],
  },
  imageOverlayCard: {
    title: [...Typography, ...Colors, ...Spacing, ...Visibility],
    bodyText: [...Typography, ...Colors, ...Spacing, ...Visibility],
    button: [...Colors, ...Typography, ...Spacing, ...Borders, ...Visibility],
  },
  headerFooterCard: {
    header: [...Typography, ...Colors, ...Spacing, ...Borders, ...Visibility],
    footer: [...Typography, ...Colors, ...Spacing, ...Borders, ...Visibility],
    title: [...Typography, ...Colors, ...Spacing, ...Visibility],
    bodyText: [...Typography, ...Colors, ...Spacing, ...Visibility],
    button: [...Colors, ...Typography, ...Spacing, ...Borders, ...Links, ...Visibility],
  },
  horizontalCard: {
    container: [...Spacing, ...Colors, ...Borders, ...Visibility],
    title: [...Typography, ...Colors, ...Spacing, ...Visibility],
    bodyText: [...Typography, ...Colors, ...Spacing, ...Visibility],
    image: [...Borders, ...Spacing, ...Visibility],
    button: [...Colors, ...Typography, ...Spacing, ...Borders, ...Links, ...Visibility],
  },
  pricingTable: {
    container: [...Borders, ...Visibility],
    planName: [...Typography, ...Colors, ...Spacing, ...Visibility],
    price: [...Typography, ...Colors, ...Spacing, ...Visibility],
    features: [...Typography, ...Colors, ...Spacing, ...Visibility],
    icons: [...ColorOnly, ...Visibility],
    button: [...Colors, ...Typography, ...Spacing, ...Borders, ...Links, ...Visibility],
  },
  testimonialComponent: {
    container: [...Spacing, ...Colors, ...Borders, ...Visibility],
    image: [...Borders, ...Spacing, ...Visibility],
    name: [...Typography, ...Colors, ...Spacing, ...Visibility],
    position: [...Typography, ...Colors, ...Spacing, ...Visibility],
    message: [...Typography, ...Colors, ...Spacing, ...Visibility],
  },
  blockquote: {
    container: [...Spacing, ...Colors, ...Borders, ...Visibility],
    blockquote: [...Typography, ...Borders, ...Visibility],
    footer: [...Typography, ...Borders, ...Visibility],
  },
  divider: {
    divider: [...Borders, ...Spacing, ...Layout, ...Visibility],
  },
  alert: {
    alertStyle: [...Colors, ...Typography, ...Spacing, ...Borders, ...Visibility],
  },
  badge: {
    badgeStyle: [...Colors, ...Typography, ...Spacing, ...Borders, ...Visibility],
  },
  spacer: {
    spacerStyle: [
      'height', // Assuming 'height' isn't covered by existing categories
      ...Colors,
      ...Spacing,
      ...Borders,
      ...Visibility,
    ],
  },
  teamSection: {
    container: [...Borders, ...Spacing, ...Colors, ...Visibility],
    image: [...Borders, ...Visibility, ...Layout],
    name: [...Typography, ...Colors, ...Spacing, ...Visibility],
    position: [...Typography, ...Colors, ...Spacing, ...Visibility],
    icon: [...Colors, ...ColorOnly, ...Spacing, ...Visibility],
  },
  featureCards: {
    headline: [...Typography, ...Colors, ...Spacing, ...Visibility],
    description: [...Typography, ...Colors, ...Spacing, ...Visibility],
    card: [...Borders, ...Layout, ...Spacing, ...Visibility],
    image: [...Borders, ...Layout, ...Visibility],
    title: [...Typography, ...Colors, ...Spacing, ...Visibility],
    text: [...Typography, ...Colors, ...Spacing, ...Visibility],
  },
  tabs: {
    container: [...Colors, ...Borders, ...Spacing, ...Visibility],
    tabItem: [...Typography, ...Colors, ...Borders, ...Spacing, ...Visibility],
    activeItem: [...Typography, ...Colors, ...Borders, ...Spacing, ...Visibility],
    tabContent: [...Colors, ...Spacing, ...Borders, ...Typography, ...Visibility],
  },
  accordion: {
    container: [...Colors, ...Borders, ...Spacing, ...Visibility],
    header: [...Colors, ...Typography, ...Spacing, ...Borders, ...Visibility],
    body: [...Colors, ...Typography, ...Spacing, ...Borders, ...Visibility],
  },
  listGroupCard: {
    container: [...Colors, ...Borders, ...Spacing, ...Visibility, ...Background],
    header: [...Typography, ...Colors, ...Spacing, ...Borders, ...Visibility],
    item: [...Typography, ...Colors, ...Spacing, ...Borders, ...Visibility],
    icon: [...Colors, ...ColorOnly, ...Spacing, ...Visibility],
  },
  modal: {
    title: [...Typography, ...Colors, ...Spacing, ...Visibility],
    body: [...Typography, ...Colors, ...Spacing, ...Visibility],
    button: [...Colors, ...Typography, ...Spacing, ...Borders, ...Visibility],
    closeButton: [...Colors, ...Typography, ...Borders, ...Visibility],
    overlay: [...Colors, ...Opacity, ...Spacing, ...Visibility],
  },
  timeline: {
    date: [...Typography, ...Colors, ...Spacing, ...Visibility],
    heading: [...Typography, ...Colors, ...Spacing, ...Visibility],
    body: [...Typography, ...Colors, ...Spacing, ...Visibility],
    panel: [...Colors, ...Borders, ...Spacing, ...Layout, ...Visibility],
  },
  progress: {
    title: [...Typography, ...Colors, ...Visibility],
    bar: [...Colors, ...Borders, ...Visibility],
    fill: [...Colors, ...Borders, ...Visibility],
    percentage: [...Typography, ...Colors, ...Visibility],
  },
  breadcrumb: {
    container: [ ...Spacing, ...Borders, ...Alignment, ...Visibility],
    item: [...Typography, ...Spacing, ...Visibility],
    separator: [...Typography, ...Spacing, ...Visibility],
    activeItem: [...Typography, ...Spacing, ...Visibility],
    link: [...Typography, ...Spacing, ...Visibility],
  },
  carousel: {
    headline: [...Typography, ...Colors, ...Spacing, ...Visibility],
    caption: [...Typography, ...Colors, ...Spacing, ...Visibility],
    image: [...Borders, ...Spacing, ...Layout, ...Visibility],
    button: [...Colors, ...Typography, ...Spacing, ...Borders, ...Visibility],
  },
  contactForm: {
    container: [...Colors, ...Spacing, ...Borders, ...Visibility],
    labels: [...Typography, ...Colors, ...Spacing, ...Visibility],
    inputs: [...Typography, ...Colors, ...Spacing, ...Borders, ...Layout, ...Visibility],
    button: [...Colors, ...Typography, ...Spacing, ...Borders, ...Layout, ...Visibility],
  },
  blogList: {
    container: [...Colors, ...Borders, ...Spacing, ...Layout, ...Visibility],
    card: [...Colors, ...Spacing, ...Layout, ...Visibility],
    title: [...Typography, ...Colors, ...Visibility],
    content: [...Typography, ...Colors, ...Spacing, ...Visibility],
    button: [...Colors, ...Typography, ...Spacing, ...Borders, ...Links, ...Visibility],
    image: [...Borders, ...Spacing, ...Layout, ...Visibility],
  },
  gallery: {
    container: [...Borders, ...Spacing, ...Visibility],
    slide: [...Colors, ...Borders, ...Spacing, ...Visibility, ...Layout],
    image: [...Colors, ...Borders, ...Spacing, ...Layout, ...Visibility, ...Background],
  },
  menus: { 
    navbar: [
      ...Colors,
      ...Spacing,
      ...Borders,
      ...Layout,
      ...Visibility,
    ],
    nav: [
      ...Spacing,
      ...Colors,
      ...Layout,
      ...Visibility,
    ],
    navLink: [
      ...Typography,
      ...Colors,
      ...Spacing,
      ...Visibility,
    ],
    navDropdown: [
      ...Typography,
      ...Colors,
      ...Spacing,
      ...Borders,
      ...Layout,
      ...Visibility,
    ],
    dropdownItem: [
      ...Typography,
      ...Colors,
      ...Spacing,
      ...Visibility,
    ],
  },
};
