import React from 'react';
import { Carousel } from 'react-bootstrap';

const SplitScreenTemplate = ({ headline, testimonials, styles = {} }) => (
    <div className="testimonial-split-screen-wrap"  style={styles.container || {}}>
        <Carousel indicators={true} interval={5000}>
            {testimonials.map((testimonial, idx) => (
                <Carousel.Item key={idx} className="testimonial-item-split-screen">
                    <div className="content-wrap">
                        <div className="img-wrap">
                            <img
                                src={testimonial.image}
                                alt={testimonial.name}
                                className="img-fluid"
                                style={styles.image || {}}
                            />
                        </div>
                        <div className="info-right">
                            <p style={styles.message || {}}>"{testimonial.message}"</p>
                            <h5 style={styles.name || {}}>{testimonial.name}</h5>
                            <h6 style={styles.position || {}}>{testimonial.position}</h6>
                        </div>
                    </div>
                </Carousel.Item>
            ))}
        </Carousel>
    </div>
);

export default SplitScreenTemplate;
