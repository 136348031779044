// ColorPickerModal.jsx
import React, { useEffect, useRef } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { ChromePicker } from 'react-color';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark,faCheck } from '@fortawesome/free-solid-svg-icons';

const ColorPickerModal = ({ show, handleClose, color, handleColorChange }) => {
    const pickerRef = useRef(null);

    useEffect(() => {
        if (show && pickerRef.current) {
            // Focus on the first input in ChromePicker
            const firstInput = pickerRef.current.querySelector('input');
        if (firstInput) firstInput.focus();
    }
}, [show]);

return (
    <Modal show={show} onHide={handleClose} centered size="sm" className="color-picker">
        <Modal.Header closeButton>
            <Modal.Title>Pick Color</Modal.Title>
        </Modal.Header>
        <Modal.Body ref={pickerRef}>
            <ChromePicker
                color={color || '#000000'}
                onChangeComplete={(selectedColor) => {
                    handleColorChange(selectedColor.hex);
                }}
            />
        </Modal.Body>
        <Modal.Footer>
            <Button variant="danger" onClick={handleClose}><FontAwesomeIcon icon={faXmark} /></Button>
            <Button variant="success" onClick={handleClose}><FontAwesomeIcon icon={faCheck} /></Button>
        </Modal.Footer>
    </Modal>
);
};

export default ColorPickerModal;
