// src/utils/BuilderHeader.js

import React, { useState, useRef, useEffect } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faFloppyDisk,
    faEye,
    faClone,
    faSpinner,
    faMagic,
    faEllipsisV,
    faPlus,
    faCode,
    faBars,
    faEllipsisVertical,
    faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom'; // Import useNavigate


function BuilderPostHeader({
    onSavePost,
    loading,
    currentPageURL,
}) {
    const navigate = useNavigate(); // Initialize useNavigate

    return (
        <nav className="navbar navbar-light">
            <div className="build-nav">
                <div className="row align-items-center justify-content-between">
                    <div className="col-md-3"></div>
                    <div className="col-md-9 d-flex align-items-center justify-content-between">
                        {/* Center Section: Primary Actions */}
                        <div className="d-flex align-items-center build-nav-right">
                            <button className="btn me-2">
                                <FontAwesomeIcon icon={faClone} /> Clone Post
                            </button>
                            <button className="btn me-2">
                                <FontAwesomeIcon icon={faEye} /> Preview Post
                            </button>
                        </div>

                        <div className="d-flex align-items-center">
                            {/* Right Section: Customization Options */}
                            <div className="save-head">
                                <button
                                    className="btn btn-light"
                                    onClick={onSavePost}
                                    disabled={loading}
                                >
                                    {loading ? (
                                        <>
                                            <FontAwesomeIcon icon={faSpinner} /> Saving...
                                        </>
                                    ) : (
                                        <>
                                            <FontAwesomeIcon icon={faFloppyDisk} /> Save
                                        </>
                                    )}
                                </button>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </nav>
    );

}

BuilderPostHeader.propTypes = {
    onSavePost: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    currentPageURL: PropTypes.string,
};


export default BuilderPostHeader;
