import React from 'react';
import { Form } from 'react-bootstrap';

const MapForm = ({ formValues, setFormValues }) => (
    <>
        <Form.Group controlId="formMapLat">
            <Form.Label>Latitude</Form.Label>
            <Form.Control
                type="text"
                name="lat"
                value={formValues.location?.lat || ''}
                onChange={(e) =>
                    setFormValues({
                        ...formValues,
                        location: { ...formValues.location, lat: e.target.value }
                    })
                }
            />
        </Form.Group>
        <Form.Group controlId="formMapLng">
            <Form.Label>Longitude</Form.Label>
            <Form.Control
                type="text"
                name="lng"
                value={formValues.location?.lng || ''}
                onChange={(e) =>
                    setFormValues({
                        ...formValues,
                        location: { ...formValues.location, lng: e.target.value }
                    })
                }
            />
        </Form.Group>
        <Form.Group controlId="formMapZoom">
            <Form.Label>Zoom</Form.Label>
            <Form.Control
                type="text"
                name="zoom"
                value={formValues.zoom || ''}
                onChange={(e) => setFormValues({ ...formValues, zoom: e.target.value })}
            />
        </Form.Group>
    </>
);

export default MapForm;
