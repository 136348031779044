// src/pages/RowItem.js

import React from 'react';
import DroppableRow from './component/droppable/DroppableRow';
import DroppableSection from './component/droppable/DroppableSection';
import ColumnItem from './ColumnItem';
import CustomPopover from './component/CustomPopover';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowsUpDownLeftRight,
  faEyeSlash,
  faEye,
  faClone,
  faTrashCan,
  faCog,
  faAngleDown,
  faAngleUp,
  faPlus,
  faPenToSquare
} from '@fortawesome/free-solid-svg-icons';
import { componentTypes } from './config/componentTypes';
import { useResponsiveStyles } from './component/pagebuilder/useResponsiveStyles';

function RowItem({
  row,
  index,

  // Row-level actions
  moveRow,
  removeRow,
  cloneRow,
  toggleRowState,
  collapsedRows,
  toggleCollapse,
  openRowStyleEditor,

  // Section-level actions
  moveSection,
  removeSection,
  cloneSection,
  openSectionStyleEditor,
  openSubSectionStyleEditor,
  changeLayout,
  columnLayouts,
  toggleSectionCollapse,
  collapsedSections,
  toggleSectionState,
  updateSectionInRow,

  // Column/subSection-level actions
  addComponentToColumn,
  addSubComponentToColumn,
  dropResetFlags,
  removeSubSection,
  cloneNestedSection,
  toggleSubSectionState,
  changeSubLayout,
  moveSubSection,

  // Component-level actions
  handleRemove,
  removeSubComponent,
  removeComponent,
  cloneComponent,
  cloneNestedComponent,
  handleEditComponent,
  saveChanges,

  // Additional callbacks/vars
  handleButtonClick,
  activeRowId,
  addButtonRef
}) {
  // Apply responsive styles to the row
  const appliedStyles = useResponsiveStyles(row.styles);

  return (
    <DroppableRow
      key={row.id}
      index={index}
      moveRow={moveRow}
      onRemoveRow={() => removeRow(row.id)}
      onCloneRow={() => cloneRow(row.id)}
    >
      <div
        key={row.id}
        className="mb-2 p-2 pb-5 added-row"
        style={appliedStyles}
      >
        <div className="section-edit-set-wrap d-flex justify-content-between align-items-center">
          <div className="actions-wrap">
            <button className="drag-handle">
              <FontAwesomeIcon icon={faArrowsUpDownLeftRight} />
            </button>
            <button onClick={() => toggleRowState(row.id)}>
              <FontAwesomeIcon icon={row.disabled ? faEyeSlash : faEye} />
            </button>
            <button onClick={() => cloneRow(row.id)}>
              <FontAwesomeIcon icon={faClone} />
            </button>
            <button onClick={() => handleRemove(removeRow, [row.id])}>
              <FontAwesomeIcon icon={faTrashCan} />
            </button>
            <button onClick={() => openRowStyleEditor(row)}>
              <FontAwesomeIcon icon={faCog} />
            </button>
          </div>

          <span className="row-title">Row {row.id}</span>

          <button
            className="btn collapse-toggle-btn"
            onClick={() => toggleCollapse(row.id)}
          >
            {collapsedRows[row.id] ? (
              <FontAwesomeIcon icon={faAngleDown} />
            ) : (
              <FontAwesomeIcon icon={faAngleUp} />
            )}
          </button>
        </div>

        {!collapsedRows[row.id] && (
          <div className="row-content">
            <div className={`row ${row.disabled ? 'disabled-row' : ''}`}>
              {row.sections.map((section, sectionIndex) => (
                <DroppableSection
                  key={section.id}
                  id={section.id}
                  rowId={row.id}
                  index={sectionIndex}
                  moveSection={moveSection}
                  onRemoveSection={() => removeSection(row.id, section.id)}
                  onCloneSection={() => cloneSection(row.id, section.id)}
                >
                  <div className="applied-grid">
                    <div className="row-edit-set-wrap d-flex justify-content-between align-items-center">
                      <div className="actions-wrap">
                        <button className="drag-handle">
                          <FontAwesomeIcon icon={faArrowsUpDownLeftRight} />
                        </button>
                        <button
                          onClick={() =>
                            toggleSectionState(row.id, section.id)
                          }
                        >
                          <FontAwesomeIcon
                            icon={section.disabled ? faEyeSlash : faEye}
                          />
                        </button>
                        <button onClick={() => cloneSection(row.id, section.id)}>
                          <FontAwesomeIcon icon={faClone} />
                        </button>
                        <button
                          onClick={() =>
                            handleRemove(removeSection, [row.id, section.id])
                          }
                        >
                          <FontAwesomeIcon icon={faTrashCan} />
                        </button>
                        <button
                          onClick={() => openSectionStyleEditor(row.id, section)}
                        >
                          <FontAwesomeIcon icon={faCog} />
                        </button>

                        <CustomPopover
                          title="Select Grid Layout"
                          layouts={columnLayouts}
                          onLayoutSelect={(layoutKey) =>
                            changeLayout(row.id, section.id, layoutKey)
                          }
                          triggerElement={
                            <div
                              style={{ cursor: 'pointer', display: 'inline-block' }}
                            >
                              <svg
                                viewBox="0 0 28 28"
                                preserveAspectRatio="xMidYMid meet"
                                shapeRendering="geometricPrecision"
                                style={{
                                  fill: 'rgb(255, 255, 255)',
                                  width: '35px',
                                  minWidth: '35px',
                                  height: '36px',
                                  margin: '-3px 0 0 3px',
                                  opacity: 1,
                                  transform: 'scale(1)'
                                }}
                              >
                                <g>
                                  <path
                                    d="M20 8H8a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V9a1 1 0 0 0-1-.999V8zm-7 2h2v8h-2v-8zm-2 8H9v-8h2v8zm6-8h2v8h-2v-8z"
                                    fillRule="evenodd"
                                  />
                                </g>
                              </svg>
                            </div>
                          }
                        />
                      </div>
                      <button
                        className="btn collapse-toggle-btn"
                        onClick={() => toggleSectionCollapse(section.id)}
                      >
                        {collapsedSections[section.id] ? (
                          <FontAwesomeIcon icon={faAngleDown} />
                        ) : (
                          <FontAwesomeIcon icon={faAngleUp} />
                        )}
                      </button>
                    </div>

                    {!collapsedSections[section.id] && (
                      <div
                        className={`row ${section.disabled ? 'disabled-section' : ''}`}
                      >
                        {section.columns.map((column, colIdx) => (
                          <ColumnItem
                            key={colIdx}
                            column={column}
                            rowId={row.id}
                            sectionId={section.id}
                            colIdx={colIdx}
                            subSectionId={null} // Pass subSectionId if rendering within a subSection
                            subColIdx={null} // Pass subColIdx if rendering within a subSection
                            addComponentToColumn={addComponentToColumn}
                            addSubComponentToColumn={addSubComponentToColumn}
                            dropResetFlags={dropResetFlags}
                            removeSubSection={removeSubSection}
                            cloneNestedSection={cloneNestedSection}
                            toggleSubSectionState={toggleSubSectionState}
                            changeSubLayout={changeSubLayout}
                            handleRemove={handleRemove}
                            removeSubComponent={removeSubComponent}
                            removeComponent={removeComponent}
                            cloneNestedComponent={cloneNestedComponent}
                            handleEditComponent={handleEditComponent}
                            saveChanges={saveChanges}
                            cloneComponent={cloneComponent}
                            handleButtonClick={handleButtonClick}
                            activeRowId={activeRowId}
                            addButtonRef={addButtonRef}
                            columnLayouts={columnLayouts}
                            moveSubSection={moveSubSection}
                            openSubSectionStyleEditor={openSubSectionStyleEditor}
                          />
                        ))}
                      </div>
                    )}
                  </div>
                </DroppableSection>
              ))}
            </div>

            <div className="add-new-section-link-container">
              <CustomPopover
                trigger="click"
                placement="bottom"
                title="Select Grid Layout"
                layouts={columnLayouts}
                onLayoutSelect={(layoutKey) => updateSectionInRow(row.id, layoutKey)}
                triggerElement={
                  <div
                    className="add-new-section-link"
                    ref={activeRowId === row.id ? addButtonRef : null}
                    style={{ cursor: 'pointer', display: 'inline-block' }}
                  >
                    <FontAwesomeIcon icon={faPlus} />
                  </div>
                }
              />
            </div>
          </div>
        )}
      </div>
    </DroppableRow>
  );
}

export default RowItem;
