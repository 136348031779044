import React from 'react';
import { useResponsiveStyles } from './useResponsiveStyles';

function ButtonComponent({
  text,
  styles = {},
  template = "Default",
}) {
  const containerClassName = `${template.toLowerCase()}-button`; // Class for the button template

  const appliedStyles = useResponsiveStyles(styles);

  // Extract container and content styles from the styles object
  const containerStyles = appliedStyles.container || {};
  const contentStyles = appliedStyles.content || {};

  // Handle the button link
  const handleClick = () => {
    if (contentStyles.link) {
      window.open(contentStyles.link, '_blank');
    }
  };

  return (
    <div style={containerStyles} className="button-container">
      <button
        style={contentStyles}
        onClick={handleClick}
        className={`btn ${containerClassName}`}
      >
        {text || ""}
      </button>
    </div>
  );
}

export default ButtonComponent;
