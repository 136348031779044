import React from 'react';
import ClassicTeamTemplate from './templates/teamsection/ClassicTeamTemplate';
import ModernTeamTemplate from './templates/teamsection/ModernTeamTemplate';
import SplitTeamTemplate from './templates/teamsection/SplitTeamTemplate';
import CardOverlayTeamTemplate from './templates/teamsection/CardOverlayTeamTemplate';
import MinimalGridTeamTemplate from './templates/teamsection/MinimalGridTeamTemplate';

import { useResponsiveStyles } from './useResponsiveStyles'; // Import the responsive styles hook

const TeamSectionComponent = ({ template = "Classic", styles = {}, ...props }) => {
    const appliedStyles = useResponsiveStyles(styles); // Get responsive styles

    // Function to render the appropriate template with appliedStyles
    const renderTemplate = (templateName) => {
        switch (templateName) {
            case "Modern":
                return <ModernTeamTemplate {...props} styles={appliedStyles} />;
            case "Split":
                return <SplitTeamTemplate {...props} styles={appliedStyles} />;
            case "CardOverlay":
                return <CardOverlayTeamTemplate {...props} styles={appliedStyles} />;
            case "MinimalGrid":
                return <MinimalGridTeamTemplate {...props} styles={appliedStyles} />;
            default: // Classic
                return <ClassicTeamTemplate {...props} styles={appliedStyles} />;
        }
    };

    return renderTemplate(template);
};

export default TeamSectionComponent;
