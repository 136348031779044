import React from 'react';
import { useResponsiveStyles } from '../../useResponsiveStyles';

const ClassicHeaderFooterCard = ({ header, footer, title, bodyText, styles = {} }) => {

    const appliedStyles = useResponsiveStyles(styles);

    return (
        <div className="card classic-hf-card">
            <div className="card-header classic-header" style={appliedStyles.header || {}}>
                {header}
            </div>
            <div className="card-body">
                <h5 className="card-title" style={appliedStyles.title || {}}>
                    {title}
                </h5>
                <p className="card-text" style={appliedStyles.bodyText || {}}>
                    {bodyText}
                </p>
                <a href={appliedStyles.button?.link || "#"} className="btn btn-primary" style={appliedStyles.button || {}}>
                    {appliedStyles.button?.text || "Read More"}
                </a>
            </div>
            <div className="card-footer classic-footer" style={appliedStyles.footer || {}}>
                {footer}
            </div>
        </div>
    );
};

export default ClassicHeaderFooterCard;
