import React from 'react';
import { useResponsiveStyles } from './useResponsiveStyles';


function BadgeComponent({ text = 'Default Badge', variant = 'text-bg-secondary', styles = {} }) {

    const appliedStyles = useResponsiveStyles(styles);

    return (
        <span
            className={`badge ${variant}`}
            style={appliedStyles.badgeStyle} // Apply dynamic styles
        >
            {text}
        </span>
    );
}

export default BadgeComponent;
