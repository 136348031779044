import React from 'react';
import { Carousel, Button } from 'react-bootstrap';

function ClassicCarousel({ items, styles = {} }) {
    const {
        headline = {},
        caption = {},
        image = {},
        button = {}
    } = styles || {};

    return (
        <Carousel className="slider-container classic-slider">
            {items && items.length > 0 ? (
                items.map((item, index) => (
                    <Carousel.Item key={index}>
                        <img
                            className="d-block w-100"
                            src={item.src}
                            alt={item.alt || `Slide ${index + 1}`}
                            style={{ 
                                objectFit: 'cover', 
                                height: '500px', 
                                ...image 
                            }}     
                        />
                        <Carousel.Caption style={caption}>
                            <div>
                                {item.caption && <h1 style={caption}>{item.caption}</h1>}
                                {item.alt && <p style={headline}><b>{item.alt}</b></p>}
                                {item.buttonText && (
                                    <Button
                                        variant="primary"
                                        href={item.buttonLink || '#'}
                                        style={button}
                                    >
                                        {item.buttonText}
                                    </Button>
                                )}
                            </div>
                        </Carousel.Caption>
                    </Carousel.Item>
                ))
            ) : (
                <div>No items to display</div>
            )}
        </Carousel>
    );
}

export default ClassicCarousel;
