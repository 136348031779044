// styleCategories.js

export const Typography = [
  'fontFamily',
  'fontSize',
  'fontWeight',
  'fontStyle',
  'lineHeight',
  'letterSpacing',
  'textAlign',
  'textDecoration',
  'color',
  'textTransform'

];

export const Spacing = ['margin', 'padding'];

export const Borders = ['border', 'borderRadius', 'boxShadow'];

export const Colors = ['backgroundColor'];

export const Layout = [
  'width',
  'height',
];

export const Visibility = ['display'];

export const Links = ['link'];
export const Alignment = ['textAlign'];


export const Opacity = ['opacity'];
export const ColorOnly = ['color'];
