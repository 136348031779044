import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faQuestionCircle, faAngleDown, faAngleRight } from '@fortawesome/free-solid-svg-icons';

import { useResponsiveStyles } from './useResponsiveStyles';

const FAQComponent = ({ faqs = [], styles = {}, title = "Frequently Asked Questions", template = "ModernClassic" }) => {
    const [openIndexes, setOpenIndexes] = useState([]);
    const appliedStyles = useResponsiveStyles(styles);

    const handleToggle = (index) => {
        setOpenIndexes((prev) =>
            prev.includes(index) ? prev.filter((i) => i !== index) : [...prev, index]
        );
    };

    const renderFAQs = (icon, additionalClasses = '') =>
        faqs.map((faq, idx) => (
            <div key={idx} className={`faq-card ${additionalClasses}`}>
                <div
                    className="faq-header"
                    id={`heading${idx}`}
                    style={appliedStyles.question || {}}
                    onClick={() => handleToggle(idx)}
                >
                    <h3>
                        <FontAwesomeIcon
                            icon={icon}
                            style={{ marginRight: '10px', ...appliedStyles.icon }}
                        />
                        {faq.question}
                    </h3>
                    <FontAwesomeIcon
                        icon={openIndexes.includes(idx) ? faAngleDown : faAngleRight}
                        className="faq-toggle-icon"
                    />
                </div>
                <div
                    id={`collapse${idx}`}
                    className={`faq-body ${openIndexes.includes(idx) ? 'open' : ''}`}
                    style={appliedStyles.answer || {}}
                >
                    {faq.answer}
                </div>
            </div>
        ));

    switch (template) {
        case "ModernClassic":
            return (
                <div className="faq-modern-classic">
                    <h2 style={{ ...appliedStyles.title, textAlign: 'center' }}>{title}</h2>
                    {renderFAQs(faQuestionCircle, 'modern-classic')}
                </div>
            );

        case "CollapsibleGradient":
            return (
                <div className="faq-collapsible-gradient">
                    <h2 style={appliedStyles.title || {}}>{title}</h2>
                    {renderFAQs(faAngleRight, 'collapsible-gradient')}
                </div>
            );

        case "BoxedShadow":
            return (
                <div className="faq-boxed-shadow">
                    <h2 style={appliedStyles.title || {}}>{title}</h2>
                    {renderFAQs(faCheck, 'boxed-shadow')}
                </div>
            );

        case "Minimal":
            return (
                <div className="faq-minimal">
                    <h2 style={{ ...appliedStyles.title, fontWeight: '400' }}>{title}</h2>
                    {renderFAQs(faQuestionCircle, 'minimal')}
                </div>
            );

        case "Interactive":
            return (
                <div className="faq-interactive">
                    <h2 style={appliedStyles.title || {}}>{title}</h2>
                    {renderFAQs(faCheck, 'interactive')}
                </div>
            );

        default:
            return (
                <div className="faq-default">
                    <h2 style={appliedStyles.title || {}}>{title}</h2>
                    {renderFAQs(faCheck)}
                </div>
            );
    }
};

export default FAQComponent;
