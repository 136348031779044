import React from 'react';

const TextOnlyTemplate = ({ headline, testimonials, styles = {} }) => (
    <div className="testimonial-text-only-wrap"  style={styles.container || {}}>
        {testimonials.map((testimonial, idx) => (
            <div key={idx} className="testimonial-item-text-only">
                <p style={styles.message || {}}>"{testimonial.message}"</p>
                <h5 style={styles.name || {}}>{testimonial.name}</h5>
                <h6 style={styles.position || {}}>{testimonial.position}</h6>
            </div>
        ))}
    </div>
);

export default TextOnlyTemplate;
