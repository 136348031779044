// utils/nl2br.js
import React from 'react';

export function nl2br(text) {
    if (!text) return null;
    return text.split('\n').map((line, index) => (
        <React.Fragment key={index}>
            {line}
            <br />
        </React.Fragment>
    ));
}
