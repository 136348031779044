// RichTextDisplay.js
import React from 'react';
import { useResponsiveStyles } from './useResponsiveStyles';

const RichTextDisplay = ({ content, styles = {} }) => {
    const appliedStyles = useResponsiveStyles(styles);

    return (
        <div style={appliedStyles.container}>
            <div
                style={appliedStyles.content}
                dangerouslySetInnerHTML={{ __html: content || "" }}
            />
        </div>
    );
};

export default RichTextDisplay;
