// templates/gallery/SliderTemplate.js

import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const SliderTemplate = ({ images, styles = {}, imageClass = 'slider-image' }) => {
   
    const sliderSettings = {
        dots: images.length > 1,
        arrows: images.length > 1,
        infinite: images.length > 3,
        speed: 500,
        slidesToShow: Math.min(images.length, 5),
        slidesToScroll: 1,
        autoplay: true, // Enables autoplay
        autoplaySpeed: 3000, // 3 seconds between slides
        pauseOnHover: true, // Pause autoplay on hover
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: Math.min(images.length, 2),
                },
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    };

    const defaultContainerStyle = {
        position: 'relative',
        width: '100%',
        ...styles.container,
    };

    const slideStyle = {
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        padding: '0 10px',
        height: '300px', // You can adjust the height as needed or make it dynamic
        ...styles.slide,
    };

    return (
        <div className="gallery-slider" style={defaultContainerStyle}>
            <Slider {...sliderSettings}>
                {images.map((img, idx) => (
                    <div key={idx} style={slideStyle}>
                        <div
                            className={imageClass}
                            style={{
                                backgroundImage: `url(${img.src})`,
                                backgroundSize: 'contain',
                                backgroundPosition: 'center',
                                backgroundRepeat: 'no-repeat',
                                ...styles.image,
                            }}
                            alt={img.alt || `Image ${idx + 1}`} // Note: alt attribute isn't standard for divs
                            data-index={idx} // Example of adding data attributes for tracking
                        >
                            
                        </div>
                    </div>
                ))}
            </Slider>
        </div>
    );
};

export default SliderTemplate;

