import React from 'react';
import { useResponsiveStyles } from './useResponsiveStyles';

function HeadingComponent({ level, text, styles, template = "Dotted-Underline" }) {
    const HeadingTag = `h${level}`;
    const containerClassName = `${template.toLowerCase()}`; // Class for parent div
    const appliedStyles = useResponsiveStyles(styles);
     
    return (
        <div className={containerClassName}>
            <HeadingTag style={appliedStyles.text}>
                {text || `Heading ${level}`}
            </HeadingTag>
        </div>
    );
}

export default HeadingComponent;
