// IconCardForm.js

import React, { useState } from 'react';
import { Form, Row, Col, Button, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Importing specific Solid Icons
import {
    faStar,
    faHeart,
    faCoffee,
    faHome,
    faUser,
    faEnvelope,
    faCamera,
    faCalendar,
    faCheck,
    faTimes,
    faArrowUp,
    faArrowDown,
    faArrowLeft,
    faArrowRight,
    faCar,
    faCloud,
    faBolt,
    faUmbrella,
    faBook,
    faBuilding,
    faPaperPlane,
    faSmile,
    faSearch,
    faThumbsUp,
    faThumbsDown,
    faEdit,
    faTrash,
    faPlus,
    faMinus,
    faLightbulb,
    faMapMarkerAlt,
    faGlobe,
    faLaptop,
    faDesktop,
    faMobileAlt,
    faTabletAlt,
    faTools,
    faCogs,
    faWrench,
    faHammer,
    faFileAlt,
    faFilePdf,
    faFileWord,
    faFileExcel,
    faFolder,
    faFolderOpen,
    faArchive,
    faClipboardList,
    faEnvelopeOpen,
    faEnvelopeSquare,
    faPen,
    faPencilAlt,
    faRuler,
    faPaintBrush,
    faPalette,
    faCut,
    faPlug,
    faBatteryFull,
    faBatteryHalf,
    faBatteryEmpty,
    faPhone,
    faFax,
    faWifi,
    faBroadcastTower,
    faTrain,
    faBus,
    faPlane,
    faShip,
    faAnchor,
    faRocket,
    faRoad,
    faGasPump,
    faKey,
    faLock,
    faLockOpen,
    faShieldAlt,
    faFingerprint,
    faUniversalAccess,
    faEye,
    faEyeSlash,
    faGlasses,
    faMicroscope,
    faSyringe,
    faPills,
    faFirstAid,
    faBriefcaseMedical,
    faVirus,
    faMask,
    faHandshake,
    faHandHolding,
    faHandHoldingHeart,
    faPrayingHands,
    faHandsHelping,
    faHandsWash,
    faHandPeace,
    faHandRock,
    faHandScissors,
    faHandSpock,
    faHandLizard,
    faHandMiddleFinger,
    faHandshakeAlt,
    faFistRaised,
    faUsers,
    faUserFriends,
    faUserShield,
    faUserTie,
    faUserSecret,
    faUserNurse,
    faUserCheck,
    faUserClock,
    faUserMinus,
    faUserPlus,
    faUserSlash,
    faUserCog,
    faUsersCog,
    faChild,
    faBaby,
    faMale,
    faFemale,
    faGenderless,
    faTransgender,
    faChess,
    faChessPawn,
    faChessQueen,
    faChessKing,
    faChessKnight,
    faChessRook,
    faChessBishop,
    faDice,
    faDiceD20,
    faDiceD6,
    faDiceFive,
    faDiceFour,
    faDiceOne,
    faDiceSix,
    faDiceThree,
    faDiceTwo,
    faGamepad,
    faHeadphones,
    faHeadset,
    faCompactDisc,
    faGuitar,
    faDrum,
    faDrumstickBite,
    faFilm,
    faCameraRetro,
    faVideo,
    faTv,
    faTicketAlt,
    faGift,
    faShoppingCart,
    faShoppingBasket,
    faStore,
    faStoreAlt,
    faTruck,
    faTruckLoading,
    faShippingFast,
    faBox,
    faBoxOpen,
    faCube,
    faCubes,
    faBalanceScale,
    faCoins,
    faCashRegister,
    faCreditCard,
    faWallet,
    faReceipt,
    faFileInvoice,
    faFileInvoiceDollar,
    faPiggyBank,
    faMoneyBill,
    faMoneyCheck,
    faLandmark,
    faUniversity,
    faBuildingColumns,
    faGavel,
    faBriefcase,
    faSuitcase,
    faToolbox,
    faTrashRestore,
    faRecycle,
    faLeaf,
    faSeedling,
    faTree,
    faSpa,
    faKitMedical,
    faCalendarPlus
} from '@fortawesome/free-solid-svg-icons';

// Importing specific Brand Icons
import {
    faFacebook,
    faTwitter,
    faInstagram,
    faLinkedin,
    faYoutube,
    faTiktok,
    faPinterest,
    faReddit,
    faSnapchat,
    faGithub,
    faGitlab,
    faBitbucket,
    faDropbox,
    faSlack,
    faMicrosoft,
    faGoogleDrive,
    faAws,
    faSpotify,
    faApple,
    faAndroid,
    faWindows,
    faDribbble,
    faBehance,
    faFigma,
    // faAdobe, // Removed since it's not available
    faChrome,
    faFirefox,
    faEdge,
    faOpera,
    faSafari,
    faVimeo,
    faStackOverflow,
    faStackExchange,
    faWordpress,
    faJoomla,
    faDrupal,
    faMagento,
    faShopify,
    faMailchimp,
    faBuffer,
    faTrello,
    faDiscord
} from '@fortawesome/free-brands-svg-icons';

// Define the available icons
const availableIcons = [
    // Solid Icons
    { name: 'Star', icon: faStar },
    { name: 'Heart', icon: faHeart },
    { name: 'Coffee', icon: faCoffee },
    { name: 'Home', icon: faHome },
    { name: 'User', icon: faUser },
    { name: 'Envelope', icon: faEnvelope },
    { name: 'Camera', icon: faCamera },
    { name: 'Calendar', icon: faCalendar },
    { name: 'Check', icon: faCheck },
    { name: 'Times', icon: faTimes },
    { name: 'Arrow Up', icon: faArrowUp },
    { name: 'Arrow Down', icon: faArrowDown },
    { name: 'Arrow Left', icon: faArrowLeft },
    { name: 'Arrow Right', icon: faArrowRight },
    { name: 'Car', icon: faCar },
    { name: 'Cloud', icon: faCloud },
    { name: 'Bolt', icon: faBolt },
    { name: 'Umbrella', icon: faUmbrella },
    { name: 'Book', icon: faBook },
    { name: 'Building', icon: faBuilding },
    { name: 'Paper Plane', icon: faPaperPlane },
    { name: 'Smile', icon: faSmile },
    { name: 'Search', icon: faSearch },
    { name: 'Thumbs Up', icon: faThumbsUp },
    { name: 'Thumbs Down', icon: faThumbsDown },
    { name: 'Edit', icon: faEdit },
    { name: 'Trash', icon: faTrash },
    { name: 'Plus', icon: faPlus },
    { name: 'Minus', icon: faMinus },
    { name: 'Lightbulb', icon: faLightbulb },
    { name: 'Map Marker', icon: faMapMarkerAlt },
    { name: 'Globe', icon: faGlobe },
    { name: 'Laptop', icon: faLaptop },
    { name: 'Desktop', icon: faDesktop },
    { name: 'Mobile', icon: faMobileAlt },
    { name: 'Tablet', icon: faTabletAlt },
    { name: 'Tools', icon: faTools },
    { name: 'Cogs', icon: faCogs },
    { name: 'Wrench', icon: faWrench },
    { name: 'Hammer', icon: faHammer },
    { name: 'File', icon: faFileAlt },
    { name: 'File PDF', icon: faFilePdf },
    { name: 'File Word', icon: faFileWord },
    { name: 'File Excel', icon: faFileExcel },
    { name: 'Folder', icon: faFolder },
    { name: 'Folder Open', icon: faFolderOpen },
    { name: 'Archive', icon: faArchive },
    { name: 'Clipboard List', icon: faClipboardList },
    { name: 'Envelope Open', icon: faEnvelopeOpen },
    { name: 'Envelope Square', icon: faEnvelopeSquare },
    { name: 'Pen', icon: faPen },
    { name: 'Pencil', icon: faPencilAlt },
    { name: 'Ruler', icon: faRuler },
    { name: 'Paint Brush', icon: faPaintBrush },
    { name: 'Palette', icon: faPalette },
    { name: 'Cut', icon: faCut },
    { name: 'Plug', icon: faPlug },
    { name: 'Battery Full', icon: faBatteryFull },
    { name: 'Battery Half', icon: faBatteryHalf },
    { name: 'Battery Empty', icon: faBatteryEmpty },
    { name: 'Phone', icon: faPhone },
    { name: 'Fax', icon: faFax },
    { name: 'Wifi', icon: faWifi },
    { name: 'Broadcast Tower', icon: faBroadcastTower },
    { name: 'Train', icon: faTrain },
    { name: 'Bus', icon: faBus },
    { name: 'Plane', icon: faPlane },
    { name: 'Ship', icon: faShip },
    { name: 'Anchor', icon: faAnchor },
    { name: 'Rocket', icon: faRocket },
    { name: 'Road', icon: faRoad },
    { name: 'Gas Pump', icon: faGasPump },
    { name: 'Key', icon: faKey },
    { name: 'Lock', icon: faLock },
    { name: 'Lock Open', icon: faLockOpen },
    { name: 'Shield', icon: faShieldAlt },
    { name: 'Fingerprint', icon: faFingerprint },
    { name: 'Universal Access', icon: faUniversalAccess },
    { name: 'Eye', icon: faEye },
    { name: 'Eye Slash', icon: faEyeSlash },
    { name: 'Glasses', icon: faGlasses },
    { name: 'Microscope', icon: faMicroscope },
    { name: 'Syringe', icon: faSyringe },
    { name: 'Pills', icon: faPills },
    { name: 'First Aid', icon: faFirstAid },
    { name: 'Briefcase Medical', icon: faBriefcaseMedical },
    { name: 'Virus', icon: faVirus },
    { name: 'Mask', icon: faMask },
    { name: 'Handshake', icon: faHandshake },
    { name: 'Hand Holding', icon: faHandHolding },
    { name: 'Hand Holding Heart', icon: faHandHoldingHeart },
    { name: 'Praying Hands', icon: faPrayingHands },
    { name: 'Hands Helping', icon: faHandsHelping },
    { name: 'Hands Wash', icon: faHandsWash },
    { name: 'Hand Peace', icon: faHandPeace },
    { name: 'Hand Rock', icon: faHandRock },
    { name: 'Hand Scissors', icon: faHandScissors },
    { name: 'Hand Spock', icon: faHandSpock },
    { name: 'Hand Lizard', icon: faHandLizard },
    { name: 'Hand Middle Finger', icon: faHandMiddleFinger },
    { name: 'Handshake Alt', icon: faHandshakeAlt },
    { name: 'Fist Raised', icon: faFistRaised },
    { name: 'Users', icon: faUsers },
    { name: 'User Friends', icon: faUserFriends },
    { name: 'User Shield', icon: faUserShield },
    { name: 'User Tie', icon: faUserTie },
    { name: 'User Secret', icon: faUserSecret },
    { name: 'User Nurse', icon: faUserNurse },
    { name: 'User Check', icon: faUserCheck },
    { name: 'User Clock', icon: faUserClock },
    { name: 'User Minus', icon: faUserMinus },
    { name: 'User Plus', icon: faUserPlus },
    { name: 'User Slash', icon: faUserSlash },
    { name: 'User Cog', icon: faUserCog },
    { name: 'Users Cog', icon: faUsersCog },
    { name: 'Child', icon: faChild },
    { name: 'Baby', icon: faBaby },
    { name: 'Male', icon: faMale },
    { name: 'Female', icon: faFemale },
    { name: 'Genderless', icon: faGenderless },
    { name: 'Transgender', icon: faTransgender },
    { name: 'Chess', icon: faChess },
    { name: 'Chess Pawn', icon: faChessPawn },
    { name: 'Chess Queen', icon: faChessQueen },
    { name: 'Chess King', icon: faChessKing },
    { name: 'Chess Knight', icon: faChessKnight },
    { name: 'Chess Rook', icon: faChessRook },
    { name: 'Chess Bishop', icon: faChessBishop },
    { name: 'Dice', icon: faDice },
    { name: 'Dice D20', icon: faDiceD20 },
    { name: 'Dice D6', icon: faDiceD6 },
    { name: 'Dice Five', icon: faDiceFive },
    { name: 'Dice Four', icon: faDiceFour },
    { name: 'Dice One', icon: faDiceOne },
    { name: 'Dice Six', icon: faDiceSix },
    { name: 'Dice Three', icon: faDiceThree },
    { name: 'Dice Two', icon: faDiceTwo },
    { name: 'Gamepad', icon: faGamepad },
    { name: 'Headphones', icon: faHeadphones },
    { name: 'Headset', icon: faHeadset },
    { name: 'Compact Disc', icon: faCompactDisc },
    { name: 'Guitar', icon: faGuitar },
    { name: 'Drum', icon: faDrum },
    { name: 'Drumstick Bite', icon: faDrumstickBite },
    { name: 'Film', icon: faFilm },
    { name: 'Camera Retro', icon: faCameraRetro },
    { name: 'Video', icon: faVideo },
    { name: 'TV', icon: faTv },
    { name: 'Ticket', icon: faTicketAlt },
    { name: 'Gift', icon: faGift },
    { name: 'Shopping Cart', icon: faShoppingCart },
    { name: 'Shopping Basket', icon: faShoppingBasket },
    { name: 'Store', icon: faStore },
    { name: 'Store Alt', icon: faStoreAlt },
    { name: 'Truck', icon: faTruck },
    { name: 'Truck Loading', icon: faTruckLoading },
    { name: 'Shipping Fast', icon: faShippingFast },
    { name: 'Box', icon: faBox },
    { name: 'Box Open', icon: faBoxOpen },
    { name: 'Cube', icon: faCube },
    { name: 'Cubes', icon: faCubes },
    { name: 'Balance Scale', icon: faBalanceScale },
    { name: 'Coins', icon: faCoins },
    { name: 'Cash Register', icon: faCashRegister },
    { name: 'Credit Card', icon: faCreditCard },
    { name: 'Wallet', icon: faWallet },
    { name: 'Receipt', icon: faReceipt },
    { name: 'File Invoice', icon: faFileInvoice },
    { name: 'File Invoice Dollar', icon: faFileInvoiceDollar },
    { name: 'Piggy Bank', icon: faPiggyBank },
    { name: 'Money Bill', icon: faMoneyBill },
    { name: 'Money Check', icon: faMoneyCheck },
    { name: 'Landmark', icon: faLandmark },
    { name: 'University', icon: faUniversity },
    { name: 'Building Columns', icon: faBuildingColumns },
    { name: 'Gavel', icon: faGavel },
    { name: 'Briefcase', icon: faBriefcase },
    { name: 'Suitcase', icon: faSuitcase },
    { name: 'Toolbox', icon: faToolbox },
    { name: 'Trash Restore', icon: faTrashRestore },
    { name: 'Recycle', icon: faRecycle },
    { name: 'Leaf', icon: faLeaf },
    { name: 'Seedling', icon: faSeedling },
    { name: 'Tree', icon: faTree },
    { name: 'Spa', icon: faSpa },
    { name: 'Medical Kit', icon: faKitMedical },
    { name: 'Calendar Plus', icon: faCalendarPlus },

    // Brand Icons
    { name: 'Facebook', icon: faFacebook },
    { name: 'Twitter', icon: faTwitter },
    { name: 'Instagram', icon: faInstagram },
    { name: 'LinkedIn', icon: faLinkedin },
    { name: 'YouTube', icon: faYoutube },
    { name: 'TikTok', icon: faTiktok },
    { name: 'Pinterest', icon: faPinterest },
    { name: 'Reddit', icon: faReddit },
    { name: 'Snapchat', icon: faSnapchat },
    { name: 'GitHub', icon: faGithub },
    { name: 'GitLab', icon: faGitlab },
    { name: 'Bitbucket', icon: faBitbucket },
    { name: 'Dropbox', icon: faDropbox },
    { name: 'Slack', icon: faSlack },
    { name: 'Microsoft', icon: faMicrosoft },
    { name: 'Google Drive', icon: faGoogleDrive },
    { name: 'AWS', icon: faAws },
    { name: 'Spotify', icon: faSpotify },
    { name: 'Apple', icon: faApple },
    { name: 'Android', icon: faAndroid },
    { name: 'Windows', icon: faWindows },
    { name: 'Dribbble', icon: faDribbble },
    { name: 'Behance', icon: faBehance },
    { name: 'Figma', icon: faFigma },
    // { name: 'Adobe', icon: faAdobe }, // Removed since faAdobe is not available
    { name: 'Chrome', icon: faChrome },
    { name: 'Firefox', icon: faFirefox },
    { name: 'Edge', icon: faEdge },
    { name: 'Opera', icon: faOpera },
    { name: 'Safari', icon: faSafari },
    { name: 'Vimeo', icon: faVimeo },
    { name: 'Stack Overflow', icon: faStackOverflow },
    { name: 'Stack Exchange', icon: faStackExchange },
    { name: 'WordPress', icon: faWordpress },
    { name: 'Joomla', icon: faJoomla },
    { name: 'Drupal', icon: faDrupal },
    { name: 'Magento', icon: faMagento },
    { name: 'Shopify', icon: faShopify },
    { name: 'Mailchimp', icon: faMailchimp },
    { name: 'Buffer', icon: faBuffer },
    { name: 'Trello', icon: faTrello },
    { name: 'Discord', icon: faDiscord }
];

const IconCardForm = ({ formValues, setFormValues }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [showModal, setShowModal] = useState(false);

    const handleIconChange = (icon) => {
        setFormValues({ ...formValues, icon });
        setShowModal(false); // Close modal after selection
    };

    const filteredIcons = availableIcons.filter((iconItem) =>
        iconItem.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div className="icon-card-form carousel-wrap pb-2">
            {/* Selected Icon */}
            <Form.Group controlId="formSelectedIcon" className="mb-3">
                <Form.Label>Selected Icon</Form.Label>
                <div>
                    {formValues.icon ? (
                        <Button
                            variant="outline-secondary"
                            className="d-flex align-items-center"
                            onClick={() => setShowModal(true)}
                            aria-label="Change Icon"
                        >
                            <FontAwesomeIcon icon={formValues.icon} size="2x" className="me-2" />
                            Change Icon
                        </Button>
                    ) : (
                        <Button variant="outline-secondary" onClick={() => setShowModal(true)} aria-label="Choose Icon">
                            Choose Icon
                        </Button>
                    )}
                </div>
            </Form.Group>

            {/* Icon Selection Modal */}
<Modal
    className="model-styling"
    show={showModal}
    onHide={() => setShowModal(false)}
    size="lg"
>
    <Modal.Header closeButton>
        <Modal.Title>Select an Icon</Modal.Title>
    </Modal.Header>
    <Modal.Body>
        {/* Icon Search */}
        <Form.Group
            controlId="formIconSearch"
            className="mb-3"
            style={{
                position: 'sticky',     // Makes the search bar stick to the top
                top: '0',               // Positions the sticky element at the top
                backgroundColor: 'white', // Ensures the background remains white when scrolling
                zIndex: '1',            // Places the search bar above other content
                paddingBottom: '10px',  // Adds space below the search bar
                borderBottom: '1px solid #dee2e6', // Adds a subtle border below the search bar
            }}
        >
            <Form.Control
                type="text"
                placeholder="Search icons"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                aria-label="Search Icons"
                style={{
                 padding: '20px',           // Adds padding around the content
                }}
            />
        </Form.Group>
        <div   style={{
            height: '530px',          // Sets a fixed height for the modal body
            overflowY: 'auto',         // Enables vertical scrolling when content overflows
            padding: '20px',           // Adds padding around the content
            position: 'relative',      // Establishes a positioning context for child elements
        }}>
        {/* Icon Selector */}
        <Row >
            {filteredIcons.map(({ name, icon }) => (
                <Col
                    key={name}
                    xs={3} // Adjusts the number of icons per row (e.g., xs={3} for 4 icons per row)
                    className="d-flex justify-content-center align-items-center mb-3"
                >
                    <Button
                        variant={formValues.icon === icon ? 'primary' : 'outline-secondary'}
                        onClick={() => handleIconChange(icon)}
                        className="w-100"
                        aria-label={`Select ${name} icon`}
                        style={{
                            height: '80px',       // Sets a consistent height for all icon buttons
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            padding: '10px',
                            borderRadius: '8px',
                            transition: 'background-color 0.3s',
                        }}
                    >
                        <FontAwesomeIcon icon={icon} size="2x" />
                        <span style={{ marginTop: '8px', fontSize: '0.9em' }}>{name}</span>
                    </Button>
                </Col>
            ))}
        </Row>
        </div>
    </Modal.Body>
</Modal>


            {/* Title Input */}
            <Form.Group controlId="formCardTitle" className="mb-3">
                <Form.Label>Title</Form.Label>
                <Form.Control
                    as="textarea"
                    placeholder="Enter card title"
                    value={formValues.title || ''}
                    onChange={(e) => setFormValues({ ...formValues, title: e.target.value })}
                    aria-label="Card Title"
                />
            </Form.Group>

            {/* Description Input */}
            <Form.Group controlId="formCardDescription" className="mb-3">
                <Form.Label>Description</Form.Label>
                <Form.Control
                    as="textarea"
                    placeholder="Enter description"
                    value={formValues.description || ''}
                    onChange={(e) => setFormValues({ ...formValues, description: e.target.value })}
                    rows={3}
                    aria-label="Card Description"
                />
            </Form.Group>

            {/* Button Text Input */}
            <Form.Group controlId="formCardButton" className="mb-3">
                <Form.Label>Button Text</Form.Label>
                <Form.Control
                    type="text" // Changed from textarea to text for single-line input
                    placeholder="Enter button text"
                    value={formValues.buttonText || ''}
                    onChange={(e) => setFormValues({ ...formValues, buttonText: e.target.value })}
                    aria-label="Button Text"
                />
            </Form.Group>
        </div>
    );
};

export default IconCardForm;
