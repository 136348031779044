import React, { useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { useResponsiveStyles } from './useResponsiveStyles';


function TabsComponent({ tabs, template = "default", styles = {} }) {
    const containerClassName = `${template.toLowerCase()}-tabs`; // Dynamic class based on template
    const appliedStyles = useResponsiveStyles(styles);


    const containerStyles = appliedStyles.container || {};
    const tabItemStyles = appliedStyles.tabItem || {};
    const activeItemStyles = appliedStyles.activeItem || {};
    const tabContentStyles = appliedStyles.tabContent || {};

    // We'll track the active tab to apply the correct styles
    const [activeKey, setActiveKey] = useState(tabs?.[0]?.key || 'default');

    return (
        <div className={containerClassName} style={containerStyles}>
            <Tabs 
                activeKey={activeKey}
                onSelect={(key) => setActiveKey(key)}
                id="styled-tabs"
                className="custom-tabs"
            >
                {tabs && tabs.length > 0
                    ? tabs.map((tab, index) => {
                        const isActive = activeKey === (tab.key || `tab-${index}`);
                        return (
                            <Tab
                                eventKey={tab.key || `tab-${index}`} 
                                title={
                                    <span style={isActive ? activeItemStyles : tabItemStyles}>
                                        {tab.title}
                                    </span>
                                }
                                key={tab.key || `tab-${index}`}
                            >
                                <div style={tabContentStyles}>
                                    {tab.content}
                                </div>
                            </Tab>
                        );
                    })
                    : (
                        <Tab eventKey="default" title={<span style={tabItemStyles}></span>}>
                            <div style={tabContentStyles}></div>
                        </Tab>
                    )}
            </Tabs>
        </div>
    );
}

export default TabsComponent;
