import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faXmark, faCheck, faDesktop, faTabletAlt, faMobileAlt, faCopy } from '@fortawesome/free-solid-svg-icons';
import { Tooltip, TooltipProvider } from 'react-tooltip';
import { Modal, Button, Tabs, Tab, Form, InputGroup, ButtonGroup, ToggleButton } from 'react-bootstrap';

import RichTextEditor from './pagebuilder/RichTextEditor';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import StyleEditor from './StyleEditor';
import { styleConfig } from '../config/styleConfig';
import { templateConfig } from '../config/templateConfig';


import IconCardForm from './componentForms/IconCardForm';
import IconForm from './componentForms/IconForm';

import TextForm from './componentForms/TextForm';
import HeadingForm from './componentForms/HeadingForm';
import ImageForm from './componentForms/ImageForm';
import ButtonForm from './componentForms/ButtonForm';
import BlockquoteForm from './componentForms/BlockquoteForm';
import RichTextForm from './componentForms/RichTextForm';
import CardComponentForm from './componentForms/CardComponentForm';
import ImageOverlayCardForm from './componentForms/ImageOverlayCardForm';
import HorizontalCardForm from './componentForms/HorizontalCardForm';
import HeaderFooterCardForm from './componentForms/HeaderFooterCardForm';
import DividerForm from './componentForms/DividerForm';
import ListForm from './componentForms/ListForm';
import AlertForm from './componentForms/AlertForm';
import AudioForm from './componentForms/AudioForm';
import IframeForm from './componentForms/IframeForm';
import VideoForm from './componentForms/VideoForm';
import TabsForm from './componentForms/TabsForm';
import AccordionForm from './componentForms/AccordionForm';
import CarouselForm from './componentForms/CarouselForm';
import ModalForm from './componentForms/ModalForm';
import SpacerForm from './componentForms/SpacerForm';
import BadgeForm from './componentForms/BadgeForm';
import GalleryForm from './componentForms/GalleryForm';
import PricingTableForm from './componentForms/PricingTableForm';
import MapForm from './componentForms/MapForm';
import FAQForm from './componentForms/FAQForm';
import SocialMediaIconsForm from './componentForms/SocialMediaIconsForm';
import CountdownTimerForm from './componentForms/CountdownTimerForm';
import LightboxGalleryForm from './componentForms/LightboxGalleryForm';
import TeamSectionForm from './componentForms/TeamSectionForm';
import FeatureCardsForm from './componentForms/FeatureCardsForm';
import TestimonialForm from './componentForms/TestimonialForm';
import ListGroupForm from './componentForms/ListGroupForm';
import ProgressForm from './componentForms/ProgressForm';
import BreadcrumbForm from './componentForms/BreadcrumbForm';
import ContactFormForm from './componentForms/ContactFormForm';
import ProductListingForm from './componentForms/ProductListingForm';
import BlogListingForm from './componentForms/BlogListingForm';
import MenusForm from './componentForms/MenusForm';

const ComponentEditorModal = ({ show, handleClose, component, saveChanges }) => {
  
    const [formValues, setFormValues] = useState({});

    // Set up default values to prevent errors
    const styleSections = component ? styleConfig[component.type] || {} : {};

   
    const defaultBreakpointStyles = { desktop: {}, tablet: {}, mobile: {} };
    const [styles, setStyles] = useState(defaultBreakpointStyles);
    const [activeBreakpoint, setActiveBreakpoint] = useState('desktop'); // new state to track which breakpoint we are editing

    const [activeTab, setActiveTab] = useState('content');

    const handleStyleChange = (section, newStyles) => {
      setStyles((prev) => ({
        ...prev,
        [activeBreakpoint]: {
          ...prev[activeBreakpoint],
          [section]: newStyles
        }
      }));

      setFormValues((prevValues) => ({
        ...prevValues,
        styles: {
          ...prevValues.styles,
          [activeBreakpoint]: {
            ...(prevValues.styles ? prevValues.styles[activeBreakpoint] : {}),
            [section]: newStyles
          }
        }
      }));
    };


    const formatLabel = (field) => {
        // Insert space before each uppercase letter and capitalize the first letter
        return field
            .replace(/([A-Z])/g, ' $1')
            .replace(/^./, (str) => str.toUpperCase());
    };

    useEffect(() => {
      if (component) {
        setFormValues(component.props);
        // Ensure we have a structure for all breakpoints
        const componentStyles = component.props.styles || {};
        setStyles({
          desktop: componentStyles.desktop || {},
          tablet: componentStyles.tablet || {},
          mobile: componentStyles.mobile || {},
        });
      }
    }, [component]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormValues({
            ...formValues,
            [name]: value
        });
    };

    const handleIconChange = (icon) => {
        setFormValues({ ...formValues, icon });
    };

    const handleJsonArrayChange = (fieldName, index, key, value) => {
        const updatedArray = [...(formValues[fieldName] || [])];
        updatedArray[index] = { ...updatedArray[index], [key]: value };
        setFormValues({
            ...formValues,
            [fieldName]: updatedArray
        });
    };

    const handleAddJsonArrayItem = (fieldName, newItem) => {
        setFormValues({
            ...formValues,
            [fieldName]: [...(formValues[fieldName] || []), newItem]
        });
    };

    const handleRemoveJsonArrayItem = (fieldName, index) => {
        const updatedArray = (formValues[fieldName] || []).filter((_, i) => i !== index);
        setFormValues({
            ...formValues,
            [fieldName]: updatedArray
        });
    };

    const handleSave = () => {
        saveChanges(formValues);
    };

    const handleCopyStyles = () => {
        if (!styles.desktop || Object.keys(styles.desktop).length === 0) {
            alert('No Desktop styles to copy from.');
            return;
        }

        setStyles((prev) => ({
            ...prev,
            [activeBreakpoint]: { ...styles.desktop },
        }));

        setFormValues((prevValues) => ({
            ...prevValues,
            styles: {
                ...prevValues.styles,
                [activeBreakpoint]: { ...styles.desktop },
            },
        }));

        // Optional: Provide user feedback
        alert(`Styles copied from Desktop to ${activeBreakpoint.charAt(0).toUpperCase() + activeBreakpoint.slice(1)}.`);
    };

    const renderJsonArrayFields = (fieldName, itemSchema) => (
        <>
            {formValues[fieldName]?.map((item, index) => (
                <div key={index} className="mb-3">
                    {Object.keys(itemSchema).map((key) => (
                        <InputGroup key={key} className="mb-2">
                            <Form.Control
                                type="text"
                                placeholder={key}
                                value={item[key]}
                                onChange={(e) => handleJsonArrayChange(fieldName, index, key, e.target.value)}
                            />
                        </InputGroup>
                    ))}
                    <Button variant="danger" onClick={() => handleRemoveJsonArrayItem(fieldName, index)}>Remove</Button>
                </div>
            ))}
            <Button variant="primary" onClick={() => handleAddJsonArrayItem(fieldName, itemSchema)}>Add More</Button>
        </>
    );
    
    if (!component) return null; // Prevent rendering if `component` is null

    const templatesAvailable = templateConfig[component?.type]?.length > 0;
    const stylesAvailable = Object.keys(styleSections).length > 0;

    
    return (
        <Modal className="model-styling" show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Edit Component "{component?.type}"</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Tabs activeKey={activeTab} onSelect={(tab) => setActiveTab(tab)}>
                    <Tab eventKey="content" title="Content">
                        <div className=''>
                            {component?.type === 'iconCard' && (
                                <IconCardForm formValues={formValues} setFormValues={setFormValues} />
                            )}
                            {component?.type === 'iconComponent' && (
                                <IconForm formValues={formValues} setFormValues={setFormValues} />
                            )}

                            {component?.type === 'text' && (
                                <TextForm formValues={formValues} setFormValues={setFormValues} />
                            )}
                            {component?.type === 'heading' && (
                                <HeadingForm formValues={formValues} setFormValues={setFormValues} />
                            )}
                            {component?.type === 'image' && (
                                <ImageForm formValues={formValues} setFormValues={setFormValues} />
                            )}
                            {component?.type === 'button' && (
                                <ButtonForm formValues={formValues} setFormValues={setFormValues} />
                            )}
                            {component?.type === 'blockquote' && (
                                <BlockquoteForm formValues={formValues} setFormValues={setFormValues} />
                            )}
                            {component?.type === 'richText' && (
                                <RichTextForm formValues={formValues} setFormValues={setFormValues} />
                            )}
                            {component?.type === 'cardComponent' && (
                                <CardComponentForm formValues={formValues} setFormValues={setFormValues} />
                            )}
                            {component?.type === 'imageOverlayCard' && (
                                <ImageOverlayCardForm formValues={formValues} setFormValues={setFormValues} />
                            )}      
                            {component?.type === 'horizontalCard' && (
                                <HorizontalCardForm formValues={formValues} setFormValues={setFormValues} />
                            )}
                            {component?.type === 'headerFooterCard' && (
                                <HeaderFooterCardForm formValues={formValues} setFormValues={setFormValues} />
                            )}
                            {component?.type === 'divider' && (
                                <DividerForm formValues={formValues} setFormValues={setFormValues} />
                            )}
                            {component?.type === 'list' && (
                                <ListForm formValues={formValues} setFormValues={setFormValues} />
                            )}
                            {component?.type === 'alert' && (
                                <AlertForm formValues={formValues} setFormValues={setFormValues} />
                            )}
                            {component?.type === 'audio' && (
                                <AudioForm formValues={formValues} setFormValues={setFormValues} />
                            )}
                            {component?.type === 'iframe' && (
                                <IframeForm formValues={formValues} setFormValues={setFormValues} />
                            )}
                            {component?.type === 'accordion' && (
                                <AccordionForm
                                    formValues={formValues}
                                    setFormValues={setFormValues}
                                    handleAddJsonArrayItem={handleAddJsonArrayItem}
                                    handleRemoveJsonArrayItem={handleRemoveJsonArrayItem}
                                />
                            )}
                            {component?.type === 'carousel' && (
                                <CarouselForm
                                    formValues={formValues}
                                    setFormValues={setFormValues}
                                    handleAddJsonArrayItem={handleAddJsonArrayItem}
                                    handleRemoveJsonArrayItem={handleRemoveJsonArrayItem}
                                />
                            )}
                            {component?.type === 'tabs' && (
                                <TabsForm
                                    formValues={formValues}
                                    setFormValues={setFormValues}
                                    handleAddJsonArrayItem={handleAddJsonArrayItem}
                                    handleRemoveJsonArrayItem={handleRemoveJsonArrayItem}
                                />
                            )}
                            {component?.type === 'gallery' && (
                                <GalleryForm
                                    formValues={formValues}
                                    setFormValues={setFormValues}
                                    handleAddJsonArrayItem={handleAddJsonArrayItem}
                                    handleRemoveJsonArrayItem={handleRemoveJsonArrayItem}
                                />
                            )}
                            {component?.type === 'faq' && (
                                <FAQForm
                                    formValues={formValues}
                                    setFormValues={setFormValues}
                                    handleAddJsonArrayItem={handleAddJsonArrayItem}
                                    handleRemoveJsonArrayItem={handleRemoveJsonArrayItem}
                                />
                            )}
                            {component?.type === 'pricingTable' && (
                                <PricingTableForm
                                    formValues={formValues}
                                    setFormValues={setFormValues}
                                    handleAddJsonArrayItem={handleAddJsonArrayItem}
                                    handleRemoveJsonArrayItem={handleRemoveJsonArrayItem}
                                />
                            )}
                            {component?.type === 'socialMediaIcons' && (
                                <SocialMediaIconsForm
                                    formValues={formValues}
                                    setFormValues={setFormValues}
                                    handleAddJsonArrayItem={handleAddJsonArrayItem}
                                    handleRemoveJsonArrayItem={handleRemoveJsonArrayItem}
                                />
                            )}
                            {component?.type === 'lightboxGallery' && (
                                <LightboxGalleryForm
                                    formValues={formValues}
                                    setFormValues={setFormValues}
                                    handleAddJsonArrayItem={handleAddJsonArrayItem}
                                    handleRemoveJsonArrayItem={handleRemoveJsonArrayItem}
                                />
                            )}
                            {component?.type === 'teamSection' && (
                                <TeamSectionForm
                                    formValues={formValues}
                                    setFormValues={setFormValues}
                                    handleAddJsonArrayItem={handleAddJsonArrayItem}
                                    handleRemoveJsonArrayItem={handleRemoveJsonArrayItem}
                                />
                            )}
                            {component?.type === 'featureCards' && (
                                <FeatureCardsForm
                                    formValues={formValues}
                                    setFormValues={setFormValues}
                                    handleAddJsonArrayItem={handleAddJsonArrayItem}
                                    handleRemoveJsonArrayItem={handleRemoveJsonArrayItem}
                                />
                            )}
                            {component?.type === 'testimonialComponent' && (
                                <TestimonialForm
                                    formValues={formValues}
                                    setFormValues={setFormValues}
                                    handleAddJsonArrayItem={handleAddJsonArrayItem}
                                    handleRemoveJsonArrayItem={handleRemoveJsonArrayItem}
                                />
                            )}
                            {component?.type === 'video' && (
                                <VideoForm formValues={formValues} setFormValues={setFormValues} />
                            )}
                            {component?.type === 'badge' && (
                                <BadgeForm formValues={formValues} setFormValues={setFormValues} />
                            )}
                            {component?.type === 'productList' && (
                                <ProductListingForm formValues={formValues} setFormValues={setFormValues} />
                            )}
                            {component?.type === 'blogList' && (
                                <BlogListingForm formValues={formValues} setFormValues={setFormValues} />
                            )}
                            {component?.type === 'menus' && (
                                <MenusForm formValues={formValues} setFormValues={setFormValues} />
                            )}
                            {component?.type === 'listGroupCard' && (
                                <ListGroupForm
                                    formValues={formValues}
                                    setFormValues={setFormValues}
                                    handleAddJsonArrayItem={handleAddJsonArrayItem}
                                    handleRemoveJsonArrayItem={handleRemoveJsonArrayItem}
                                />
                            )}
                            {component?.type === 'progress' && (
                                <ProgressForm
                                    formValues={formValues}
                                    setFormValues={setFormValues}
                                    handleAddJsonArrayItem={handleAddJsonArrayItem}
                                    handleRemoveJsonArrayItem={handleRemoveJsonArrayItem}
                                />
                            )}
                            {component?.type === 'breadcrumb' && (
                                <BreadcrumbForm
                                    formValues={formValues}
                                    setFormValues={setFormValues}
                                    handleAddJsonArrayItem={handleAddJsonArrayItem}
                                    handleRemoveJsonArrayItem={handleRemoveJsonArrayItem}
                                />
                            )}
                                              
                            {component?.type === 'contactForm' && (
                                <ContactFormForm
                                    formValues={formValues}
                                    setFormValues={setFormValues}
                                />
                            )}
                            {component?.type === 'map' && (
                                <MapForm
                                    formValues={formValues}
                                    setFormValues={setFormValues}
                                />
                            )}
                            {component?.type === 'timeline' && renderJsonArrayFields('events', { title: '', date: '', description: '' })}
                        </div>
                    </Tab>                   

                    {stylesAvailable && (
                        <Tab eventKey="styles" title="Styles">
                            <div className="d-flex align-items-center device-view-pop">
                                <ButtonGroup>
                                    <ToggleButton
                                        key="desktop"
                                        id="desktop"
                                        type="radio"
                                        variant={activeBreakpoint === 'desktop' ? 'primary' : 'outline-primary'}
                                        name="breakpoint"
                                        value="desktop"
                                        checked={activeBreakpoint === 'desktop'}
                                        onChange={(e) => setActiveBreakpoint(e.currentTarget.value)}
                                        data-tooltip-id={`tooltip-desktop-${component.id}`}
                                        data-tooltip-content="Desktop"
                                    >
                                        <FontAwesomeIcon icon={faDesktop} size="lg" />
                                    </ToggleButton>
                                    <ToggleButton
                                        key="tablet"
                                        id="tablet"
                                        type="radio"
                                        variant={activeBreakpoint === 'tablet' ? 'primary' : 'outline-primary'}
                                        name="breakpoint"
                                        value="tablet"
                                        checked={activeBreakpoint === 'tablet'}
                                        onChange={(e) => setActiveBreakpoint(e.currentTarget.value)}
                                        data-tooltip-id={`tooltip-tablet-${component.id}`}
                                        data-tooltip-content="Tablet"
                                    >
                                        <FontAwesomeIcon icon={faTabletAlt} size="lg" />
                                    </ToggleButton>
                                    <ToggleButton
                                        key="mobile"
                                        id="mobile"
                                        type="radio"
                                        variant={activeBreakpoint === 'mobile' ? 'primary' : 'outline-primary'}
                                        name="breakpoint"
                                        value="mobile"
                                        checked={activeBreakpoint === 'mobile'}
                                        onChange={(e) => setActiveBreakpoint(e.currentTarget.value)}
                                        data-tooltip-id={`tooltip-mobile-${component.id}`}
                                        data-tooltip-content="Mobile"
                                    >
                                        <FontAwesomeIcon icon={faMobileAlt} size="lg" />
                                    </ToggleButton>
                                </ButtonGroup>
                                {/* Conditionally render the Copy button for Tablet and Mobile */}
                                {activeBreakpoint !== 'desktop' && (
                                    <div className="tooltip-wrapper">
                                        <Button
                                            variant=""
                                            className="copy-button"
                                            onClick={handleCopyStyles}
                                            aria-label="Copy Styles from Desktop"
                                        >
                                            <FontAwesomeIcon icon={faCopy} />
                                        </Button>
                                        <span className="tooltip-text" onClick={handleCopyStyles}>Copy desktop style</span>
                                    </div>
                                )}
                                
                                {/* Initialize tooltips */}
                                <Tooltip id={`tooltip-desktop-${component.id}`} place="top" effect="solid" />
                                <Tooltip id={`tooltip-tablet-${component.id}`} place="top" effect="solid" />
                                <Tooltip id={`tooltip-mobile-${component.id}`} place="top" effect="solid" />

                            </div>
                            <Tabs defaultActiveKey={Object.keys(styleSections)[0] || 'default'} className="mb-3">
                                {Object.keys(styleSections).map((section) => (
                                  <Tab eventKey={section} title={formatLabel(section)} key={section}>
                                    <StyleEditor
                                        styles={styles[activeBreakpoint][section] || {}}
                                        onChange={(newStyles) => handleStyleChange(section, newStyles)}
                                        editableFields={styleSections[section] || []}
                                        activeBreakpoint={activeBreakpoint}
                                    />
                                  </Tab>
                                ))}
                            </Tabs>
                        </Tab>
                    )}


                    {templatesAvailable && (
                        <Tab eventKey="templates" title="Templates">
                            <div className="template-selection">
                                {templateConfig[component?.type]?.map((template) => (
                                    <div
                                        key={template.templateName}
                                        className={`template-card ${
                                            formValues.template === template.templateName ? 'active' : ''
                                        }`}
                                        onClick={() =>
                                            setFormValues({ ...formValues, template: template.templateName })
                                        }
                                        style={{
                                            cursor: 'pointer',
                                            border:
                                                formValues.template === template.templateName
                                                    ? '2px solid #007bff'
                                                    : '1px solid #ddd',
                                            padding: '10px',
                                            margin: '10px',
                                            borderRadius: '5px',
                                        }}
                                    >
                                        <img
                                            src={template.previewImage}
                                            alt={template.templateName}
                                            style={{
                                                width: '100%',
                                                height: 'auto',
                                                borderRadius: '5px',
                                            }}
                                        />
                                        <p style={{ textAlign: 'center', marginTop: '5px' }}>
                                            {template.templateName}
                                        </p>
                                    </div>
                                ))}
                            </div>
                        </Tab>
                    )}

                </Tabs>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="danger" onClick={handleClose}>
                    <FontAwesomeIcon icon={faXmark} />
                </Button>
                <Button variant="success" onClick={handleSave}>
                    <FontAwesomeIcon icon={faCheck} />
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ComponentEditorModal;
