// src/pages/CustomPosts.js

import React, { useReducer, useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import Loading from './component/Loading';
import { API_URL } from '../config';
import { componentTypes } from './config/componentTypes';
import { safeParseJSON } from './utils/safeParseJSON';
import { useAppData } from './component/AppDataContext';
import { Helmet } from 'react-helmet-async';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faSquareCaretRight } from '@fortawesome/free-solid-svg-icons';

const initialState = { rows: [] };

function reducer(state, action) {
    switch (action.type) {
        case 'LOAD_PAGE_DATA':
            // Safely access page_builder_rows inside page
            const pageBuilderRows = action.payload.page?.page_builder_rows || [];

            return {
                ...state,
                rows: pageBuilderRows.map((row) => ({
                    id: row.id,
                    disabled: row.disabled,
                    styles: safeParseJSON(row.styles, {}), // Safely parse row styles

                    sections: row.page_builder_sections.map((section) => ({
                        id: section.id,
                        disabled: section.disabled,
                        styles: safeParseJSON(section.styles, {}), // Safely parse section styles
                        layout_key: section.layout_key,

                        columns: section.page_builder_columns.map((column) => ({
                            class: column.class,

                            components: column.page_builder_components.map((component) => ({
                                type: component.type,
                                props: safeParseJSON(component.props, {}), // Safely parse component props
                            })),

                            subsections: column.page_builder_subsections.map((subsection) => ({
                                id: subsection.id,
                                disabled: subsection.disabled,
                                layout_key: subsection.layout_key,

                                subcolumns: subsection.page_builder_subcolumns.map((subcolumn) => ({
                                    class: subcolumn.class,

                                    subcomponents: subcolumn.page_builder_subcomponents.map((subcomp) => ({
                                        type: subcomp.type,
                                        props: safeParseJSON(subcomp.props, {}), // Safely parse subcomponent props
                                    })),
                                })),
                            })),
                        })),
                    })),
                })),
            };
        default:
            return state;
    }
}

/**
 * Fetch post data for the post builder. Adjust the endpoint as necessary.
 */
const fetchPostData = async (postId, subdomain) => {
    const response = await fetch(`${API_URL}/post-builder/${postId}?account_key=${subdomain}`);
    if (!response.ok) {
        throw new Error('Failed to fetch post data');
    }
    const data = await response.json();
    return data;
};

function CustomPosts({ postId: propPostId }) {
    const { postId: paramPostId } = useParams();
    const navigate = useNavigate();
    const idToLoad = propPostId || paramPostId; // If postId is passed as prop, use that; otherwise use URL param.

    const [{ rows }, dispatch] = useReducer(reducer, initialState);
    const [isLoading, setIsLoading] = useState(true);
    const [currentPost, setCurrentPost] = useState(null);
    const [blog, setBlog] = useState(null); // State for blog details
    const [blogCategories, setBlogCategories] = useState([]); // State for blog's categories
    const [error, setError] = useState(null); // State for error handling

    const { subdomain } = useAppData();

    useEffect(() => {
        if (idToLoad) {
            setIsLoading(true);
            fetchPostData(idToLoad, subdomain)
                .then((postData) => {
                    console.log('API Response:', postData); // Debugging line
                    setCurrentPost(postData); // Store the entire post object
                    setBlog(postData.blog || null); // Store blog details
                    setBlogCategories(postData.blog.categories || []); // Store blog's categories
                    dispatch({ type: 'LOAD_PAGE_DATA', payload: postData });
                })
                .catch((error) => {
                    console.error(error);
                    setError(error.message);
                    // Optionally navigate to a 404 page:
                    // navigate('/404');
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
    }, [idToLoad, subdomain]);

    // Identify full-screen carousels from the loaded rows
    const fullScreenCarousels = rows.flatMap((row) =>
        row.sections.flatMap((section) =>
            section.columns.flatMap((column) =>
                column.components.filter(
                    (comp) => comp.type === 'carousel' && comp.props.isFullScreen
                )
            )
        )
    );

    // Prepare Helmet SEO data
    const seo = currentPost
        ? {
              meta_title: currentPost.page.title || 'Untitled Post',
              meta_description:
                  currentPost.page.excerpt ||
                  (currentPost.page.content
                      ? currentPost.page.content.substring(0, 150)
                      : ''),
              canonical_url: window.location.href, // or if your API returns a canonical_url field
          }
        : {};

    return (
        <>
            {/* Blog Header with Background Image */}
            {currentPost && blog && (
                <div
                    className="blog-list-head"
                    style={{
                        backgroundImage: blog.media_url
                            ? `url(${blog.media_url})`
                            : 'url(/path/to/default-image.jpg)', // Replace with your actual fallback image path
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                        padding: '100px 0', // Adjust padding as needed
                        color: '#fff', // Ensure text is readable over the image
                        textAlign: 'center',
                        position: 'relative',
                    }}
                >
                    {/* Optional Overlay for Better Text Readability */}
                    {blog.media_url && (
                        <div
                            style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                                backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent overlay
                                zIndex: 1,
                            }}
                        ></div>
                    )}
                    <div
                        style={{
                            position: 'relative',
                            zIndex: 2,
                            maxWidth: '800px',
                            margin: '0 auto',
                            padding: '0 20px',
                        }}
                    >
                        <h1>{currentPost.page.title || `Blog`}</h1>
                        <p>{currentPost.page.content || 'Explore posts below'}</p>
                    </div>
                </div>
            )}

            {/* Main Content */}
            <div className="container py-5">
                <div className="row mt-4">
                    <div className="col-md-8 col-lg-8">
                        {/* Helmet for SEO */}
                        {currentPost && (
                            <Helmet>
                                <title>{seo.meta_title}</title>
                                <meta name="description" content={seo.meta_description || ''} />
                                {/* Add more meta tags if needed */}
                                <link rel="canonical" href={seo.canonical_url} />
                            </Helmet>
                        )}

                        {/* Loading state */}
                        {isLoading && <Loading />}

                        {/* Error Handling */}
                        {error && <div className="alert alert-danger">Error: {error}</div>}

                        {/* Render full-screen carousels (if any) */}
                        {fullScreenCarousels.map((carouselComp, idx) => (
                            <div key={idx} className="carousel-full-screen">
                                {React.createElement(componentTypes[carouselComp.type], {
                                    ...carouselComp.props,
                                })}
                            </div>
                        ))}

                        {/* Render the page-builder rows (if they exist) */}
                        {rows.map((row) => (
                            row.disabled !== 1 && (
                                <div key={row.id} className="row-wrap" style={row.styles || {}}>
                                    {/* Render sections */}
                                    {row.sections.map((section) => (
                                        section.disabled !== 1 && (
                                            <div key={section.id} className="section-wrap">
                                                <div className="container">
                                                    <div className="row">
                                                        {/* Render columns */}
                                                        {section.columns.map((column, colIdx) => (
                                                            <div
                                                                key={colIdx}
                                                                className={`${column.class} p-3`}
                                                            >
                                                                {/* Render subsections */}
                                                                {column.subsections.map((subsection) => (
                                                                    subsection.disabled !== 1 && (
                                                                        <div key={subsection.id} className="row">
                                                                            {subsection.subcolumns.map((subcolumn, subcolIdx) => (
                                                                                <div
                                                                                    key={subcolIdx}
                                                                                    className={`${subcolumn.class} p-3`}
                                                                                >
                                                                                    {/* Render subcomponents */}
                                                                                    {subcolumn.subcomponents.map((subcomp, subcompIdx) => (
                                                                                        <div key={subcompIdx}>
                                                                                            {React.createElement(
                                                                                                componentTypes[subcomp.type],
                                                                                                {
                                                                                                    ...subcomp.props,
                                                                                                }
                                                                                            )}
                                                                                        </div>
                                                                                    ))}
                                                                                </div>
                                                                            ))}
                                                                        </div>
                                                                    )
                                                                ))}

                                                                {/* Render components */}
                                                                {column.components.map((comp, compIdx) => {
                                                                    if (
                                                                        comp.type === 'carousel' &&
                                                                        comp.props.isFullScreen
                                                                    ) {
                                                                        // We already rendered full-screen carousels above
                                                                        return null;
                                                                    }
                                                                    return (
                                                                        <div key={compIdx}>
                                                                            {React.createElement(
                                                                                componentTypes[comp.type],
                                                                                {
                                                                                    ...comp.props,
                                                                                }
                                                                            )}
                                                                        </div>
                                                                    );
                                                                })}
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    ))}
                                </div>
                            )
                        ))}
                    </div>
                    <div className="col-md-4 col-lg-4">
                        {/* Blog's Categories */}
                        {blogCategories.length > 0 && (
                            <div className="blog-sidebar mb-4">
                                <h4>Categories</h4>
                                <ul className="list-group">
                                    {blogCategories.map((cat) => (
                                        <li key={cat.id}>
                                            <FontAwesomeIcon icon={faSquareCaretRight} />
                                            <Link to={`/blog/category/${cat.slug}`}>{cat.name}</Link>
                                            {/* Optionally, show the number of posts in each category */}
                                            {/* <span className="badge bg-primary rounded-pill">12</span> */}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )}

                        {/* Optionally, display other blog-related information here */}
                    </div>
                </div>
            </div>
        </>
    );
}

// Correct: Export outside the function
export default CustomPosts;
