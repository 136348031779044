import React from 'react';
import LightGallery from 'lightgallery/react';

// Styles

// Import LightGallery styles
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';

// Plugins
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';

const LightboxTemplate = ({ images }) => {
    return (
        <div className="light-gallery-wrap">
            <LightGallery
                speed={500}
                plugins={[lgThumbnail, lgZoom]}
            >
                {images.map((img, idx) => (
                    <a
                        key={idx}
                        href={img.src}
                        data-sub-html={img.alt || `Image ${idx + 1}`}
                        className=""
                    >
                        <img
                            src={img.src}
                            alt={img.alt || `Thumbnail ${idx + 1}`}
                        />
                    </a>
                ))}
            </LightGallery>
        </div>
    );
};

export default LightboxTemplate;
