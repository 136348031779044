// HeadingComponent.jsx
import React from 'react';
import { useResponsiveStyles } from './useResponsiveStyles';
import { nl2br } from '../../utils/nl2br'; // Adjust the import path as needed

function HeadingComponent({ level, text, styles, template = "Dotted-Underline" }) {
    const HeadingTag = `h${level}`;
    const containerClassName = `${template.toLowerCase()}`; // Class for parent div
    const appliedStyles = useResponsiveStyles(styles);

    return (
        <div className={containerClassName}>
            <HeadingTag style={appliedStyles.text}>
                {nl2br(text) || `Heading ${level}`}
            </HeadingTag>
        </div>
    );
}

export default HeadingComponent;
